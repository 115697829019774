import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import PaidIcon from "@mui/icons-material/Paid";

//
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
import { logout } from "../../DAL/Login/Login";
import { baseUri } from "../../config/config";
import { userProfile } from "src/DAL/Project/Project";
import { unreadMessagesCount } from "src/DAL/chat/chat";
import { useSocket } from "src/Hooks/socketContext";
import { Icon } from "@iconify/react";

//--------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const navigate = useNavigate();
  const { getUserProfileData, disconnect_socket, fetchUnreadMessagesCount } =
    useSocket();
  const [user, setUser] = useState(getUserProfileData());
  const handleLogout = async () => {
    let token = localStorage.getItem("token");
    disconnect_socket();
    if (!token) {
      let expand = localStorage.getItem("localExpandStates");
      localStorage.clear();
      console.log(expand, "cleared");
      if (expand) {
        localStorage.setItem("localExpandStates", expand);
      }
      navigate("/login");
      return;
    }
    if (token === "") {
      let expand = localStorage.getItem("localExpandStates");
      localStorage.clear();
      console.log(expand, "cleared");
      if (expand) {
        localStorage.setItem("localExpandStates", expand);
      }
      navigate("/login");
      return;
    }
    const formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    const result = await logout(formdata);
    if (result.code === 200) {
      let expand = localStorage.getItem("localExpandStates");
      localStorage.clear();
      console.log(expand, "cleared");
      if (expand) {
        localStorage.setItem("localExpandStates", expand);
      }
      navigate("/login");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const filterChatRoutes = (data) => {
    if (String(user?.team_user_type) === "1") {
      let filtered_routes = Array.from(data);
      if (String(user?.allow_chat) === "1") {
        let chat_exist = false;
        data.map((route) => {
          if (String(route.path) === "/chat/chat-messages") {
            chat_exist = true;
          }
        });
        if (!chat_exist) {
          filtered_routes.push({
            title: "Team Chat",
            path: "/chat/chat-messages",
            icon: getIcon("bi:chat-left-dots"),
          });
        }
        setFilteredRoutes(filtered_routes);
      }
      if (String(user?.allow_chat) === "0") {
        console.log(user?.team_user_type, "typeee");
        let filtered = filtered_routes.filter((item) => {
          if (String(item.path) !== "/chat/chat-messages") {
            return item;
          }
        });
        console.log(filtered, "foll");
        setFilteredRoutes(filtered);
      }
    }
  };

  const pushRoute = () => {
    let filtered_routes = sidebarConfig;
    let sale_exist = false;
    let chat_exist = false;
    sidebarConfig.map((route) => {
      if (String(route.path) === "/all-sales-listing") {
        sale_exist = true;
      }
      if (String(route.path) === "/chat/chat-messages") {
        chat_exist = true;
      }
    });
    if (String(user?.sales_option) === "1") {
      if (!sale_exist) {
        if (String(user?.sales_option) === "1") {
          // filtered_routes.push({
          //   title: 'All Sales',
          //   path: '/all-sales-listing',
          //   icon: <PaidIcon width={22} height={22} />,
          // });
          filtered_routes.push({
            title: "All Sales",
            path: "/all-sales-listing",
            icon: <PaidIcon width={22} height={22} />,
            children: [
              {
                title: "Sections",
                path: "/all-sales-listing/sections",
                icon: getIcon("carbon:list-boxes"),
              },
              {
                title: "All Sales",
                path: "/all-sales-listing/all",
                icon: <PaidIcon width={22} height={22} />,
              },
            ],
          },
          {
            title: "Private Notes",
            path: "/Notes/AllNotes",
            icon: getIcon("material-symbols:menu-book-outline-rounded"),
          }
          );
          console.log(sidebarConfig, "pushed-routes");
        }
      }
      setFilteredRoutes(filtered_routes);
    }
    if (String(user?.sales_option) === "0") {
      let filtered = filtered_routes.filter((route) => {
        if (String(route.path) !== "/all-sales-listing") {
          return route;
        }
      });
      setFilteredRoutes(filtered);
    }
    if (String(user?.allow_chat) === "1") {
      if (!chat_exist) {
        filtered_routes.push({
          title: "Team Chat",
          path: "/chat/chat-messages",
          icon: getIcon("bi:chat-left-dots"),
        });
        console.log(sidebarConfig, "pushed-routes");
      }
      setFilteredRoutes(filtered_routes);
    }
    if (String(user?.allow_chat) === "0") {
      let filtered = filtered_routes.filter((route) => {
        if (String(route.path) !== "/chat/chat-messages") {
          return route;
        }
      });
      setFilteredRoutes(filtered);
    }
    // filterChatRoutes(filteredRoutes);
    // return filtered_routes;
  };
  const getImage = () => {
    let image = user?.image;
    return image;
  };
  const getName = () => {
    let name = user?.first_name;
    if (!name) {
      localStorage.clear();
      window.location.reload();
    }
    if (name === "") {
      localStorage.clear();
      window.location.reload();
    }
    if (name === null) {
      localStorage.clear();
      window.location.reload();
    }
    if (name) {
      return name;
    }
  };
  const getUserProfile = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await userProfile(formData);
    console.log(result, "user-profile");
    const { user_detail } = result;
    console.log(user_detail, "user-profile-dashboard");
    if (result.code === 200) {
      localStorage.setItem("user-profile", JSON.stringify(user_detail));
      localStorage.setItem('allow_check_comments', user_detail.allow_check_comments);

      // localStorage.setItem('allowDrag', String(user_detail.allow_reorder_task));
      // localStorage.setItem('allowViewSales', String(user_detail.sales_option));
      // localStorage.setItem('allowProject', user_detail.allow_project);
      pushRoute();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    pushRoute();
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    let status = localStorage.getItem("user-status");
    if (String(status) === "0") {
      localStorage.clear();
      window.location.reload();
    }
    console.log(user, "yoser");
    if (!user) {
      localStorage.clear();
      navigate("/login");
    }
    console.log("path-changed", filteredRoutes);
    fetchUnreadMessagesCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  useEffect(() => {
    pushRoute();
    getUserProfile();
    if (!user) {
      localStorage.clear();
      navigate("/login");
      return;
    }
  }, []);
  useEffect(() => {
    pushRoute();
  }, [getUserProfileData()]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        {/* <Link underline="none" component={RouterLink} to="#"> */}
        <AccountStyle onClick={() => navigate("/user_profile")}>
          {getImage() ? (
            <Avatar src={baseUri + getImage()} alt="profile-image" />
          ) : (
            <Avatar>{getName().substring(0, 1)}</Avatar>
          )}

          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
              {user?.first_name} {(" ", user?.last_name)}
            </Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                role
              </Typography> */}
          </Box>
        </AccountStyle>
        {/* </Link> */}
      </Box>

      <NavSection navConfig={filteredRoutes} />
      {/* <NavSection navConfig={sidebarConfig} /> */}

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Button fullWidth onClick={() => handleLogout()} variant="contained">
          Logout
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
