import React, { useState } from "react";
import StyledSearch from "src/pages/Tasks/components/search/StyledSearch";
import { debounce } from "lodash";
const debouncedUpdateSearch = debounce((query, cb) => {
  cb(query);
}, 150);
const SaleSearch = ({ query, onChange }) => {
  const [search, setSearch] = useState(query);

  const handleChange = (e) => {
    setSearch(e.target.value);
    debouncedUpdateSearch(e.target.value, onChange);
  };
  return (
    <StyledSearch
      placeHolder="Search..."
      value={search}
      onChange={handleChange}
    />
  );
};

export default SaleSearch;
