import React from 'react';
import { CircularProgress } from '@mui/material';

function Loader() {
  return (
    <div className='relative' style={{ position: 'relative', minHeight: '82vh' }}>
      <div
        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
      >
        <CircularProgress />
      </div>
    </div>
  );
}

export default Loader;
