import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import PaidIcon from "@mui/icons-material/Paid";
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "calender",
    path: "/calender",
    icon: getIcon("simple-line-icons:calender"),
  },
  {
    title: "Projects",
    path: "/projects",
    icon: getIcon(barchartfill),
  },
  {
    title: "All tasks",
    path: "/all-tasks",
    icon: getIcon("fluent:clipboard-more-20-regular"),
  },
  {
    title: "All completed tasks",
    path: "/all-completed-tasks",
    icon: getIcon("akar-icons:circle-check"),
  },
  {
    title: "Team Chat",
    path: "/chat/chat-messages",
    icon: getIcon("bi:chat-left-dots"),
  },
  // {
  //   title: 'team',
  //   path: '/team',
  //   icon: getIcon(peopleFill)
  // },
  // {
  //   title: 'department',
  //   path: '/department',
  //   icon: getIcon(peopleFill)
  // }
  // {
  //   title: 'product',
  //   path: '/products',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'blog',
  //   path: '/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];
console.log(localStorage.getItem("allowViewSales"), "first-value-at-sidebar");
const pushRoute = () => {
  let exist = false;
  sidebarConfig.map((route) => {
    if (String(route.path) === "/all-sales-listing") {
      exist = true;
    }
  });
  if (!exist) {
    if (String(localStorage.getItem("allowViewSales")) === "1") {
      sidebarConfig.push({
        title: "All Sales",
        path: "/all-sales-listing",
        icon: <PaidIcon width={22} height={22} />,
      });
      console.log(sidebarConfig, "pushed-routes");
    }
  }
};
// pushRoute();
export default sidebarConfig;
