import React from 'react';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';
// routes
import Routers from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/style.css';
import './assets/css/calender-custom.css';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
//Mui
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
//hooks
import { ContextSocket } from './Hooks/socketContext';
// ----------------------------------------------------------------------
export default function App() {
  const notitackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notitackRef.current.closeSnackbar(key);
  };
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <SnackbarProvider
        ref={notitackRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        TransitionComponent={Slide}
        maxSnack={3}
        autoHideDuration={2000}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon htmlColor='white' />
          </IconButton>
        )}
      >
        {/* <ContextSocket> */}
        <Routers />
        {/* </ContextSocket> */}
      </SnackbarProvider>
    </ThemeConfig>
  );
}
