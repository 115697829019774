import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  RadioGroup,
  Radio,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { filter } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { changeTaskDetail, deleteTask, verifiedTaskList } from 'src/DAL/Project/Project';
import StyledSearch from './components/search/StyledSearch';
import TaskComponent from 'src/components/TaskComponent';
import { useSnackbar } from 'notistack';
import Loader from 'src/components/Loader';
import { EditTaskSidebar } from 'src/components/_dashboard/projects';
import CommentsDrawer from './components/CommentsDrawer';
import PopoverSectionMenu from './components/PopoverSectionMenu';
const VerifiedTasks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedRow, setRowData] = useState({});
  const [opendialog, setOpenDialog] = React.useState(false);
  const [openstatus, setOpenStatus] = useState(false);
  const [openpriority, setOpenPriority] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [sidebarFilterData, setSidebarFilterData] = useState([]);
  const [openTotalMemberListing, setOpenTotalMemberListing] = useState(false);
  const [taskdata, setTaskData] = React.useState([]);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [isMyTodoOn, setIsMyTodoOn] = useState(false);
  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [taskSortStatus, setTaskSortStatus] = useState({
    type: 'date',
    value: 'newest',
    status: true,
  });
  const [searchValue, setSearchValue] = useState('');
  const [projectDetail, setProjectDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  console.log(location.state, 'location-');
  const project_id = location.state?.project_id;
  const getProjectName = () => {
    return projectDetail.project_name;
  };
  const getAssignedTask = (task) => {
    const userId = localStorage.getItem('user_id');
    const members = task.assign_member;

    const findAssign = members.find((t) => String(t.user_id) === String(userId));
    if (findAssign) {
      return true;
    }
    if (String(task.task_creator_id) === String(userId)) {
      return true;
    }
    return false;
  };
  const getVerifiedTasks = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', project_id);
    console.log(...formData, 'get-verified-tasks-req');
    const result = await verifiedTaskList(formData);
    const userId = localStorage.getItem('user_id');
    // let result;
    if (result.code === 200) {
      setProjectDetail(result.project_detail.get_info);
      setProjectMembers(result.project_detail.Project);
      const tasks = result.tasks_tags.map((x, i) => {
        console.log();
        let isAllowed = false;
        if (String(userId) === String(x.task_creator_id)) {
          isAllowed = true;
        }
        return {
          task_id: x.task_id,
          task: x.task_name,
          task_creator_id: x.task_creator_id,
          targetDate: x.target_date === '0000-00-00' ? '' : x.target_date,
          // targetDate: moment(new Date(x.target_date)).format('DD.MM.YYYY HH:mm'),
          endDate: '',
          status: x.task_status,
          priority: x.task_priority,
          taskOrder: x.task_order,
          detailVisible: true,
          members: x.assign_member,
          isAssigned: getAssignedTask(x),
          visibilityStatus: x.vasibility_status,
          isAllowed: isAllowed,
          isCreator: isAllowed,
          taskCreatorName: x.task_creator_name,
          taskCreatorImage: x.task_creator_image,
          commentsCount: x.comment_count,
          createdDate: x.created_at,
          taskType: x.task_type,
          description: x.task_distribution,
        };
      });
      console.log(tasks, 'modified-obj');

      let filtered = [];
      tasks.map((task) => {
        if (task.isCreator || String(task.visibilityStatus) === String(1)) {
          filtered.push(task);
        }
      });
      console.log(filtered, 'filtered-verified');
      setTaskData(filtered);
      //   setTaskData(tasks);
      //   setTaskData(result.tasks_tags);
      console.log(result, 'verified-tasks-result');
      setLoading(false);
      setTimeout(() => {
        // if (shouldScroll.current === true) {
        handleScroll();
        // }
      }, 1100);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleDeleteTask = async () => {
    // console.log(selectedRow, "row-data");
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', selectedRow.task_id);
    formData.append('project_id', project_id);
    formData.append('task_order', selectedRow.taskOrder);
    formData.append('vasibility_status', selectedRow.visibilityStatus);
    console.log(...formData, 'delete-task-req');
    const result = await deleteTask(formData);
    if (result.code === 200) {
      handleCloseSectionMenu();
      getVerifiedTasks();
      handleCloseDialog();
      enqueueSnackbar('Task deleted', { variant: 'success' });
    } else {
      handleCloseDialog();
      handleCloseSectionMenu();
      enqueueSnackbar(result.message, { variant: 'error' });
    }
    const _arr = taskdata.filter((x) => x.task_id !== selectedRow.task_id);
    setTaskData(_arr);
  };
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, 'target-task');
    console.log(index, 'target-index');
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseSectionMenu = () => {
    setIsSectionMenuOpen(false);
    setSectionMenuAnchorEl(null);
  };
  const handleSectionAnchorEl = (e) => {
    setSectionMenuAnchorEl(e.currentTarget);
  };
  const handleOpenDialog = (data, i) => {
    // console.log(data, i);
    setOpenDialog(true);
    // setRowData(selectedRow);
  };
  const closeCommentsDrawer = () => {
    setIsCommentDrawerOpen(false);
  };
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_task) => _task.task.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const handleOpenCommentDrawer = (data) => {
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const getFilteredTasks = (data) => {
    if (searchValue !== '') {
      const filtered = handleLocalTaskSearch(searchValue, data);
      return filtered;
    }
    return data;
  };
  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ':') {
      if (String(localStorage.getItem('allowDrag')) === String(1)) {
        return '2';
      }
      return '1';
    }
    return '1';
  };
  const handleSubmitUpdateTask = async (name, description, id) => {
    const taskType = getTaskType(name);
    const formData = new FormData(); // formdata object
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', id);
    formData.append('task_text', name);
    formData.append('description', description);
    formData.append('task_type', taskType);
    console.log(...formData, 'update-task-req');
    const result = await changeTaskDetail(formData);
    if (result.code === 200) {
      handleCloseUpdate();
      getVerifiedTasks();
      enqueueSnackbar('Task detail updated', { variant: 'success' });
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleOpenEditTask = (data) => {
    setOpenEditTask(true);
    setRowData(data);
  };

  const handleCloseEditTask = () => {
    setOpenEditTask(false);
  };
  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };
  const handleScroll = () => {
    if (!location.state) return;
    if (!location.state.task_id) return;
    if (location.state.task_id === null) return;
    console.log(location.state, 'at-scroll-fn');
    let taskId = location.state.task_id;
    let task = document.getElementById(taskId);
    if (!task) {
      console.log('no element found');
      return;
    }
    const rects = task.getBoundingClientRect();
    const yAxis = rects.y;
    const height = rects.height;
    const xAxis = rects.x;
    // task.scrollIntoView({ block: 'end', inline: 'nearest' });
    task.style.transition = 'all .5s linear';
    task.style.border = 'solid 3px rgb(10, 238, 124)';
    window.scrollTo(xAxis, yAxis - height);
    // shouldScroll.current = false;
    setTimeout(() => {
      task.style.border = 'solid 1px transparent ';
    }, 3000);
  };
  useEffect(() => {
    getVerifiedTasks();
  }, []);
  useEffect(() => {
    console.log(taskdata, 'changed--');
  }, [taskdata]);
  if (!project_id || project_id === '' || project_id === null) {
    return <Navigate to='/projects/categories' />;
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <Page title='Verified Tasks | Support Task Portal'>
      <Container maxWidth='xl' style={{ backgroundColor: '#fff' }}>
        <div className='row main-header'>
          <div
            className='col-lg-4 col-md-12 col-sm-12 pl-0 align-items-center'
            style={{ display: 'flex' }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title='Project name'>
              <span role='button'>
                <Typography style={{ fontsize: 16, fontWeight: 'bold', paddingLeft: 8 }}>
                  {getProjectName()}
                </Typography>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className='d-flex align-items-center mt-3'>
          <div className=''>
            <StyledSearch
              value={searchValue}
              onChange={handleChangeSearchValue}
              placeHolder='Search Task...'
            />
          </div>
        </div>

        <TaskComponent
          //passing states as props
          projectDetail={projectDetail}
          taskSortStatus={null}
          searchValue={searchValue}
          isMyTodoOn={false}
          setOpenEditTask={setOpenEditTask}
          setRowData={setRowData}
          setIsCommentDrawerOpen={setIsCommentDrawerOpen}
          projectMembers={projectMembers}
          taskdata={taskdata}
          setTaskData={setTaskData}
          sidebarFilterData={sidebarFilterData}
          setSidebarFilterData={setSidebarFilterData}
          setOpenStatus={setOpenStatus}
          setOpenPriority={setOpenPriority}
          setOpenTotalMemberListing={setOpenTotalMemberListing}
          //
          setSectionMenuAnchorEl={setSectionMenuAnchorEl}
          setIsSectionMenuOpen={setIsSectionMenuOpen}
          //functions
          fetchTaskListing={getVerifiedTasks}
          getFilteredTasks={getFilteredTasks}
          targetProjectId={project_id}
          //conditions
          verifiedListing={true}
          isDraggable={false}
          showMenu={true}
          showMembersListing={true}
          showAddMembers={false}
          projectButton={false}
          canOpenSidebar={true}
          canOpenStatus={true}
          canOpenPriority={false}
          showCheckbox={false}
          showCopy={false}
        />

        {/* ========================== delete task dialog========================= */}

        <Dialog open={opendialog} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want to delete this task ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleDeleteTask}>Agree</Button>
          </DialogActions>
        </Dialog>
        {/* ========================== popovers ========================= */}
        <PopoverSectionMenu
          isOpen={isSectionMenuOpen}
          onOpen={handleOpenSectionMenu}
          onClose={handleCloseSectionMenu}
          anchorEl={sectionMenuAnchorEl}
          setAnchorEl={handleSectionAnchorEl}
          handleOpenDialog={handleOpenDialog}
          sections={[]}
          handleMoveSection={() => null}
          selectedRow={selectedRow}
          isPersonal={false}
          isDragDisabled={() => true}
          showMoveTo={false}
          data={[]}
        />
        {/* ========================== sidebars ========================= */}
        <EditTaskSidebar
          isOpenEditTask={openEditTask}
          isTaskData={selectedRow}
          onResetEditTask={() => null}
          onOpenEditTask={handleOpenEditTask}
          onCloseEditTask={handleCloseEditTask}
          openUpdate={handleOpenUpdate}
          openEdit={openEdit}
          SubmitTask={handleSubmitUpdateTask}
        />
        <CommentsDrawer
          isOpenEditTask={openEditTask}
          isTaskData={selectedRow}
          onResetEditTask={() => null}
          onOpenEditTask={handleOpenEditTask}
          onCloseEditTask={handleCloseEditTask}
          openUpdate={handleOpenUpdate}
          openEdit={openEdit}
          SubmitTask={handleSubmitUpdateTask}
          getTaskList={getVerifiedTasks}
          // handleRemoveImage={handleRemoveImage}
          // setIsImageDialogOpen={setIsImageDialogOpen}
          isCommentOpen={isCommentDrawerOpen}
          onCloseComment={closeCommentsDrawer}
          handleOpen={handleOpenCommentDrawer}
          markCommentAsRead={() => null}
          verifiedListing={true}
        />
      </Container>
    </Page>
  );
};

export default VerifiedTasks;
