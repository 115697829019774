import React, { useState } from "react";
//material
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
import {
  addTaskMembers,
  addTaskTargetDate,
  changePriority,
  changeStatus,
  deleteTaskMembers,
} from "src/DAL/Project/Project";
import { useSocket } from "src/Hooks/socketContext";
import MembersListing from "src/pages/Tasks/components/MembersListing";
import Tooltip from "@mui/material/Tooltip";
import Linkify from "react-linkify";
import { SecureLink } from "react-secure-link";
//API's
import AddMembers from "src/pages/Tasks/components/AddMembers";
//icons
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentIcon from "@mui/icons-material/Comment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CircleIcon from "@mui/icons-material/Circle";
import SquareIcon from '@mui/icons-material/Square';


//other
import DatePicker from "react-datepicker";
import moment from "moment";
import { useSnackbar } from "notistack";
import PriorityPopover from "src/pages/Tasks/components/Priority";
import StatusPopover from "src/pages/Tasks/components/Status";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { baseUri } from "src/config/config";
import { noTask } from "src/assets";
import { set_blank_target } from "src/utils/DomUtils";
import { TaskDateTimePicker } from ".";


const TaskComponent = ({
  provided,
  taskSortStatus,
  searchValue,
  isMyTodoOn,
  setTaskData,
  isFilterOn,
  taskdata,
  sidebarFilterData,
  projectDetail,
  setSidebarFilterData,
  fetchTaskListing,
  setOpenEditTask,
  setRowData,
  setSectionMenuAnchorEl,
  setIsSectionMenuOpen,
  getFilteredTasks,
  projectMembers,
  setIsCommentDrawerOpen,
  setOpenStatus,
  setOpenPriority,
  setOpenTotalMemberListing,
  isDraggable,
  showMenu,
  projectButton,
  canOpenSidebar,
  canOpenStatus,
  canOpenPriority,
  targetProjectId,
  showAddMembers,
  showCheckbox,
  showCopy,
  verifiedListing,
  allTaskListing,
  allCompletedListing,
  mainTaskListing,
}) => {
  let allowDrag = false;
  if (localStorage.getItem("allowDrag") === "1") {
    allowDrag = true;
  }
  //hooks
  const { sendSocketNotification, getUserProfileData } = useSocket();
  const user = getUserProfileData();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();
  //states
  const [location, setLocation] = useState(useLocation());
  //
  //drag-drop
  const isDragDisabled = () => {
    // if (localStorage.getItem('allowDrag') === '0') {
    //   return true;
    // }
    if (String(user.allow_reorder_task) === "0") {
      return true;
    }
    if (isDraggable === false) {
      return false;
    }
    let disabled = false;
    if (String(localStorage.getItem("taskFilter")) === String(1)) {
      disabled = true;
    }
    if (String(localStorage.getItem("taskFilter")) === String(0)) {
      disabled = false;
    }

    if (taskSortStatus.status === true) {
      disabled = true;
    }
    if (searchValue !== "") {
      disabled = true;
    }
    if (isMyTodoOn) {
      disabled = true;
    }
    return disabled;
  };
  //status handling
  const handleStatusChecked = async (status, data) => {
    console.log(status, data, "AT STATUS");
    if (status === true) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 3,
            };
          }
          return x;
        })
      );
      setSidebarFilterData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 3,
            };
          }
          return x;
        })
      );

      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 3);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        setTaskData((prev) => {
          return prev.filter(
            (item) => String(item.task_id) !== String(data.task_id)
          );
        });
        setSidebarFilterData((prev) => {
          return prev.filter(
            (item) => String(item.task_id) !== String(data.task_id)
          );
        });

        // fetchTaskListing(); /* uncomment if needed */

        if (String(data.visibilityStatus) !== String(0)) {
          // const payload = {
          //   receiver_id: projectDetail.project_manager_id,
          //   message: 'Your Task Status has been Changed',
          //   project_id: id,
          //   task_id: data.task_id,
          //   project_name: projectDetail.project_name,
          // };
          // sendSocketNotification(payload);
          console.log(data, "task--");
          if (allCompletedListing) {
            const payload = {
              receiver_id: data.projectDetail.project_manager_id,
              message: "Your Task Status has been Changed",
              project_id: data.projectDetail.id,
              task_id: data.task_id,
              project_name: data.projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload, "notification-payload");
            sendSocketNotification(payload);
          }
          if (allTaskListing) {
            const payload = {
              receiver_id: data.projectDetail.project_manager_id,
              message: "Your Task Status has been Changed",
              project_id: data.projectDetail.id,
              task_id: data.task_id,
              project_name: data.projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload, "notification-payload");
            sendSocketNotification(payload);
          }
          if (mainTaskListing) {
            const payload = {
              receiver_id: projectDetail.project_manager_id,
              message: "Your Task Status has been Changed",
              project_id: id,
              task_id: data.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload, "notification-payload");
            sendSocketNotification(payload);
          }
        }
        enqueueSnackbar("Task status changed", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        fetchTaskListing();
      }
    } else {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 1,
            };
          }
          return x;
        })
      );

      setSidebarFilterData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 1,
            };
          }
          return x;
        })
      );
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 1);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        fetchTaskListing();
        if (String(data.visibilityStatus) !== String(0)) {
          // const payload = {
          //   receiver_id: projectDetail.project_manager_id,
          //   message: 'Your Task Status has been Changed',
          //   project_id: id,
          //   task_id: data.task_id,
          //   project_name: projectDetail.project_name,
          // };
          // sendSocketNotification(payload);
          if (allCompletedListing) {
            const payload = {
              receiver_id: data.projectDetail.project_manager_id,
              message: "Your Task Status has been Changed",
              project_id: data.projectDetail.id,
              task_id: data.task_id,
              project_name: data.projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload, "notification-payload");
            sendSocketNotification(payload);
          }
          if (allTaskListing) {
            const payload = {
              receiver_id: data.projectDetail.project_manager_id,
              message: "Your Task Status has been Changed",
              project_id: data.projectDetail.id,
              task_id: data.task_id,
              project_name: data.projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload, "notification-payload");
            sendSocketNotification(payload);
          }
          if (mainTaskListing) {
            const payload = {
              receiver_id: projectDetail.project_manager_id,
              message: "Your Task Status has been Changed",
              project_id: id,
              task_id: data.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload, "notification-payload");
            sendSocketNotification(payload);
          }
          enqueueSnackbar("Task status changed", { variant: "success" });
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        fetchTaskListing();
      }
    }
  };
  const handleTaskStatus = async (value, data) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            status: value,
          };
        }
        return x;
      })
    );
    setSidebarFilterData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            status: value,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("status", value);
    formData.append("task_id", data.task_id);

    const result = await changeStatus(formData);
    if (result.code === 200) {
      console.log("refetching-tasks");
      console.log(data, "task-data");
      fetchTaskListing();
      if (allCompletedListing) {
        const payload = {
          receiver_id: data.projectDetail.project_manager_id,
          message: "Your Task Status has been Changed",
          project_id: data.projectDetail.id,
          task_id: data.task_id,
          project_name: data.projectDetail.project_name,
          notification_type: "task",
        };
        console.log(payload, "notification-payload");
        sendSocketNotification(payload);
      }
      if (allTaskListing) {
        const payload = {
          receiver_id: data.projectDetail.project_manager_id,
          message: "Your Task Status has been Changed",
          project_id: data.projectDetail.id,
          task_id: data.task_id,
          project_name: data.projectDetail.project_name,
          notification_type: "task",
        };
        console.log(payload, "notification-payload");
        sendSocketNotification(payload);
      }
      if (mainTaskListing) {
        const payload = {
          receiver_id: projectDetail.project_manager_id,
          message: "Your Task Status has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        console.log(payload, "notification-payload");
        sendSocketNotification(payload);
      }
      // if (allCompletedListing) {
      //   const payload = {
      //     receiver_id: projectDetail.project_manager_id,
      //     message: 'Your Task Status has been Changed',
      //     project_id: id,
      //     task_id: data.task_id,
      //     project_name: projectDetail.project_name,
      //   };
      //   console.log(payload, 'notification-payload');
      //   sendSocketNotification(payload);
      // }

      if (!allTaskListing && !allCompletedListing) {
        if (verifiedListing === true) {
          if (String(value) !== "5") {
            let message = "Task moved back to project";
            showCustomSnackbar(data.task_id, message);
            // enqueueSnackbar('Task moved back to project', { variant: 'info' });
          }
        }
        if (!verifiedListing) {
          if (String(value) === "5") {
            let message = "Task moved to verified list";
            showCustomSnackbar(data.task_id, message);
            // enqueueSnackbar('Task moved to verified list', { variant: 'info' });
          } else enqueueSnackbar("Task status changed", { variant: "success" });
        }
      }
      if (allTaskListing) {
        enqueueSnackbar("Task status changed", { variant: "success" });
      }
      if (allCompletedListing) {
        enqueueSnackbar("Task status changed", { variant: "success" });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  const getUnreadCount = (count) => {
    if (!count) {
      return "Comments";
    }

    let message = "0 unread comments";
    if (Number(count) === 1) {
      message = `${count} unread comment`;
      return message;
    }
    if (Number(count) > 0) {
      message = `${count} unread comments`;
      return message;
    }
    return message;
  };
  const navigateToVerifiedListing = (taskId) => {
    navigate(`projects/verified-tasks`, {
      state: { project_id: id, project_detail: projectDetail, task_id: taskId },
    });
  };
  const navigateToProject = (taskId) => {
    navigate(`/projects/project/${location.state.project_id}`, {
      state: {
        project_id: location.state.project_id,
        project_detail: projectDetail,
        task_id: taskId,
      },
    });
  };
  const showCustomSnackbar = (taskId, message) => {
    const action = (key) => (
      <>
        {!verifiedListing ? (
          <Button
            onClick={() => {
              // console.log(`Moved task id is ${taskId}`);
              navigateToVerifiedListing(taskId);
            }}
            style={{ color: "white" }}
          >
            View
          </Button>
        ) : (
          <Button
            onClick={() => {
              // console.log(`Moved task id is ${taskId}`);
              navigateToProject(taskId);
            }}
            style={{ color: "white" }}
          >
            View
          </Button>
        )}
      </>
    );
    enqueueSnackbar(message, {
      variant: "info",
      autoHideDuration: 3000,
      action: action,
    });
  };
  //priority handling
  const handlePriority = async (value, id, task) => {
    console.log(task, "task--");
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            priority: value,
          };
        }
        return x;
      })
    );
    setSidebarFilterData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            priority: value,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_priority", value);
    formData.append("task_id", id);

    const result = await changePriority(formData);
    if (result.code === 200) {
      // fetchTaskListing();
      enqueueSnackbar("Task priority changed", { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //toggles
  const handleOpenEditTask = (data) => {
    setOpenEditTask(true);
    setRowData(data);
  };
  const handleOpenMemberSelect = (value, id) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.id === id) {
          return {
            ...x,
            openMemberSelect: value,
          };
        }
        return x;
      })
    );
  };
  const handleOpenCommentDrawer = (data) => {
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, "target-task");
    console.log(index, "target-index");
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseMemberSelect = (id) => {
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenTotalMemberListing(false);
    setTaskData((task) =>
      task.map((x) => {
        if (x.id === id) {
          return {
            ...x,
            openMemberSelect: false,
            openMemberListing: false,
          };
        }
        return x;
      })
    );
  };
  //date handling
  const onTargetDateTimeChange = (new_date_time, task) => {
    console.log(new_date_time, "new-date");

    handleTargetDate(new_date_time, task.task_id);
  };
  const handleTargetDate = async (date, id) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            targetDate: date,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", id);
    formData.append("target_date", date);
    // formData.append('target_date', moment(date).format('YYYY-MM-DD'));
    const result = await addTaskTargetDate(formData);
    if (result.code === 200) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === id) {
            return {
              ...x,
              targetDate: date,
            };
          }
          return x;
        })
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing();
    }
  };
  //conditions
  const isSection = (task) => {
    // console.log(task, 'tsak');
    let is_section = false;
    if (String(task.taskType) === String(2)) {
      is_section = true;
    }
    return is_section;
  };
  const shouldStatusOpen = () => {
    let should = true;
    if (!canOpenStatus) {
      return false;
    }
    return should;
  };
  const shouldPriorityOpen = () => {
    let should = true;
    if (!canOpenPriority) {
      return false;
    }
    return should;
  };
  //members
  const handleAddMembers = async (value, id) => {
    let assigner = localStorage.getItem("user_id");
    value = { ...value, ["task_assigner_id"]: assigner };
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", id);
    console.log(value, "add-mem");
    setTaskData((tasks) =>
      tasks.map((task) => {
        if (task.task_id === id) {
          console.log(task, "add-member");
          task = { ...task, ["isAssigned"]: true };
          task.members.push(value);
          return task;
        }
        return task;
      })
    );

    setSidebarFilterData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          console.log(task, "add-member");
          x = { ...x, ["isAssigned"]: true };
          x.members.push(value);
          return x;
        }
        return x;
      })
    );

    const result = await addTaskMembers(formData);
    if (result.code === 200) {
      console.log(taskdata, "after-add");
      //
    } else {
      fetchTaskListing();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDeleteMembers = async (value, id) => {
    let assigned = false;
    console.log(id, "id");
    console.log(taskdata, "task-data");
    console.log(value, "value");
    taskdata.map((task, index) => {
      if (String(task.task_id) === String(id)) {
        console.log(task, "found-task");
        if (task.isCreator === true) {
          assigned = true;
        }
        if (task.isAssigned === true) {
          assigned = true;
        }
      }
    });

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", id);
    console.log(...formData, "delete-members-req");
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            members: x.members.filter((y) => y.user_id !== value.user_id),
            isAssigned: assigned,
          };
        }
        return x;
      })
    );
    setSidebarFilterData((task) =>
      task.map((x) => {
        if (x.task_id === id) {
          return {
            ...x,
            members: x.members.filter((y) => y.user_id !== value.user_id),
            isAssigned: assigned,
          };
        }
        return x;
      })
    );
    const result = await deleteTaskMembers(formData);
    if (result.code === 200) {
      console.log(taskdata, "after-del");
    } else {
      fetchTaskListing();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  //hover
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target("task-html-description");
    }, 500);
  };
  //others
  const handleCopyTaskLink = (task) => {
    let newLink = "";
    let project_id = id;
    if (task.projectDetail) {
      project_id = task.projectDetail.id;
    }
    if (task.projectId) {
      project_id = task.projectId;
    }
    console.log(task, "to-be-copied");
    newLink = `${window.location.origin}/project/${project_id}/${task.task_id}`;
    console.log(newLink);
    navigator.clipboard.writeText(newLink);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };
  const handleProjectNavigate = (task) => {
    console.log(task);
    let project_id = task.projectDetail?.id;
    if (task.projectId) {
      project_id = task.projectId;
    }
    if (targetProjectId) {
      project_id = targetProjectId;
    }
    navigate(`/projects/project/${project_id}`, {
      state: { ...task, ["projectName"]: task.projectDetail?.project_name },
    });
  };
  const handleTaskClick = (task) => {
    if (canOpenSidebar) {
      handleOpenEditTask(task);
    }

    if (!canOpenSidebar) {
      handleProjectNavigate(task);
    }
  };
  const getDragTitle = () => {
    if (String(user.allow_reorder_task) === "0") {
      return "Not allowed";
    }
    let title = "Please remove filters";
    if (isMyTodoOn) {
      title = "Please disable My todo";
    }

    if (taskSortStatus.status) {
      title = "Please remove filters";
    }
    if (String(localStorage.getItem("taskFilter")) === String(1)) {
      title = "Please remove filters";
    }
    if (searchValue !== "") {
      title = "Please remove filters";
    }
    return title;
  };
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: "<p>No Description added!</p>" };
    }

    return { __html: html };
  };
  // console.log('task-data-card')
  const showNewCommentIndicator = (count) => {
    if (!count) return false;
    let show = false;
    let unread_count = Number(count);
    if (unread_count > 0) {
      return true;
    }
  };
  return (
    <div className="col-12">
      {isDraggable ? (
        <div
          className="row mt-3"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {getFilteredTasks(taskdata).length > 0 ? (
            getFilteredTasks(taskdata).map((task, index) => (
              <Draggable
                key={task.task_id}
                draggableId={task.task_id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    className="w-100 "
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    // style={{ background: isSection(task.task) && '#3ab469' }}
                  >
                    <Box
                      id={task.task_id}
                      sx={{ px: 5, py: 1, boxShadow: isSection(task) ? 0 : 3 }}
                      // className='not-drag col-12  not-drag-col-outer pl-1'
                      className={
                        snapshot.isDragging
                          ? "onDrag col-12 pl-1 "
                          : `not-drag-col-outer col-12 pl-1 ${
                              isSection(task) && "section-card-div"
                            }`
                      }
                      style={{
                        // borderLeft: `3px solid ${getStatusColor(task.status)}`,
                        borderLeft: `3px solid transparent`,
                      }}
                    >
                      {!isSection(task) ? (
                        <div role="button" tabIndex="0">
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: "50%",
                              transform: " translateY(-50%)",
                              zIndex: 2,
                            }}
                          >
                            {isDraggable && (
                              <>
                                {isDragDisabled() ? (
                                  <Tooltip title={getDragTitle()}>
                                    <IconButton style={{ float: "right" }}>
                                      <DragIndicatorIcon
                                        style={{ color: "#C4CDD5" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <IconButton
                                    disabled={false}
                                    {...provided.dragHandleProps}
                                    style={{ float: "right" }}
                                  >
                                    <DragIndicatorIcon />
                                  </IconButton>
                                )}
                              </>
                            )}
                            {showMenu && (
                              <IconButton
                                onClick={(e) =>
                                  handleOpenSectionMenu(e, task, index)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                            )}
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ paddingBottom: "5px" }}
                          >
                            {showCheckbox && (
                              <>
                                {localStorage.getItem("user_id") ===
                                task.task_creator_id ? (
                                  <Tooltip title={
                                    String(task.status) ===
                                    String(0) ? "Pending" : String(task.status) ===
                                    String(1) ? 'In Progress' : String(task.status) ===
                                    String(2) ? 'In Reviewe' : String(task.status) ===
                                    String(3) ? 'Live Completed' : String(task.status) ===
                                    String(4) ? 'Cancelled' : String(task.status) ===
                                    String(5) ? 'Live Verified' : String(task.status) ===
                                    String(6) ? 'Dev Completed' : String(task.status) ===
                                    String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                                  }> 
                                  <Checkbox
                                  style={{color: String(task.status) ===
                                    String(0) ? "#f5c900" : String(task.status) ===
                                    String(1) ? '#000' : String(task.status) ===
                                    String(2) ? '#fff' : String(task.status) ===
                                    String(3) ? '#51d821' : String(task.status) ===
                                    String(4) ? '#960031' : String(task.status) ===
                                    String(5) ? '#EC00FF' : String(task.status) ===
                                    String(6) ? '#3792cb' : String(task.status) ===
                                    String(7) ? '#f96300' : '#8C8B10' }}
                                    icon={<SquareIcon/>} 
                                    checked={
                                      String(task.status) === String(3) ||
                                      (String(task.status) === String(5) &&
                                        true)
                                    }
                                    onChange={(e) =>
                                      handleStatusChecked(
                                        e.target.checked,
                                        task
                                      )
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  </Tooltip>
                                ) : task.isAssigned === false ? (
                                  <Checkbox
                                    disabled
                                    checked={
                                      String(task.status) === String(3) ||
                                      (String(task.status) === String(5) &&
                                        true)
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                ) : (
                                  <Tooltip title={
                                    String(task.status) ===
                                    String(0) ? "Pending" : String(task.status) ===
                                    String(1) ? 'In Progress' : String(task.status) ===
                                    String(2) ? 'In Reviewe' : String(task.status) ===
                                    String(3) ? 'Live Completed' : String(task.status) ===
                                    String(4) ? 'Cancelled' : String(task.status) ===
                                    String(5) ? 'Live Verified' : String(task.status) ===
                                    String(6) ? 'Dev Completed' : String(task.status) ===
                                    String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                                  }> 
                                  <Checkbox
                                  style={{color: String(task.status) ===
                                    String(0) ? "#f5c900" : String(task.status) ===
                                    String(1) ? '#000' : String(task.status) ===
                                    String(2) ? '#fff' : String(task.status) ===
                                    String(3) ? '#51d821' : String(task.status) ===
                                    String(4) ? '#960031' : String(task.status) ===
                                    String(5) ? '#EC00FF' : String(task.status) ===
                                    String(6) ? '#3792cb' : String(task.status) ===
                                    String(7) ? '#f96300' : '#8C8B10' }}
                                    icon={<SquareIcon/>} 
                                    checked={
                                      String(task.status) === String(3) ||
                                      (String(task.status) === String(5) &&
                                        true)
                                    }
                                    onChange={(e) =>
                                      handleStatusChecked(
                                        e.target.checked,
                                        task
                                      )
                                    }
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                  </Tooltip>

                                )}
                              </>
                            )}
                            {localStorage.getItem("user_id") ===
                            task.task_creator_id ? (
                              <Tooltip
                                onOpen={(e) => handleOpenTooltip(e)}
                                title={
                                  <div id="task-description-tooltip">
                                    <div
                                      id="task-html-description"
                                      dangerouslySetInnerHTML={getTooltipHtml(
                                        task.description
                                      )}
                                    ></div>
                                  </div>
                                }
                                placement="top"
                                arrow
                              >
                                <Typography
                                  color={"#5a5a5a"}
                                  component="span"
                                  className="col-enabled"
                                  onClick={() => handleTaskClick(task)}
                                  sx={{ paddingRight: "4rem" }}
                                >
                                  {task.task}&nbsp;&nbsp;&nbsp;
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                onOpen={(e) => handleOpenTooltip(e)}
                                title={
                                  <div id="task-description-tooltip">
                                    <div
                                      id="task-html-description"
                                      dangerouslySetInnerHTML={getTooltipHtml(
                                        task.description
                                      )}
                                    ></div>
                                  </div>
                                }
                                placement="top"
                                arrow
                              >
                                <Typography
                                  component="div"
                                  color={"#5a5a5a"}
                                  className={
                                    task.isAssigned === false && "col-disabled"
                                  }
                                  onClick={() => handleTaskClick(task)}
                                  sx={{ paddingRight: "4rem" }}
                                >
                                  {task.task}&nbsp;&nbsp;&nbsp;
                                </Typography>
                              </Tooltip>
                            )}
                          </div>
                          {task.detailVisible === true ? (
                            <div className="row pl-2">
                              {/* ================================================= date picker ===================================== */}
                              <div className="col-lg-2 col-md-3 col-sm-3 mb-1 mb-sm-0">
                                {task.isCreator === false &&
                                task.isAssigned === false ? (
                                  <>
                                    {task.targetDate !== "" ? (
                                      <a
                                        style={{
                                          textDecorationLine: "underline",
                                          fontSize: 12,
                                          color: "green",

                                          padding: 0,
                                        }}
                                      >
                                        {moment(task.targetDate).format(
                                          "DD MMM, YYYY"
                                        )}
                                      </a>
                                    ) : task.isAssigned ? (
                                      <a
                                        style={{
                                          textDecorationLine: "underline",
                                          fontSize: 12,
                                          padding: 0,
                                        }}
                                      >
                                        Select Date
                                      </a>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      <TaskDateTimePicker
                                        value={task.targetDate}
                                        hasPortal={true}
                                        onChange={(new_date) =>
                                          onTargetDateTimeChange(new_date, task)
                                        }
                                        task={task}
                                      />
                                    </span>
                                    {/* {task.targetDate === '' ? (
                                      <DatePicker
                                        // dateFormat="YYYY-MM-DD"
                                        value={task.targetDate}
                                        selected={task.targetDate}
                                        onSelect={(date) => handleTargetDate(date, task.task_id)}
                                        minDate={new Date()}
                                        targetTask={task}
                                        customInput={<ExampleCustomInput targetTask={task} />}
                                      />
                                    ) : (
                                      <DatePicker
                                        // dateFormat="YYYY-MM-DD"
                                        value={task.targetDate}
                                        selected={new Date(task.targetDate)}
                                        onSelect={(date) => handleTargetDate(date, task.task_id)}
                                        minDate={new Date()}
                                        targetTask={task}
                                        customInput={<ExampleCustomInput targetTask={task} />}
                                      />
                                    )} */}
                                  </>
                                )}
                              </div>
                              <div className="col-lg-5 col-md-3 col-sm-3 mb-1">
                                <div className="row align-items-center ">
                                  <div className="col d-flex flex-row justify-content-start justify-content-sm-center ">
                                    <div className="mr-2">
                                      <Tooltip
                                        title={`Created by ${task.taskCreatorName}`}
                                        placement="bottom"
                                      >
                                        {task.taskCreatorImage !== "" ? (
                                          <Avatar
                                            alt="Remy Sharp"
                                            src={
                                              baseUri + task.taskCreatorImage
                                            }
                                            style={{ height: 30, width: 30 }}
                                          />
                                        ) : (
                                          <Avatar
                                            alt="Remy Sharp"
                                            style={{ height: 30, width: 30 }}
                                          >
                                            {task.taskCreatorName.substring(
                                              0,
                                              1
                                            )}
                                          </Avatar>
                                        )}
                                      </Tooltip>
                                    </div>
                                    <div className="mr-2">
                                      <PriorityPopover
                                        isCreator={task.isCreator}
                                        isAssigned={task.isAssigned}
                                        priority={task.priority}
                                        id={task.task_id}
                                        task={task}
                                        handleTaskPriority={handlePriority}
                                        shouldOpen={shouldStatusOpen()}
                                      />
                                    </div>
                                    <div className="mr-4">
                                      <StatusPopover
                                        isAssigned={task.isAssigned}
                                        isCreator={task.isCreator}
                                        status={task.status}
                                        id={task}
                                        handleStatus={handleTaskStatus}
                                        onStatusChecked={handleStatusChecked}
                                        shouldOpen={shouldStatusOpen()}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <div
                                        className="row align-items-center"
                                        style={{ position: "relative" }}
                                      >
                                        <div>
                                          <Tooltip
                                            onClick={() =>
                                              handleOpenCommentDrawer(task)
                                            }
                                            // title='Comments'
                                            title={getUnreadCount(
                                              task.unreadComments
                                            )}
                                          >
                                            <CommentIcon
                                              style={{ fontSize: "1em" }}
                                              htmlColor="#9f9f9f"
                                            />
                                          </Tooltip>
                                        </div>
                                        {showNewCommentIndicator(
                                          task.unreadComments
                                        ) && (
                                          <span className="new-comment-indicator">
                                            <CircleIcon
                                              htmlColor="red"
                                              style={{ fontSize: "0.5rem" }}
                                            />
                                          </span>
                                        )}
                                        <div style={{ zIndex: 2 }}>
                                          <div
                                            style={{
                                              marginLeft: "auto",
                                              marginRight: ".4rem",
                                            }}
                                          >
                                            <div>
                                              <Typography
                                                onClick={() =>
                                                  handleOpenCommentDrawer(task)
                                                }
                                                style={{
                                                  paddingInline: ".1rem",
                                                }}
                                                color="black"
                                                sx={{
                                                  cursor: "pointer",
                                                  fontSize: 12,
                                                }}
                                              >
                                                {task.commentsCount}
                                              </Typography>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {String(task.visibilityStatus) ===
                                        String(1) && (
                                        <div>
                                          <Tooltip
                                            onClick={() =>
                                              handleCopyTaskLink(task)
                                            }
                                            title="Copy task link"
                                          >
                                            <ContentCopyIcon
                                              style={{
                                                marginLeft: "1rem",
                                                color: "grey",
                                                fontSize: "0.9rem",
                                              }}
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!projectButton ? (
                                <div
                                  className="col-lg-5 col-md-3 col-sm-3 align-items-flex-start"
                                  style={{ display: "flex" }}
                                >
                                  {showAddMembers && (
                                    <>
                                      {String(task.visibilityStatus) === "1" &&
                                        task.isAssigned === true && (
                                          <span>
                                            <AddMembers
                                              onAddMember={handleAddMembers}
                                              onDeleteMember={
                                                handleDeleteMembers
                                              }
                                              membersData={projectMembers}
                                              onId={task.task_id}
                                              assignMembers={task.members}
                                              taskData={
                                                isFilterOn
                                                  ? sidebarFilterData
                                                  : taskdata
                                              }
                                            />
                                          </span>
                                        )}
                                    </>
                                  )}

                                  <MembersListing
                                    isCreator={task.isCreator}
                                    task={task}
                                    onClickPopover={handleOpenMemberSelect}
                                    open={task.openMemberSelect}
                                    onClose={() =>
                                      handleCloseMemberSelect(task.id)
                                    }
                                    onAddMember={handleAddMembers}
                                    onDeleteMember={handleDeleteMembers}
                                    onId={task.task_id}
                                    membersData={projectMembers}
                                    assignMembers={task.members}
                                    membersList={task.members}
                                    taskData={
                                      isFilterOn ? sidebarFilterData : taskdata
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="my-1 my-sm-0">
                                  <Tooltip title="Go to project">
                                    <Typography
                                      onClick={() =>
                                        handleProjectNavigate(task)
                                      }
                                      className="project-name-div"
                                      component="span"
                                    >
                                      {task.projectDetail?.project_name}
                                      {task.projectName
                                        ? task.projectName
                                        : null}
                                      {projectDetail?.project_name}
                                    </Typography>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div tabIndex="0" role="button">
                          <div
                            style={{
                              position: "absolute",
                              right: 10,
                              top: "50%",
                              transform: " translateY(-50%)",
                              zIndex: 2,
                            }}
                          >
                            {isDraggable && (
                              <>
                                {isDragDisabled() ? (
                                  <Tooltip title={getDragTitle()}>
                                    <IconButton style={{ float: "right" }}>
                                      <DragIndicatorIcon
                                        style={{ color: "#C4CDD5" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <IconButton
                                    disabled={false}
                                    {...provided.dragHandleProps}
                                    style={{ float: "right" }}
                                  >
                                    <DragIndicatorIcon htmlColor="black" />
                                  </IconButton>
                                )}
                              </>
                            )}
                            {showMenu && (
                              <>
                                {localStorage.getItem("user_id") ==
                                  task.task_creator_id && (
                                  <IconButton
                                    onClick={(e) =>
                                      handleOpenSectionMenu(e, task, index)
                                    }
                                  >
                                    <MoreVertIcon htmlColor="black" />
                                  </IconButton>
                                )}
                              </>
                            )}
                          </div>
                          <div className="row pl-1 ">
                            {/* ================================================= date picker ===================================== */}
                            <div className="col">
                              {/* section */}
                              <div>
                                <Typography
                                  onClick={() => handleTaskClick(task)}
                                  textAlign="left"
                                  className="section-text"
                                  sx={{ paddingRight: "4rem" }}
                                >
                                  {task.task}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Box>
                  </div>
                )}
              </Draggable>
            ))
          ) : (
            <div className="col-12 mt-5">
              <img src={noTask} alt="no task" style={{ margin: "auto" }} />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Task Found
              </Typography>
            </div>
          )}
        </div>
      ) : (
        <div className="row mt-3">
          {getFilteredTasks(taskdata).length > 0 ? (
            getFilteredTasks(taskdata).map((task, index) => (
              <div
                className="w-100"
                key={index}
                // style={{ background: isSection(task.task) && '#3ab469' }}
              >
                <Box
                  id={task.task_id}
                  sx={{ px: 5, py: 1, boxShadow: isSection(task) ? 0 : 3 }}
                  // className='not-drag col-12  not-drag-col-outer pl-1'
                  className={`not-drag-col-outer col-12 pl-1 ${
                    isSection(task) && "section-card-div"
                  }`}
                  //   className={
                  //     snapshot.isDragging
                  //       ? 'onDrag col-12 pl-1 '
                  //       : `not-drag-col-outer col-12 pl-1 ${isSection(task) && 'section-card-div'}`
                  //   }
                  style={{
                    // borderLeft: `3px solid ${getStatusColor(task.status)}`,
                    borderLeft: `3px solid transparent`,
                  }}
                >
                  {!isSection(task) ? (
                    <div role="button" tabIndex="0">
                      <div
                        style={{
                          position: "absolute",
                          right: 10,
                          top: "50%",
                          transform: " translateY(-50%)",
                          zIndex: 2,
                        }}
                      >
                        {showMenu && (
                          <IconButton
                            onClick={(e) =>
                              handleOpenSectionMenu(e, task, index)
                            }
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </div>
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingBottom: "5px" }}
                      >
                        {showCheckbox && (
                          <>
                            {localStorage.getItem("user_id") ===
                            task.task_creator_id ? (
                              <Tooltip title={
                                String(task.status) ===
                                String(0) ? "Pending" : String(task.status) ===
                                String(1) ? 'In Progress' : String(task.status) ===
                                String(2) ? 'In Reviewe' : String(task.status) ===
                                String(3) ? 'Live Completed' : String(task.status) ===
                                String(4) ? 'Cancelled' : String(task.status) ===
                                String(5) ? 'Live Verified' : String(task.status) ===
                                String(6) ? 'Dev Completed' : String(task.status) ===
                                String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                              }> 
                              <Checkbox
                              style={{color: String(task.status) ===
                                String(0) ? "#f5c900" : String(task.status) ===
                                String(1) ? '#000' : String(task.status) ===
                                String(2) ? '#fff' : String(task.status) ===
                                String(3) ? '#51d821' : String(task.status) ===
                                String(4) ? '#960031' : String(task.status) ===
                                String(5) ? '#EC00FF' : String(task.status) ===
                                String(6) ? '#3792cb' : String(task.status) ===
                                String(7) ? '#f96300' : '#8C8B10' }}
                                icon={<SquareIcon/>} 
                                checked={
                                  String(task.status) === String(3) ||
                                  (String(task.status) === String(5) && true)
                                }
                                onChange={(e) =>
                                  handleStatusChecked(e.target.checked, task)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              </Tooltip>
                            ) : task.isAssigned === false ? (
                              <Checkbox
                                disabled
                                checked={
                                  String(task.status) === String(3) ||
                                  (String(task.status) === String(5) && true)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            ) : (
                              <Tooltip title={
                                String(task.status) ===
                                String(0) ? "Pending" : String(task.status) ===
                                String(1) ? 'In Progress' : String(task.status) ===
                                String(2) ? 'In Reviewe' : String(task.status) ===
                                String(3) ? 'Live Completed' : String(task.status) ===
                                String(4) ? 'Cancelled' : String(task.status) ===
                                String(5) ? 'Live Verified' : String(task.status) ===
                                String(6) ? 'Dev Completed' : String(task.status) ===
                                String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                              }> 
                              <Checkbox
                              style={{color: String(task.status) ===
                                String(0) ? "#f5c900" : String(task.status) ===
                                String(1) ? '#000' : String(task.status) ===
                                String(2) ? '#fff' : String(task.status) ===
                                String(3) ? '#51d821' : String(task.status) ===
                                String(4) ? '#960031' : String(task.status) ===
                                String(5) ? '#EC00FF' : String(task.status) ===
                                String(6) ? '#3792cb' : String(task.status) ===
                                String(7) ? '#f96300' : '#8C8B10' }}
                                icon={<SquareIcon/>} 
                                checked={
                                  String(task.status) === String(3) ||
                                  (String(task.status) === String(5) && true)
                                }
                                onChange={(e) =>
                                  handleStatusChecked(e.target.checked, task)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              </Tooltip>
                            )}
                          </>
                        )}
                        {localStorage.getItem("user_id") ===
                        task.task_creator_id ? (
                          <Linkify
                          componentDecorator={(
                            decoratedHref: string,
                            decoratedText: string,
                            key: number
                          ) => (
                            <SecureLink
                              href={decoratedHref}
                              key={key}
                              target="_blank"
                            >
                              {decoratedText}
                            </SecureLink>
                          )}
                        >
                          <Tooltip
                            onOpen={(e) => handleOpenTooltip(e)}
                            title={
                              <div id="task-description-tooltip">
                                <div
                                  id="task-html-description"
                                  dangerouslySetInnerHTML={getTooltipHtml(
                                    task.description
                                  )}
                                ></div>
                              </div>
                            }
                            placement="top"
                            arrow
                          >
                            <Typography
                              color={"#5a5a5a"}
                              component="span"
                              className={`col-enabled ${
                                !showCheckbox && "ml-2"
                              }`}
                              onClick={() => handleTaskClick(task)}
                              sx={{ paddingRight: "4rem" }}
                            >
                              {task.task}&nbsp;&nbsp;&nbsp;
                            </Typography>
                          </Tooltip>
                          </Linkify>
                        ) : (
                          <Linkify
                            componentDecorator={(
                              decoratedHref: string,
                              decoratedText: string,
                              key: number
                            ) => (
                              <SecureLink
                                href={decoratedHref}
                                key={key}
                                target="_blank"
                              >
                                {decoratedText}
                              </SecureLink>
                            )}
                          >
                          <Tooltip
                            onOpen={(e) => handleOpenTooltip(e)}
                            title={
                              <div id="task-description-tooltip">
                                <div
                                  id="task-html-description"
                                  dangerouslySetInnerHTML={getTooltipHtml(
                                    task.description
                                  )}
                                ></div>
                              </div>
                            }
                            placement="top"
                            arrow
                          >
                            <Typography
                              component="div"
                              color={"#5a5a5a"}
                              className={
                                task.isAssigned === false && "col-disabled"
                              }
                              onClick={() => handleTaskClick(task)}
                              sx={{
                                paddingRight: "4rem",
                                marginLeft: showCheckbox ? "0px" : "8px",
                              }}
                            >
                              {task.task}&nbsp;&nbsp;&nbsp;
                            </Typography>
                          </Tooltip>
                          </Linkify>
                        )}
                      </div>
                      {task.detailVisible === true ? (
                        <div className="row pl-2">
                          {/* ================================================= date picker ===================================== */}
                          <div className="col-lg-2 col-md-3 col-sm-3 mb-1 mb-sm-0">
                            {task.isCreator === false &&
                            task.isAssigned === false ? (
                              <>
                                {task.targetDate !== "" ? (
                                  <a
                                    style={{
                                      textDecorationLine: "underline",
                                      fontSize: 12,
                                      color: "green",

                                      padding: 0,
                                    }}
                                  >
                                    {moment(task.targetDate).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </a>
                                ) : task.isAssigned ? (
                                  <a
                                    style={{
                                      textDecorationLine: "underline",
                                      fontSize: 12,
                                      padding: 0,
                                    }}
                                  >
                                    Select Date
                                  </a>
                                ) : null}
                              </>
                            ) : (
                              <>
                                <span>
                                  <TaskDateTimePicker
                                    hasPortal={true}
                                    value={task.targetDate}
                                    onChange={(new_date) =>
                                      onTargetDateTimeChange(new_date, task)
                                    }
                                    task={task}
                                  />
                                </span>
                                {/* {task.targetDate === "" ? (
                                  <DatePicker
                                    // dateFormat="YYYY-MM-DD"
                                    value={task.targetDate}
                                    selected={task.targetDate}
                                    onSelect={(date) =>
                                      handleTargetDate(date, task.task_id)
                                    }
                                    minDate={new Date()}
                                    targetTask={task}
                                    customInput={
                                      <ExampleCustomInput targetTask={task} />
                                    }
                                  />
                                ) : (
                                  <DatePicker
                                    // dateFormat="YYYY-MM-DD"
                                    value={task.targetDate}
                                    selected={new Date(task.targetDate)}
                                    onSelect={(date) =>
                                      handleTargetDate(date, task.task_id)
                                    }
                                    minDate={new Date()}
                                    targetTask={task}
                                    customInput={
                                      <ExampleCustomInput targetTask={task} />
                                    }
                                  />
                                )} */}
                              </>
                            )}
                          </div>
                          <div className="col-lg-5 col-md-3 col-sm-3 mb-1">
                            <div className="row align-items-center ">
                              <div className="col d-flex flex-row justify-content-start justify-content-sm-center ">
                                <div className="mr-2">
                                  <Tooltip
                                    title={`Created by ${task.taskCreatorName}`}
                                    placement="bottom"
                                  >
                                    {task.taskCreatorImage !== "" ? (
                                      <Avatar
                                        alt="Remy Sharp"
                                        src={baseUri + task.taskCreatorImage}
                                        style={{ height: 30, width: 30 }}
                                      />
                                    ) : (
                                      <Avatar
                                        alt="Remy Sharp"
                                        style={{ height: 30, width: 30 }}
                                      >
                                        {task.taskCreatorName.substring(0, 1)}
                                      </Avatar>
                                    )}
                                  </Tooltip>
                                </div>
                                <div className="mr-2">
                                  <PriorityPopover
                                    isCreator={task.isCreator}
                                    isAssigned={task.isAssigned}
                                    priority={task.priority}
                                    id={task.task_id}
                                    task={task}
                                    handleTaskPriority={handlePriority}
                                    shouldOpen={shouldPriorityOpen()}
                                  />
                                </div>
                                <div className="mr-4">
                                  <StatusPopover
                                    isAssigned={task.isAssigned}
                                    isCreator={task.isCreator}
                                    status={task.status}
                                    id={task}
                                    handleStatus={handleTaskStatus}
                                    onStatusChecked={handleStatusChecked}
                                    shouldOpen={shouldStatusOpen()}
                                  />
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="row align-items-center comment-icon-">
                                    <div>
                                      <Tooltip
                                        onClick={() =>
                                          handleOpenCommentDrawer(task)
                                        }
                                        // title='Comments'
                                        title={getUnreadCount(
                                          task.unreadComments
                                        )}
                                      >
                                        <CommentIcon
                                          style={{ fontSize: "0.8em" }}
                                          htmlColor="#9f9f9f"
                                        />
                                      </Tooltip>
                                    </div>
                                    <div style={{ zIndex: 2 }}>
                                      <div
                                        style={{
                                          marginLeft: "auto",
                                          marginRight: ".4rem",
                                        }}
                                      >
                                        <div>
                                          <Typography
                                            onClick={() =>
                                              handleOpenCommentDrawer(task)
                                            }
                                            style={{ paddingInline: ".1rem" }}
                                            color="black"
                                            sx={{
                                              cursor: "pointer",
                                              fontSize: 12,
                                            }}
                                          >
                                            {task.commentsCount}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {showCopy && (
                                    <>
                                      {String(task.visibilityStatus) ===
                                        String(1) && (
                                        <div>
                                          <Tooltip
                                            onClick={() =>
                                              handleCopyTaskLink(task)
                                            }
                                            title="Copy task link"
                                          >
                                            <ContentCopyIcon
                                              style={{
                                                marginLeft: "1rem",
                                                color: "grey",
                                                fontSize: "0.9rem",
                                              }}
                                            />
                                          </Tooltip>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {!projectButton ? (
                            <div
                              className="col-lg-5 col-md-3 col-sm-3 align-items-flex-start"
                              style={{ display: "flex" }}
                            >
                              {showAddMembers && (
                                <>
                                  {String(task.visibilityStatus) === "1" &&
                                    task.isAssigned === true && (
                                      <span>
                                        <AddMembers
                                          onAddMember={handleAddMembers}
                                          onDeleteMember={handleDeleteMembers}
                                          membersData={projectMembers}
                                          onId={task.task_id}
                                          assignMembers={task.members}
                                          taskData={
                                            isFilterOn
                                              ? sidebarFilterData
                                              : taskdata
                                          }
                                        />
                                      </span>
                                    )}
                                </>
                              )}

                              <MembersListing
                                isCreator={task.isCreator}
                                task={task}
                                onClickPopover={handleOpenMemberSelect}
                                open={task.openMemberSelect}
                                onClose={() => handleCloseMemberSelect(task.id)}
                                onAddMember={handleAddMembers}
                                onDeleteMember={handleDeleteMembers}
                                onId={task.task_id}
                                membersData={projectMembers}
                                assignMembers={task.members}
                                membersList={task.members}
                                taskData={
                                  isFilterOn ? sidebarFilterData : taskdata
                                }
                              />
                            </div>
                          ) : (
                            <div className="col-lg-5 col-md-3 col-sm-3 my-1 my-sm-0">
                              <Tooltip title="Go to project">
                                <Typography
                                  onClick={() => handleProjectNavigate(task)}
                                  className="project-name-div"
                                  component="span"
                                >
                                  {task.projectDetail?.project_name}
                                  {task.projectName ? task.projectName : null}
                                  {projectDetail?.project_name}
                                </Typography>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div tabIndex="0" role="button">
                      <div
                        style={{
                          position: "absolute",
                          right: 10,
                          top: "50%",
                          transform: " translateY(-50%)",
                          zIndex: 2,
                        }}
                      >
                        {isDraggable && (
                          <>
                            {isDragDisabled() ? (
                              <Tooltip title={getDragTitle()}>
                                <IconButton style={{ float: "right" }}>
                                  <DragIndicatorIcon
                                    style={{ color: "#C4CDD5" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                                disabled={false}
                                {...provided.dragHandleProps}
                                style={{ float: "right" }}
                              >
                                <DragIndicatorIcon htmlColor="black" />
                              </IconButton>
                            )}
                          </>
                        )}
                        {showMenu && (
                          <>
                            {localStorage.getItem("user_id") ==
                              task.task_creator_id && (
                              <IconButton
                                onClick={(e) =>
                                  handleOpenSectionMenu(e, task, index)
                                }
                              >
                                <MoreVertIcon htmlColor="black" />
                              </IconButton>
                            )}
                          </>
                        )}
                      </div>
                      <div className="row pl-1 ">
                        {/* ================================================= date picker ===================================== */}
                        <div className="col">
                          <div>
                            <Typography
                              onClick={() => handleTaskClick(task)}
                              textAlign="left"
                              className="section-text"
                              sx={{ paddingRight: "4rem" }}
                            >
                              {task.task}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </div>
            ))
          ) : (
            <div className="col-12 mt-5">
              <img src={noTask} alt="no task" style={{ margin: "auto" }} />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Task Found
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const getDateColor = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return "rgb(0, 171, 85)";
    }

    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return false;
    }
    return true;
  }
  return false;
};
export default TaskComponent;
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => {
    return (
      <Tooltip title="Target Date">
        {value !== "" ? (
          <>
            <a
              role="button"
              tabIndex="0"
              className="btn theme-text-color"
              onClick={onClick}
              onKeyDown={onClick}
              ref={ref}
              style={{
                textDecorationLine: "underline",
                fontSize: 12,
                padding: 0,
                color: getDateColor(value, targetTask.status),
              }}
            >
              {moment(value).format("DD MMM, YYYY")}
            </a>
            {showDangerIcon(value, targetTask.status) && (
              <>
                <PriorityHighIcon
                  htmlColor="red"
                  style={{ fontSize: "1rem" }}
                />
              </>
            )}
          </>
        ) : (
          <a
            role="button"
            tabIndex="0"
            className="btn theme-text-color"
            onClick={onClick}
            onKeyDown={onClick}
            ref={ref}
            style={{
              textDecorationLine: "underline",
              fontSize: 12,
              padding: 0,
            }}
          >
            Select Date
          </a>
        )}
      </Tooltip>
    );
  }
);
