import * as React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { filter } from 'lodash';
// material
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSnackbar } from 'notistack';
import StyledSearch from './components/search/StyledSearch';
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  IconButton,
  RadioGroup,
  Radio,
  AvatarGroup,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Avatar,
  Popover,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import '@szhsin/react-menu/dist/index.css';
import moment from 'moment';
// components
import Page from '../../components/Page';
import { ProjectFilterSidebar, EditTaskSidebar } from '../../components/_dashboard/projects';
import Sekeleton from '../../components/Sekeleton';
import { useSocket } from '../../Hooks/socketContext';
import {
  addTask,
  taskList,
  deleteTask,
  taskFilter,
  taskOrder,
  detailProject,
  changeTaskDetail,
  taskSectionsList,
  moveTaskToSection,
} from '../../DAL/Project/Project';
import { makeStyles } from '@mui/styles';
import TaskSort from './components/TaskSort';
import CommentsDrawer from './components/CommentsDrawer';
import ProjectDrawer from './components/ProjectDrawer';
import SectionSelect from './components/SectionSelect';
import PopoverSectionMenu from './components/PopoverSectionMenu';
import TaskComponent from 'src/components/TaskComponent';
import { alpha, styled } from '@mui/material/styles';
import { baseUri } from 'src/config/config';
import { LoadingButton } from '@mui/lab';
// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  taskTypeRadio: {
    '& label': {
      margin: '0px',
    },
    // '& span': {
    //   marginTop: '0.1rem',
    // },
  },
}));

const filterStatus = [
  {
    label: 'Pending',
    value: 'not_started',
    color: 'warning',
    filterValue: '0',
    type: 'status',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    color: 'secondary',
    filterValue: '1',
    type: 'status',
  },
  // { label: 'In Review', value: 'in_review', color: 'warning', filterValue: '2', type: 'status' },
  {
    label: 'Completed',
    value: 'completed',
    color: 'primary',
    filterValue: '3',
    type: 'status',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
    color: 'error',
    filterValue: '4',
    type: 'status',
  },
  {
    label: 'Critical',
    value: 'critical',
    color: 'error',
    filterValue: '0',
    type: 'priority',
  },
  {
    label: 'High',
    value: 'high',
    color: 'primary',
    filterValue: '1',
    type: 'priority',
  },
  {
    label: 'Medium',
    value: 'medium',
    color: 'success',
    filterValue: '2',
    type: 'priority',
  },
  {
    label: 'Low',
    value: 'low',
    color: 'info',
    filterValue: '3',
    type: 'priority',
  },
];
CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress variant='determinate' {...props} style={{ height: 35, width: 35 }} />
      <Tooltip title='Progress'>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            border: '1px solid #c5c5c5',
            borderRadius: '50%',
          }}
        >
          <Typography variant='caption' component='div' color='text.secondary'>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));
export default function TaskListing(props) {
  const classes = useStyles();
  const { sendSocketNotification, getUserProfileData } = useSocket();
  const navigate = useNavigate();
  const location_ = useLocation();
  console.log(location_, 'location--');
  const [location, setLocation] = useState(useLocation());
  const [projectStats, setProjectStats] = useState({
    all: 0,
    pending: 0,
    completed: 0,
  });

  const user = getUserProfileData();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [taskStats, setTaskStats] = useState({});
  const [projectDetail, setProjectDetail] = useState({});
  const [personal, setPersonal] = useState(false);
  const anchorTotalMembers = useRef(null);
  const [sidebarFilterData, setSidebarFilterData] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedSectionIndexPublic, setSelectedSectionIndexPublic] = React.useState(0);
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [selectedSectionIndexPrivate, setSelectedSectionIndexPrivate] = React.useState(0);
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [isDeletingTask, setIsDeletingTask] = useState(false);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const [isSectionSelectDisabled, setIsSectionSelectDisabled] = useState(false);
  const [taskSectionsListing, setTaskSectionsListing] = useState([]);
  const [currentSelectedSection, setCurrentSelectedSection] = useState(null);
  const getTaskSections = async (status) => {
    setIsSectionSelectDisabled(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);
    formData.append('project_id', id);
    formData.append('status', status);
    console.log(...formData, 'get-sections-req');
    const result = await taskSectionsList(formData);
    console.log(result, 'sections-res');
    if (result.code === 200) {
      setIsSectionSelectDisabled(false);
      setTaskSectionsListing(result.active_task_section);
      setCurrentSelectedSection(result.active_task_section[0]);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const openProjectDrawer = () => {
    setIsProjectDrawerOpen(true);
  };
  const closeProjectDrawer = () => {
    setIsProjectDrawerOpen(false);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleChangeFilterData = (value, key) => {
    setFilterData({
      ...filterData,
      [key]: value,
    });
  };

  const handleFilterData = async (data, chipdata) => {
    const _arr = [];
    const updatedStatus = chipdata.map((status) => {
      if (status.value === true) {
        _arr.push(filterStatus.find((x) => x.value === status.key));
      }
      return status;
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: JSON.parse(localStorage.getItem(`project_${id}`)).myFilter,
        filter: _arr,
        chipData: chipdata,
        filterData: data,
      })
    );
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);
    const filter = _arr.map((y, j) => {
      console.log(j, 'j');
      if (y.type === 'status') {
        formData.append(`status[${j}]`, y.filterValue);
      }
      if (y.type === 'priority') {
        formData.append(`priority[${j}]`, y.filterValue);
      }
      return y;
    });
    const result = await taskFilter(formData);
    console.log(result, 'task-filter');
    if (result.code === 200) {
      localStorage.setItem('taskFilter', '1');
      const userId = localStorage.getItem('user_id');
      let isAllowed = false;
      // setIsFilterOn(true)
      setFilterData(data);
      setFilterChip(chipdata);
      const tasks = result.tasks_tags.map((x, i) => {
        if (String(userId) === String(x.task_creator_id)) {
          isAllowed = true;
        }
        console.log(result, 'FOUND');
        return {
          task_id: x.task_id,
          task: x.task_name,
          targetDate: x.target_date === '0000-00-00' ? new Date() : x.target_date,
          // targetDate: moment(new Date(x.target_date)).format('DD.MM.YYYY HH:mm'),
          endDate: '',
          status: x.task_status,
          priority: x.task_priority,
          taskOrder: x.task_order,
          detailVisible: true,
          members: x.assign_member,
          // isAssigned: getAssignedTask(x.assign_member),
          isAssigned: getAssignedTask(x),
          isAllowed: isAllowed,
          isCreator: isAllowed,
          visibilityStatus: x.vasibility_status,
          taskCreatorName: x.task_creator_name,
          taskCreatorImage: x.task_creator_image,
          commentsCount: x.comment_count,
          createdDate: x.created_at,
          taskType: x.task_type,
          sectionId: x.section_id,
          description: x.task_distribution,
          unreadComments: x.unread_comment_count,
        };
      });
      console.log(tasks, 'created-obj-from-filter-api');
      setSidebarFilterData(tasks);
      setTaskData(tasks);
      setIsFilterOn(true);
    } else {
      setIsFilterOn(false);
      enqueueSnackbar(result.message, { variant: 'error' });
      // setIswaiting(false);
    }
  };
  const handleResetFilter = () => {
    setIsFilterOn(false);
    setFilterData({
      critical: false,
      high: false,
      medium: false,
      low: false,
      not_started: false,
      in_progress: false,
      in_review: false,
      completed: false,
      cancelled: false,
    });
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: false,
        filter: [],
        chipData: [],
      })
    );
    setMyFilter(false);
    const tempState = [...filterChip];
    setFilterChip(
      tempState.map((x) => {
        console.log();
        return {
          ...x,
          value: false,
        };
      })
    );
    fetchTaskListing();
    // handleCloseFilter();
    setOpenFilter(false);
  };

  const [openFilter, setOpenFilter] = useState(false);
  const [myFilter, setMyFilter] = useState(false);

  const [filterData, setFilterData] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    not_started: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    critical: false,
    high: false,
    medium: false,
    low: false,
    not_started: false,
    in_progress: false,
    in_review: false,
    completed: false,
    cancelled: false,
  });
  const [filterChip, setFilterChip] = useState([]);
  const [taskfield, setTaskField] = React.useState(false);
  const [taskName, setTaskName] = React.useState('');
  const [selectedRow, setRowData] = useState({});
  const [opendialog, setOpenDialog] = React.useState(false);
  const [openstatus, setOpenStatus] = useState(false);
  const [openpriority, setOpenPriority] = useState(false);
  const [projectMembers, setProjectMembers] = useState([]);
  const [openTotalMemberListing, setOpenTotalMemberListing] = useState(false);
  const [taskdata, setTaskData] = React.useState([]);
  const [taskSections, setTaskSections] = React.useState([]);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [isMyTodoOn, setIsMyTodoOn] = useState(false);
  const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState(false);
  const shouldScroll = useRef(true);
  const [isSectionMenuOpen, setIsSectionMenuOpen] = useState(false);
  const [sectionMenuAnchorEl, setSectionMenuAnchorEl] = useState(null);
  let allowDrag = false;
  if (localStorage.getItem('allowDrag') === '1') {
    allowDrag = true;
  }
  const [openEdit, setOpenEdit] = React.useState(false);
  const [taskType, setTaskType] = useState('0');
  const [taskSortStatus, setTaskSortStatus] = useState({ type: '', value: '', status: false });

  const getTaskType = (task) => {
    task = task.trim();
    let length = task.length;
    let char = task.charAt(length - 1);
    if (String(char) === ':') {
      if (String(localStorage.getItem('allowDrag')) === String(1)) {
        return '2';
      }
      return '1';
    }
    return '1';
  };
  const handleAddTask = async (e) => {
    e.preventDefault();
    if (!taskName) {
      enqueueSnackbar('task should not be empty', { variant: 'error' });
      return;
    }
    if (isCreatingTask) {
      return;
    }
    const task_Type = getTaskType(taskName);
    console.log(taskType, 'taskType');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_name', taskName);
    formData.append('project_id', id);
    formData.append('vasibility_status', taskType);
    if (taskSections.length > 0) {
      if (selectedSection) {
        formData.append('task_id', selectedSection.task_id);
      }
    }
    formData.append('task_type', task_Type);
    console.log(...formData, 'addTask-request');
    setIsCreatingTask(true);
    const result = await addTask(formData);
    if (result.code === 200) {
      setIsCreatingTask(false);
      fetchTaskListing();
      getTaskSections();
    } else {
      setIsCreatingTask(false);
      enqueueSnackbar(result.message, { variant: 'error' });
      // setIswaiting(false);
    }
    setTaskName('');
    setTaskField(false);
  };

  const handleSubmitUpdateTask = async (name, description, id) => {
    const taskType = getTaskType(name);
    const formData = new FormData(); // formdata object
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', id);
    formData.append('task_text', name);
    formData.append('description', description);
    formData.append('task_type', taskType);
    console.log(...formData, 'update-task-req');
    setIsUpdatingTask(true);
    const result = await changeTaskDetail(formData);
    if (result.code === 200) {
      setIsUpdatingTask(false);
      handleCloseUpdate();
      fetchTaskListing();
      enqueueSnackbar('Task detail updated', { variant: 'success' });
    } else {
      setIsUpdatingTask(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const handleOpenEditTask = (data) => {
    setOpenEditTask(true);
    setRowData(data);
  };

  const handleCloseEditTask = () => {
    setOpenEditTask(false);
  };

  const handleOpenUpdate = () => {
    setOpenEdit(true);
  };

  const handleCloseUpdate = () => {
    setOpenEdit(false);
  };

  const handleDeleteTask = async () => {
    console.log(selectedRow, 'row-data');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', selectedRow.task_id);
    formData.append('project_id', id);
    formData.append('task_order', selectedRow.taskOrder);
    formData.append('vasibility_status', selectedRow.visibilityStatus);
    console.log(...formData, 'delete-task-req');
    setIsDeletingTask(true);
    handleCloseSectionMenu();
    const result = await deleteTask(formData);
    if (result.code === 200) {
      setIsDeletingTask(false);
      handleCloseSectionMenu();
      handleCloseDialog();
      fetchTaskListing();
      enqueueSnackbar('Task deleted', { variant: 'success' });
    } else {
      setIsDeletingTask(false);
      handleCloseDialog();
      handleCloseSectionMenu();
      fetchTaskListing();
      enqueueSnackbar(result.message, { variant: 'error' });
    }
    const _arr = taskdata.filter((x) => x.id !== id);
    setTaskData(_arr);
  };

  const handleResetEditTask = () => {};

  const handleOpenDialog = (data, i) => {
    // console.log(data, i);
    setOpenDialog(true);
    // setRowData(selectedRow);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const showAddTask = () => {
    setTaskField(!taskfield);
  };

  const fetchProjectDetail = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);
    console.log(...formData, 'fetch-project-detail-req');
    const result = await detailProject(formData);
    if (result.code === 200) {
      console.log(result, 'project-detail');

      setTaskStats(result.task_stats);
      setProjectMembers(result.Team_Project);
      setProjectDetail(result.Team_Data);
      if (String(result.Team_Data.project_visibility_status) === '0') {
        setPersonal(true);
      }
      setIswaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const fetchPersonalTasks = () => {
    let privateTasks = [];
    taskdata.map((task, index) => {
      if (String(task.visibilityStatus) === String(0) && task.isCreator === true) {
        privateTasks.push(task);
      }
    });
    return privateTasks;
  };
  const fetchProjectTasks = () => {
    let publicTasks = [];
    taskdata.map((task, index) => {
      if (String(task.visibilityStatus) === String(1)) {
        publicTasks.push(task);
      }
    });
    return publicTasks;
  };
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, 'target-task');
    console.log(index, 'target-index');
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleCloseSectionMenu = () => {
    setIsSectionMenuOpen(false);
    setSectionMenuAnchorEl(null);
  };
  const handleSectionAnchorEl = (e) => {
    setSectionMenuAnchorEl(e.currentTarget);
  };

  const handleMoveToSection = async (section, destination) => {
    let _status = undefined;
    if (String(taskType) === '1') {
      _status = 1;
    }
    if (String(taskType) === '0') {
      _status = 0;
    }
    console.log(section, 'ss');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', selectedRow.task_id);
    formData.append('task_section_id', section.id);
    console.log(...formData, 'move-task-req');
    const resp = await moveTaskToSection(formData);
    if (resp.code === 200) {
      // setSelectedSectionIndex(0);
      enqueueSnackbar(`Task moved to ${section.section_name}`, { variant: 'success' });
      fetchTaskListing(0, _status);
    } else {
      fetchTaskListing(0, _status);
      enqueueSnackbar(resp.message, { variant: 'error' });
    }
    // if (!destination || !selectedRow) return;
    // if (personal) {
    //   let privateTasks = fetchPersonalTasks();
    //   console.log('filtered-drag-end', privateTasks);
    //   moveToSection(privateTasks, selectedRow, destination);
    //   return;
    // } else {
    //   let publicTasks = fetchProjectTasks();
    //   moveToSection(publicTasks, selectedRow, destination);
    // }
  };
  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    if (isMyTodoOn) return;
    //
    if (personal) {
      let privateTasks = fetchPersonalTasks();
      console.log('filtered-drag-end', privateTasks);
      handleReorderTasks(privateTasks, result);
      return;
    } else {
      let publicTasks = fetchProjectTasks();
      handleReorderTasks(publicTasks, result);
    }
  };
  const moveToSection = async (data, source, destination) => {
    if (!destination || !selectedRow) return;
    let sourceIndex = undefined;
    let destinationIndex = undefined;
    data.map((task, index) => {
      if (String(task.task_id) === String(destination.task_id)) {
        destinationIndex = index;
      }
      if (String(task.task_id) === String(selectedRow.task_id)) {
        sourceIndex = index;
      }
    });
    if (destinationIndex < sourceIndex) {
      destinationIndex = destinationIndex + 1;
    }
    console.log(destination, 'destination');
    console.log(selectedRow, 'source');
    const reOrderedItems = Array.from(data);
    const [recordedItem] = reOrderedItems.splice(sourceIndex, 1);
    let task_order = Number(destination.taskOrder);
    if (Number(destinationIndex) < Number(sourceIndex)) {
      task_order = task_order - 1;
      console.log('decreased-order', task_order);
    }
    reOrderedItems.splice(destinationIndex, 0, recordedItem);
    setTaskData(reOrderedItems);
    let destinationTask = reOrderedItems[destinationIndex];
    console.log(destinationTask, 'destinationTask');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', source.task_id);
    formData.append('project_id', id);
    // formData.append('task_order', data.length - destinationIndex);
    /* new task-order method */
    formData.append('task_order', task_order);
    formData.append('vasibility_status', destination.visibilityStatus);
    formData.append('section_id', destination.sectionId);
    console.log(...formData, 'req-change-order');
    setTaskData(reOrderedItems);
    const resp = await taskOrder(formData);
    if (resp.code === 200) {
      fetchTaskListing();
      enqueueSnackbar('Task Order changed', { variant: 'success' });
      console.log(resp, 'task-order-response');
    } else {
      fetchTaskListing();
      enqueueSnackbar(resp.message, { variant: 'error' });
    }
  };

  const handleReorderTasks = async (data, result) => {
    const reOrderedItems = Array.from(data);
    let destinationTask = reOrderedItems[result.destination.index];
    let sourceTask = reOrderedItems[result.source.index];
    const [recordedItem] = reOrderedItems.splice(result.source.index, 1);
    reOrderedItems.splice(result.destination.index, 0, recordedItem);
    setTaskData(reOrderedItems);

    console.log(destinationTask, 'destinationTask');
    console.log(sourceTask, 'sourceTask');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', result.draggableId);
    formData.append('project_id', id);
    // formData.append('task_order', data.length - result.destination.index);
    /* updated order sending method */
    formData.append('task_order', destinationTask.taskOrder);
    formData.append('vasibility_status', destinationTask.visibilityStatus);
    console.log(...formData, 'req-change-order');
    setTaskData(reOrderedItems);
    const resp = await taskOrder(formData);
    if (resp.code === 200) {
      enqueueSnackbar('Task Order changed', { variant: 'success' });
      console.log(resp, 'task-order-response');
      fetchTaskListing();
    } else {
      enqueueSnackbar(resp.message, { variant: 'error' });
    }
  };
  const getAssignedTask = (task) => {
    const userId = localStorage.getItem('user_id');
    const members = task.assign_member;

    const findAssign = members.find((t) => String(t.user_id) === String(userId));
    if (findAssign) {
      return true;
    }
    if (String(task.task_creator_id) === String(userId)) {
      return true;
    }
    return false;
  };

  const fetchTaskListing = async () => {
    if (JSON.parse(localStorage.getItem(`project_${id}`)).filter.length > 0) {
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('project_id', id);
      const filter = JSON.parse(localStorage.getItem(`project_${id}`)).filter.map((y, j) => {
        if (y.type === 'status') {
          formData.append(`status[${j}]`, y.filterValue);
        }
        if (y.type === 'priority') {
          formData.append(`priority[${j}]`, y.filterValue);
        }
        return y;
      });
      const result = await taskFilter(formData);
      console.log(result, 'filtered-task-listing');
      if (result.code === 200) {
        // setFilterData(data);
        const userId = localStorage.getItem('user_id');
        setFilterChip(JSON.parse(localStorage.getItem(`project_${id}`)).chipData);
        setFilterData(JSON.parse(localStorage.getItem(`project_${id}`)).filterData);
        const tasks = result.tasks_tags.map((x, i) => {
          let isAllowed = false;
          if (String(userId) === String(x.task_creator_id)) {
            isAllowed = true;
          }
          console.log(x, 'is-x');
          return {
            task_id: x.task_id,
            task: x.task_name,
            task_creator_id: x.task_creator_id,
            targetDate: x.target_date === '0000-00-00' ? new Date() : x.target_date,
            // targetDate: moment(new Date(x.target_date)).format('DD.MM.YYYY HH:mm'),
            endDate: '',
            status: x.task_status,
            priority: x.task_priority,
            taskOrder: x.task_order,
            detailVisible: true,
            members: x.assign_member,
            isAssigned: getAssignedTask(x),
            // isAssigned: getAssignedTask(x.assign_member),
            visibilityStatus: x.vasibility_status,
            isAllowed: isAllowed,
            isCreator: isAllowed,
            taskCreatorName: x.task_creator_name,
            taskCreatorImage: x.task_creator_image,
            commentsCount: x.comment_count,
            createdDate: x.created_at,
            taskType: x.task_type,
            sectionId: x.section_id,
            description: x.task_distribution,
            unreadComments: x.unread_comment_count,
          };
        });
        localStorage.getItem('taskFilter');
        console.log(tasks, 'filtered-modified-obj');
        setTaskData(tasks);
        localStorage.setItem('taskFilter', '1');
      } else {
        localStorage.setItem('taskFilter', '0');
        enqueueSnackbar(result.message, { variant: 'error' });
        // setIswaiting(false);
      }
    } else {
      localStorage.setItem('taskFilter', '0');
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('project_id', id);
      const result = await taskList(formData);
      if (result.code === 200) {
        console.log(result, 'unfiltered-tasks');
        // setIswaiting(false);
        // navigate(-1);task_name
        const userId = localStorage.getItem('user_id');
        const tasks = result.tasks_tags.map((x, i) => {
          console.log();
          let isAllowed = false;
          if (String(userId) === String(x.task_creator_id)) {
            isAllowed = true;
          }
          return {
            task_id: x.task_id,
            task: x.task_name,
            task_creator_id: x.task_creator_id,
            targetDate: x.target_date === '0000-00-00' ? '' : x.target_date,
            // targetDate: moment(new Date(x.target_date)).format('DD.MM.YYYY HH:mm'),
            endDate: '',
            status: x.task_status,
            priority: x.task_priority,
            taskOrder: x.task_order,
            detailVisible: true,
            members: x.assign_member,
            isAssigned: getAssignedTask(x),
            visibilityStatus: x.vasibility_status,
            isAllowed: isAllowed,
            isCreator: isAllowed,
            taskCreatorName: x.task_creator_name,
            taskCreatorImage: x.task_creator_image,
            commentsCount: x.comment_count,
            createdDate: x.created_at,
            taskType: x.task_type,
            sectionId: x.section_id,
            description: x.task_distribution,
            unreadComments: x.unread_comment_count,
          };
        });
        console.log(tasks, 'modified-obj-unfiltered');
        setTaskData(tasks);
        setTimeout(() => {
          if (shouldScroll.current === true) {
            handleScroll();
          }
        }, 1100);
        setIswaiting(false);
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        // setIswaiting(false);
      }
    }
  };
  const getTitle = () => {
    let title = projectDetail.project_name;
    title = `${title} | Projects`;

    return title;
  };
  const getProjectName = () => {
    let name = projectDetail.project_name;
    return name;
  };
  const handleScroll = () => {
    if (!location.state) return;
    if (!location.state.task_id) return;
    if (location.state.task_id === null) return;
    console.log(location.state, 'at-scroll-fn');
    let taskId = location.state.task_id;
    let task = document.getElementById(taskId);
    if (!task) {
      console.log('no element found');
      return;
    }
    const rects = task.getBoundingClientRect();
    const yAxis = rects.y;
    const height = rects.height;
    const xAxis = rects.x;
    // task.scrollIntoView({ block: 'end', inline: 'nearest' });
    task.style.transition = 'all .5s linear';
    task.style.border = 'solid 3px rgb(10, 238, 124)';
    window.scrollTo(xAxis, yAxis - height);
    shouldScroll.current = false;
    setTimeout(() => {
      task.style.border = 'solid 1px transparent ';
    }, 3000);
  };

  const handleApplyLocalFilter = (e) => {
    setMyFilter(e.target.checked);
    localStorage.setItem(
      `project_${id}`,
      JSON.stringify({
        myFilter: e.target.checked,
        filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
        chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
        filterData: JSON.parse(localStorage.getItem(`project_${id}`)).filterData,
      })
    );
  };
  const toggleTaskList = (taskType) => {
    if (String(taskType) === '1') {
      let todoStatus = localStorage.getItem('todoStatus');
      todoStatus = JSON.parse(todoStatus);
      setPersonal(false);
      setMyFilter(false);
      getTaskSections(1);
      fetchTaskListing();
      if (todoStatus === true) {
        setIsMyTodoOn(true);
        return;
      }
      setIsMyTodoOn(false);
    }
    if (String(taskType) === '0') {
      getTaskSections(0);
      let todoStatus = localStorage.getItem('todoStatus');
      todoStatus = JSON.parse(todoStatus);
      setPersonal(true);
      setIsMyTodoOn(false);
      fetchTaskListing();
    }
  };
  const calculateTaskStats = () => {
    let filtered = [];
    taskdata.map((task) => {
      if (String(task.taskType) !== String(2) && String(task.visibilityStatus) !== String(0)) {
        filtered.push(task);
      }
    });
    const all = filtered.length;
    let completed = 0;
    let pending = 0;
    taskdata.forEach((task) => {
      if (String(task.status) === '3' || String(task.status) === '5') {
        completed += 1;
      }
    });
    pending = all - completed;

    setProjectStats({ all, pending, completed });
  };

  const setFirstLocalStorage = () => {
    if (localStorage.getItem(`project_${id}`)) {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          myFilter: JSON.parse(localStorage.getItem(`project_${id}`)).myFilter,
          filter: JSON.parse(localStorage.getItem(`project_${id}`)).filter,
          chipData: JSON.parse(localStorage.getItem(`project_${id}`)).chipData,
          filterData: JSON.parse(localStorage.getItem(`project_${id}`)).filterData,
        })
      );
      setMyFilter(JSON.parse(localStorage.getItem(`project_${id}`)).myFilter);
    } else {
      localStorage.setItem(
        `project_${id}`,
        JSON.stringify({
          myFilter,
          filter: [],
          chipData: [],
          filterData: [],
        })
      );
    }
  };
  const handleTodoChange = (e) => {
    setIsMyTodoOn(e.target.checked);
    let status = 'false';
    if (e.target.checked === true) {
      status = 'true';
    }
    localStorage.setItem('todoStatus', status);
  };
  React.useEffect(() => {
    let todoStatus = localStorage.getItem('todoStatus');
    todoStatus = JSON.parse(todoStatus);
    if (todoStatus === true) {
      setIsMyTodoOn(true);
    }
    console.log(navigator, 'navigator');
  }, []);
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_task) => _task.task.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredTasks = (data) => {
    data = [...taskdata];
    if (searchValue !== '') {
      const filtered = handleLocalTaskSearch(searchValue, data);
      data = [...filtered];
    }

    if (taskSortStatus.status === true && taskSortStatus.type !== 'default') {
      const sorted = handleSort(taskSortStatus.type, taskSortStatus.value, data);
      console.log(sorted, 'sorted');
      data = [...sorted];
    }

    if (isMyTodoOn) {
      let todo = [];
      data.map((task, index) => {
        if (task.isAssigned === true && String(task.visibilityStatus) === String(1)) {
          todo.push(task);
        }
        if (isFilterOn) {
          let filtered = [];
          sidebarFilterData.map((task, index) => {
            if (task.isAssigned === true && String(task.visibilityStatus) === String(1)) {
              filtered.push(task);
            }
          });

          return filtered;
        }
      });
      if (isFilterOn) {
        let my_todo = [];
        sidebarFilterData.map((task, index) => {
          if (task.isAssigned === true && String(task.visibilityStatus) === String(1)) {
            my_todo.push(task);
          }
        });

        return my_todo;
      }

      return todo;
    }
    if (personal) {
      let personal_tasks = [];
      data.map((task) => {
        if (String(task.visibilityStatus) === String(0) && task.isCreator === true) {
          task.isAssigned = true;
          personal_tasks.push(task);
        }
      });

      if (isFilterOn) {
        let filtered_personal = [];

        sidebarFilterData.map((task, index) => {
          if (String(task.visibilityStatus) === '0' && task.isCreator === true) {
            task.isAssigned = true;
            filtered_personal.push(task);
          }
        });

        return filtered_personal;
      }

      return personal_tasks;
    }
    if (!personal) {
      let projectTasks = [];
      data.map((task, index) => {
        if (String(task.visibilityStatus) === '1') {
          projectTasks.push(task);
        }
      });
      if (isFilterOn) {
        let filtered = [];
        sidebarFilterData.map((task, index) => {
          if (String(task.visibilityStatus) === '1') {
            filtered.push(task);
          }
        });

        return filtered;
      }

      return projectTasks;
    }
  };
  const handleSort = (type, option, data) => {
    if (type === 'priority') {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ['intPriority']: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ['intPriority']: t2Priority };
        if (option === 'priorityDesc') {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === 'date') {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === 'oldest') {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ['isDateGreater']: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ['isDateGreater']: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === 'newest') {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ['isDateGreater']: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ['isDateGreater']: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });

      return sorted;
    }
  };

  const handleSortChange = (type, value) => {
    let status = true;
    console.log(type, 'tape');
    if (type === 'default') {
      status = false;
    }
    setTaskSortStatus({ ...taskSortStatus, ['status']: status, ['type']: type, ['value']: value });
  };
  const handleOpenTotalMemberListing = () => {
    setOpenTotalMemberListing(true);
  };

  const handleClose = (id) => {
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenTotalMemberListing(false);
  };
  const closeCommentsDrawer = () => {
    setIsCommentDrawerOpen(false);
  };
  const markCommentAsRead = (taskId) => {
    setTaskData((tasks) =>
      tasks.map((task) => {
        if (String(task.task_id) === String(taskId)) {
          return { ...task, unreadComments: 0 };
        }
        return task;
      })
    );
  };
  const handleOpenCommentDrawer = (data) => {
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  const getSections = (data) => {
    if (data.lenght < 0) return [];
    let sections = [];
    let Data = [];
    let user_id = localStorage.getItem('user_id');
    if (personal && String(taskType) === String(0)) {
      data.map((task) => {
        if (
          String(task.visibilityStatus) === String(0) &&
          String(task.task_creator_id) === String(user_id)
        ) {
          Data.push(task);
        }
      });
    }
    if (!personal && String(taskType) === String(1)) {
      data.map((task) => {
        if (String(task.visibilityStatus) === String(1)) {
          Data.push(task);
        }
      });
    }
    Data.map((task, index) => {
      if (String(task.taskType) === String(2)) {
        sections.push(task);
      }
    });
    if (sections.length > 0) {
      if (personal) {
        setSelectedSection(sections[selectedSectionIndexPrivate]);
      }
      if (!personal) {
        setSelectedSection(sections[selectedSectionIndexPublic]);
      }
    }
    return sections;
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const onSectionChange = (value) => {
    console.log(value, 'section-change');
  };
  const showAddTaskButton = () => {
    if (taskName === '') {
      return false;
    }
    return true;
  };
  const handleTaskInputBlur = () => {
    if (taskName === '') {
      setTaskField(false);
    }
  };
  const isDragDisabled = () => {
    if (String(user.allow_reorder_task) === '0') {
      return true;
    }
    let disabled = false;
    if (searchValue !== '') {
      disabled = false;
    }
    if (String(localStorage.getItem('taskFilter')) === String(1)) {
      disabled = true;
    }
    if (String(localStorage.getItem('taskFilter')) === String(0)) {
      disabled = false;
    }

    if (taskSortStatus.status === true) {
      disabled = true;
    }
    // if (searchValue !== '') {
    //   disabled = true;
    // }
    if (isMyTodoOn) {
      disabled = true;
    }
    return disabled;
  };
  React.useEffect(() => {
    if (personal) {
      getTaskSections(0);
      setTaskType('0');
    }
    if (!personal) {
      getTaskSections(1);
      setTaskType('1');
    }
  }, [personal]);
  React.useEffect(() => {
    document.title = getTitle();
  }, [projectDetail]);
  React.useEffect(() => {
    getTaskSections(1);
    setFirstLocalStorage();
    fetchTaskListing();
    fetchProjectDetail();
  }, []);

  React.useEffect(() => {
    calculateTaskStats();
  }, [taskdata]);
  React.useEffect(() => {
    setTaskSections(getSections(taskdata));
    if (taskSections.length > 0) {
      if (personal) {
        setSelectedSection(taskSections[selectedSectionIndexPrivate]);
      }
      if (!personal) {
        setSelectedSection(taskSections[selectedSectionIndexPublic]);
      }
    }
  }, [taskdata, taskType]);
  React.useEffect(() => {
    // if(project)
    setTaskType(String(projectDetail.project_visibility_status));
  }, [projectDetail]);
  React.useEffect(() => {
    if (personal) {
      setTaskType('0');
    }
    if (!personal) {
      setTaskType('1');
    }
  }, [personal]);

  React.useEffect(() => {
    if (isFilterOn) {
      getFilteredTasks(sidebarFilterData);
    }
  }, [sidebarFilterData]);

  React.useEffect(() => {
    console.log(selectedSection, 'selected-section');
  }, [selectedSection]);
  return (
    <Page title='Tasks | Support Task Portal'>
      <Container maxWidth='xl' style={{ backgroundColor: '#fff' }}>
        <div className='row main-header'>
          <div
            className='col-lg-4 col-md-12 col-sm-12 pl-0 align-items-center'
            style={{ display: 'flex' }}
          >
            <IconButton
              onClick={() => navigate('/project')}
              // onClick={() => navigate(location.state.backToDashboard ? '/dashboard' : '/project')}
            >
              <ArrowBackIcon />
            </IconButton>
            <Tooltip title='Click to View Description'>
              <span role='button' onClick={() => openProjectDrawer()}>
                <Typography style={{ fontsize: 16, fontWeight: 'bold', paddingLeft: 8 }}>
                  {getProjectName()}
                </Typography>
              </span>
            </Tooltip>
          </div>
          {/* <div className='col-lg-3 col-md-6 col-sm-12 header-date justify-content-center'>
            <Tooltip title='Start Date - End Date'>
              <Typography sx={{ cursor: 'pointer', fontSize: 12 }} className='date-css'>
                {moment(projectDetail.start_date).format('DD MMM, YYYY')} -{' '}
                {moment(projectDetail.end_date).format('DD MMM, YYYY')}
              </Typography>
            </Tooltip>
          </div> */}
          <div className='col-lg-3 col-md-3 col-sm-12 header-date justify-content-end justify-content-md-center mb-1 mb-md-0'>
            <div className='d-flex align-items-center'>
              <Tooltip title='Go to verified tasks'>
                <Button
                  onClick={() =>
                    navigate(`/verified-tasks`, {
                      state: { project_id: id, project_detail: projectDetail },
                    })
                  }
                  style={{ paddingInline: '1rem' }}
                  startIcon={<CheckCircleIcon />}
                >
                  Verified Tasks
                </Button>
              </Tooltip>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 col-sm-12 header-task ml-auto justify-content-end justify-content-md-center mb-1 mb-md-0'>
            <Typography sx={{ cursor: 'pointer', fontSize: 12 }} className='date-css'>
              Pending&nbsp;
            </Typography>
            <Tooltip title='Pending Task'>
              <div
                className='status-circle'
                style={{
                  backgroundColor: '#3366FF',
                }}
              >
                <Typography sx={{ cursor: 'pointer', fontSize: 12 }}>
                  {projectStats.pending}
                </Typography>
              </div>
            </Tooltip>
            <Typography sx={{ cursor: 'pointer', fontSize: 12 }} className='date-css'>
              &nbsp;Complete&nbsp;
            </Typography>
            <Tooltip title='Completed Task'>
              <div
                className='status-circle'
                style={{
                  backgroundColor: '#00AB55',
                }}
              >
                <Typography sx={{ cursor: 'pointer', fontSize: 12 }}>
                  {projectStats.completed}
                </Typography>
              </div>
            </Tooltip>
            <Typography sx={{ cursor: 'pointer', fontSize: 12 }} className='date-css'>
              &nbsp;Total&nbsp;
            </Typography>
            <Tooltip title='Total Task'>
              <div
                className='status-circle'
                style={{
                  backgroundColor: '#c5c5c5',
                }}
              >
                <Typography sx={{ cursor: 'pointer', fontSize: 12 }}>{projectStats.all}</Typography>
              </div>
            </Tooltip>
          </div>
          {/* <div className='col-lg-2 col-md-12 col-sm-12 header-progress'>
            <CircularProgressWithLabel
              value={
                projectStats.completed !== 0 && projectStats.all !== 0
                  ? (projectStats.completed / projectStats.all) * 100
                  : 0
              }
            />
          </div> */}
          <div className='ml-auto col-lg-2 col-md-12 col-sm-12 header-progress justify-content-end justify-content-md-center mb-1 mb-md-0'>
            <CircularProgressWithLabel
              value={
                projectStats.completed !== 0 && projectStats.all !== 0
                  ? (projectStats.completed / projectStats.all) * 100
                  : 0
              }
            />
            <Tooltip title='Team Members'>
              <AvatarGroup
                max={3}
                style={{ cursor: 'pointer' }}
                ref={anchorTotalMembers}
                onClick={() => handleOpenTotalMemberListing(true)}
              >
                {projectMembers.length > 0 &&
                  projectMembers.map((member, index) => (
                    <span key={index}>
                      {member.image !== '' ? (
                        <Avatar src={baseUri + member.image} style={{ width: 30, height: 30 }} />
                      ) : (
                        <Avatar style={{ width: 30, height: 30 }}>
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </span>
                  ))}
              </AvatarGroup>
            </Tooltip>
            <Popover
              open={openTotalMemberListing}
              onClose={() => handleClose()}
              anchorEl={anchorTotalMembers.current}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              PaperProps={{
                sx: {
                  mt: 1.5,
                  ml: 0.5,
                  width: 230,
                  height: 280,
                  overflow: 'auto',
                  boxShadow: (theme) => theme.customShadows.z20,
                  border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
                },
              }}
            >
              <ArrowStyle className='arrow' />
              <List>
                <ListItem>
                  <ListItemText primary='Team Members' />
                </ListItem>
                {projectMembers.length > 0
                  ? projectMembers.map((member, index) => (
                      <div key={index}>
                        <ListItem>
                          <ListItemIcon>
                            {member.image !== '' ? (
                              <Avatar
                                alt='Remy Sharp'
                                src={baseUri + member.image}
                                style={{ height: 30, width: 30 }}
                              />
                            ) : (
                              <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                                {member.first_name.substring(0, 1)}
                              </Avatar>
                            )}
                          </ListItemIcon>
                          <ListItemText>
                            {member.first_name} {member.last_name}
                          </ListItemText>
                        </ListItem>
                      </div>
                    ))
                  : ''}
              </List>
            </Popover>
          </div>
        </div>
        <Stack
          direction='row'
          flexWrap='wrap-reverse'
          alignItems='center'
          // justifyContent={'space-between'}
          justifyContent={personal ? 'space-between' : 'end'}
        >
          <span className='my-3 mr-auto mr-sm-0'>
            <StyledSearch
              value={searchValue}
              onChange={handleChangeSearchValue}
              placeHolder='Search Task...'
            />
          </span>
          {!personal && (
            <FormControlLabel
              style={{ marginLeft: 'auto', marginBlock: '0px' }}
              value='end'
              control={
                <Switch
                  color='primary'
                  checked={isMyTodoOn}
                  onChange={(e) => handleTodoChange(e)}
                />
              }
              label="Only show my todo's"
              labelPlacement='end'
            />
          )}
          <Stack direction='row' spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProjectFilterSidebar
              // formik={formik}
              setMyFilter={setIsFilterOn}
              ifFilterOn={isFilterOn}
              isTaskPersonal={personal}
              isFilterOn={myFilter}
              onChangeSwitch={handleApplyLocalFilter}
              isOpenFilter={openFilter}
              isFilterData={filterData}
              isFilterChip={filterChip}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              onFilterData={handleFilterData}
              onChangeFilterData={handleChangeFilterData}
            />
            <TaskSort
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              onSortChange={handleSortChange}
              data={null}
            />

            <EditTaskSidebar
              isOpenEditTask={openEditTask}
              isTaskData={selectedRow}
              onResetEditTask={handleResetEditTask}
              onOpenEditTask={handleOpenEditTask}
              onCloseEditTask={handleCloseEditTask}
              openUpdate={handleOpenUpdate}
              openEdit={openEdit}
              SubmitTask={handleSubmitUpdateTask}
              isUpdatingTask={isUpdatingTask}
            />
            <CommentsDrawer
              // isOpenEditTask={openEditTask}
              isTaskData={selectedRow}
              // onResetEditTask={handleResetEditTask}
              // onOpenEditTask={handleOpenEditTask}
              // onCloseEditTask={handleCloseEditTask}
              // openUpdate={handleOpenUpdate}
              // openEdit={openEdit}
              // SubmitTask={handleSubmitUpdateTask}
              getTaskList={fetchTaskListing}
              // handleRemoveImage={handleRemoveImage}
              // setIsImageDialogOpen={setIsImageDialogOpen}
              isCommentOpen={isCommentDrawerOpen}
              onCloseComment={closeCommentsDrawer}
              // handleOpen={handleOpenCommentDrawer}
              markCommentAsRead={markCommentAsRead}
              verifiedListing={false}
            />
            <ProjectDrawer
              onClose={closeProjectDrawer}
              isOpen={isProjectDrawerOpen}
              projectDetail={projectDetail}
            />
          </Stack>
        </Stack>
        <Stack
          mb={2}
          flexWrap='wrap'
          direction='row'
          position='relative'
          justifyContent='space-between'
        >
          <div className='col-sm-9 px-0 mb-3 mb-sm-0'>
            {taskfield === false ? (
              <div
                style={{
                  display: 'flex',
                  border: '1px dashed black',
                  width: '100%',
                  padding: 15,
                  borderRadius: 10,
                }}
                role='button'
                tabIndex='0'
                onClick={() => showAddTask()}
                onKeyDown={() => showAddTask()}
              >
                <AddIcon style={{ color: 'green' }} />
                <Typography sx={{ ml: 1 }}>Create Task</Typography>
              </div>
            ) : (
              <form className='w-100'>
                <TextField
                  label='Task'
                  className='w-100'
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyPress={(event) => (event.key === 'Enter' ? handleAddTask(event) : null)}
                  autoFocus
                  onBlur={() => {
                    handleTaskInputBlur();
                  }}
                />
              </form>
            )}
            {showAddTaskButton() && (
              <Tooltip title='Add task'>
                <span style={{ position: 'absolute', zIndex: 2, right: 4, top: 8 }}>
                  <IconButton disabled={isCreatingTask} onClick={(e) => handleAddTask(e)}>
                    {isCreatingTask ? (
                      <CircularProgress style={{ width: '20px', height: '20px' }} />
                    ) : (
                      <SendIcon />
                    )}
                  </IconButton>
                  {/* <IconButton onClick={(e) => handleAddTask(e)}>
                    <SendIcon />
                  </IconButton> */}
                </span>
              </Tooltip>
            )}
          </div>
          <div className='col-sm-3 pr-0 d-flex flex-col justify-content-end align-items-center'>
            <SectionSelect
              data={taskSections}
              onChange={onSectionChange}
              selectedIndexPublic={selectedSectionIndexPublic}
              setSelectedIndexPublic={setSelectedSectionIndexPublic}
              selectedIndexPrivate={selectedSectionIndexPrivate}
              setSelectedIndexPrivate={setSelectedSectionIndexPrivate}
              isTaskPersonal={personal}
              setSelectedSection={setSelectedSection}
            />
          </div>
        </Stack>

        {String(projectDetail.project_visibility_status) === '1' && (
          <Stack
            mb={2}
            direction='row'
            flexWrap='wrap-reverse'
            alignItems='center'
            justifyContent='space-between'
          >
            <div>
              <Button
                onClick={() => {
                  // toggleProjectTasks();
                  toggleTaskList('1');
                }}
                sx={{ marginRight: 1 }}
                color='inherit'
                variant={!personal ? 'contained' : 'outlined'}
                className={!personal && 'filterBtnActive'}
              >
                Project Tasks
              </Button>
            </div>
            <div style={{ marginRight: 'auto' }}>
              <Button
                // onClick={showPersonalTasks}
                onClick={() => {
                  // showPersonalTasks();
                  toggleTaskList('0');
                  // setIsFilterOn(false)
                }}
                color='inherit'
                variant={personal ? 'contained' : 'outlined'}
                className={personal && 'filterBtnActive'}
              >
                Personal tasks
              </Button>
            </div>
            {String(projectDetail.project_visibility_status) === '1' && (
              <Stack direction='row' alignItems='center'>
                <Typography>Task Type :</Typography>
                <FormControl component='fieldset'>
                  <RadioGroup
                    className={classes.taskTypeRadio}
                    // className='radio-group-project-type'
                    value={taskType}
                    onChange={(e) => setTaskType(e.target.value)}
                    row
                    aria-label='gender'
                    name='row-radio-buttons-group'
                  >
                    <FormControlLabel
                      sx={{ alignSelf: 'center' }}
                      value='1'
                      control={<Radio />}
                      label='Team'
                    />
                    <FormControlLabel
                      sx={{ alignSelf: 'center' }}
                      value='0'
                      control={<Radio />}
                      label='Personal'
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>
            )}
          </Stack>
        )}

        {iswaiting === false ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='row1'>
              {(provided) => (
                <TaskComponent
                  //passing states as props
                  provided={provided}
                  projectDetail={projectDetail}
                  taskSortStatus={taskSortStatus}
                  searchValue={searchValue}
                  isMyTodoOn={isMyTodoOn}
                  setOpenEditTask={setOpenEditTask}
                  setRowData={setRowData}
                  setIsCommentDrawerOpen={setIsCommentDrawerOpen}
                  setSectionMenuAnchorEl={setSectionMenuAnchorEl}
                  setIsSectionMenuOpen={setIsSectionMenuOpen}
                  projectMembers={projectMembers}
                  taskdata={taskdata}
                  setTaskData={setTaskData}
                  sidebarFilterData={sidebarFilterData}
                  setSidebarFilterData={setSidebarFilterData}
                  setOpenStatus={setOpenStatus}
                  setOpenPriority={setOpenPriority}
                  setOpenTotalMemberListing={setOpenTotalMemberListing}
                  //functions
                  fetchTaskListing={fetchTaskListing}
                  getFilteredTasks={getFilteredTasks}
                  //conditions
                  mainTaskListing={true}
                  verifiedListing={false}
                  isDraggable={true}
                  showMenu={true}
                  showMembersListing={true}
                  showAddMembers={true}
                  projectButton={false}
                  canOpenSidebar={true}
                  canOpenStatus={true}
                  canOpenPriority={true}
                  showCheckbox={true}
                  showCopy={true}
                  showMoveTo={true}
                />
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Sekeleton totalTask={projectStats.all} />
        )}

        {/* ========================== delete task dialog========================= */}

        <Dialog open={opendialog} onClose={handleCloseDialog}>
          <DialogTitle>Are you sure you want to delete this task ?</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <LoadingButton loading={isDeletingTask} onClick={handleDeleteTask}>
              Agree
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* ==================  Popovers ==================== */}
        <PopoverSectionMenu
          isOpen={isSectionMenuOpen}
          onOpen={handleOpenSectionMenu}
          onClose={handleCloseSectionMenu}
          anchorEl={sectionMenuAnchorEl}
          setAnchorEl={handleSectionAnchorEl}
          handleOpenDialog={handleOpenDialog}
          sections={taskSections}
          handleMoveSection={handleMoveToSection}
          selectedRow={selectedRow}
          isPersonal={personal}
          isDragDisabled={isDragDisabled}
          showMoveTo={true}
          data={taskSectionsListing}
        />
      </Container>
    </Page>
  );
}
