import {
  Container,
  Stack,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Box,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { filter } from "lodash";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Label from "src/components/Label";
import Page from "src/components/Page";
import {
  SaleListHead,
  SaleListToolbar,
  SaleMoreMenu,
} from "src/components/sale-leads";
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import { saleSectionsListing } from "src/DAL/Project/Project";
// ============================================ styling=========================

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "no", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "sale_count", label: "Sale leads count", alignRight: false },
  { id: "is_default", label: "Default", alignRight: false },
  //   { id: 'image', label: 'Image', alignRight: false },
  //   { id: 'email', label: 'Email', alignRight: false },
  //   { id: 'role', label: 'Role', alignRight: false },
  //   { id: 'department', label: 'Department', alignRight: false },
  { id: "sale_section_status", label: "Status", alignRight: false },
  // { id: "show_stat", label: "Show Stats", alignRight: false },
  { id: "default_status", label: "Section Type", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (b.id < a.id) {
    return -1;
  }
  if (b.id > a.id) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.sale_section_name.toLowerCase().indexOf(query.toLowerCase()) !==
        -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
const SaleSectionsListing = () => {
  /* <<--------------------------------------------------------->> */
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [selectedRow, setRowData] = useState();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("first_name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [opendialog, setOpenDialog] = React.useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isAddSectionSidebarOpen, setIsAddSectionSidebarOpen] = useState(false);
  const [targetRow, setTargetRow] = useState(undefined);

  const handleViewSales = (_id) => {
    navigate("/all-sales-listing/all", {
      state: {
        display_section_id: _id,
      },
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const fetchSectionListing = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await saleSectionsListing(formData);
    if (result.code === 200) {
      setData(result.all_sale_section);
      console.log(result, "res");
      setIswaiting(false);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getColor = (status) => {
    if (String(status) === "0") {
      return "default";
    }
    if (String(status) === "1") {
      return "info";
    }
    if (String(status) === "2") {
      return "success";
    }
  };
  useEffect(() => {
    fetchSectionListing();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;
  /* <<--------------------------------------------------------->> */
  return (
    <Page title="Sale Sections | Support Task Portal">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Sales Sections
          </Typography>
        </Stack>

        <Card>
          <SaleListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SaleListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, role, status, avatarUrl, isVerified } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return ( */}
                  {filteredUsers.map((x, i) => (
                    <TableRow
                      hover
                      key={i}
                      tabIndex={-1}
                      role="button"
                      className="pointer"
                      onClick={() => handleViewSales(x.sale_section_id)}

                      // selected={isItemSelected}
                      // aria-checked={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell> */}
                      <TableCell align="left">{i + 1}</TableCell>
                      <TableCell
                        onClick={() => handleViewSales(x.sale_section_id)}
                        sx={{ cursor: "pointer" }}
                        component="th"
                        scope="row"
                        padding="normal"
                      >
                        <Tooltip title="View sale leads">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {x.sale_section_name}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        {x.sale_count}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="normal">
                        <Label
                          variant="ghost"
                          color={x.is_default === "1" ? "success" : "error"}
                        >
                          {" "}
                          {x.is_default === "1" ? "Yes" : "No"}{" "}
                        </Label>
                      </TableCell>
                      {/* <TableCell align='left'>
                        {' '}
                        <Avatar src={baseUri + x.image} />
                      </TableCell> */}
                      {/* <TableCell align='left'>{x.updated_at ? x.updated_at : 'N/A'}</TableCell> */}
                      {/* <TableCell align='left'>{x.role}</TableCell> */}
                      {/* <TableCell align='left'>
                        {x.user_department.length > 0
                          ? x.user_department.map((y, j) => y.department_name).join(', ')
                          : 'N/A'}
                      </TableCell> */}
                      <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={
                            String(x.sale_section_status) === "0"
                              ? "error"
                              : "success"
                          }
                        >
                          {x.sale_section_status === "1"
                            ? "Active"
                            : "Inactive"}
                        </Label>
                      </TableCell>
                      {/*  <TableCell align="left">
                        <Label
                          variant="ghost"
                          color={
                            String(x.show_stat) === "0" ? "error" : "success"
                          }
                        >
                          {x.show_stat === "1" ? "Yes" : "No"}
                        </Label>
                      </TableCell> */}
                      <TableCell align="left">
                        <Label
                          // variant=''
                          color={getColor(x.default_status)}
                        >
                          {x.default_status === "1"
                            ? "  Free"
                            : x.default_status === "2"
                            ? "  Paid"
                            : "  Normal"}
                        </Label>
                      </TableCell>

                      {/* <TableCell align="right">
                          <SaleMoreMenu
                            setIsSidebarOpen={setIsSidebarOpen}
                            setTargetRow={setTargetRow}
                            targetRow={targetRow}
                            isUserData={x}
                            onOpenDeleteDialog={handleOpenDialog}
                            fetchSectionListing={fetchSectionListing}
                          />
                        </TableCell> */}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
};

export default SaleSectionsListing;
