import * as React from 'react';
import { Icon } from '@iconify/react';
import Scrollbar from 'src/components/Scrollbar';
import { LoadingButton } from '@mui/lab';
import { Drawer, Stack, Tooltip, IconButton, Divider } from '@mui/material';
import closeFill from '@iconify/icons-eva/close-fill';
import AddIcon from '@mui/icons-material/Add';
const ChatListSidebar = ({ isOpen, toggle, toggleAddGroup, children }) => {
  return (
    <Drawer
      disableEnforceFocus={true}
      anchor='left'
      open={isOpen}
      onClose={toggle}
      PaperProps={{
        sx: { width: { xs: '100%', sm: '390px', md: '390px' }, border: 'none', overflow: 'hidden' },
      }}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
        <IconButton onClick={toggle}>
          <Icon icon={closeFill} width={20} height={20} />
        </IconButton>
      </Stack>
      <Divider />
      {/* <div className='mt-3 mb-2 col'>
      <Typography fontWeight='bold'>New Message</Typography>
    </div> */}
      <Scrollbar className='new-msg-sidebar-mobile'>
        <Stack spacing={2} sx={{ p: 2, paddingTop: 0 }}>
          {children}
        </Stack>
      </Scrollbar>
      <Tooltip title='New Message' arrow>
        <IconButton onClick={toggleAddGroup} className='add-chat-btn-hidden' aria-label='add'>
          <AddIcon htmlColor='#fff' />
        </IconButton>
      </Tooltip>
    </Drawer>
  );
};

export default ChatListSidebar;
