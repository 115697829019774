import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import editFill from '@iconify/icons-eva/edit-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import AddIcon from '@mui/icons-material/Add';
import { set, sub, formatDistanceToNow } from 'date-fns';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSnackbar } from 'notistack';
import clockFill from '@iconify/icons-eva/clock-fill';
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  ListItemText,
  ListItem,
  Tooltip,
} from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { makeStyles } from '@mui/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScrollToBottom from 'react-scroll-to-bottom';
import moment from 'moment';
import { Menu } from '@szhsin/react-menu';
//
import Scrollbar from 'src/components/Scrollbar';
// import ColorManyPicker from '../../ColorManyPicker';
// import Messages from './Messages/Messages';
import {
  uploadTaskFiles,
  removeTaskFiles,
  detailTaskFiles,
  addTaskComments,
  taskComments,
  EditComment,
  deleteComment,
  markAllCommentsAsRead,
} from '../../../DAL/Project/Project';
import CkEditor from 'src/components/editor/CKEditor';
import { baseUri } from '../../../config/config';
import ConfirmDeleteComment from 'src/components/Confirmation/ConfirmDeleteComment';
import CommentPopover from 'src/components/Popovers/CommentPopover';
import { set_blank_target } from 'src/utils/DomUtils';
import { useParams } from 'react-router-dom';
import { useSocket } from 'src/Hooks/socketContext';
// import { timelineDotClasses } from '@mui/lab';

// ----------------------------------------------------------------------

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];
export const PRIORITY_OPTIONS = [
  { label: 'Critical', value: 'critical', color: 'error', filterValue: '0' },
  { label: 'High', value: 'high', color: 'primary', filterValue: '1' },
  { label: 'Medium', value: 'medium', color: 'success', filterValue: '2' },
  { label: 'Low', value: 'low', color: 'info', filterValue: '3' },
];

const getChipColor = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.color;
  }
  return chip.color;
};

const getLabel = (value) => {
  const chip = PRIORITY_OPTIONS.find((chip) => chip.value === value);
  if (!chip) {
    const chip2 = STATUS_OPTIONS.find((chip) => chip.value === value);
    return chip2.label;
  }
  return chip.label;
};

export const STATUS_OPTIONS = [
  {
    label: 'Not Started',
    value: 'not_started',
    color: 'success',
    filterValue: '0',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    color: 'secondary',
    filterValue: '1',
  },
  {
    label: 'In Review',
    value: 'in_review',
    color: 'warning',
    filterValue: '2',
  },
  {
    label: 'Completed',
    value: 'completed',
    color: 'primary',
    filterValue: '3',
  },
  { label: 'Cancelled', value: 'cancelled', color: 'error', filterValue: '4' },
];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' },
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107',
];

// ----------------------------------------------------------------------

CommentsDrawer.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    '&.Mui-focused': { outline: 'green' },
  },
  edit: {
    marginLeft: '.2rem',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  editCommentIcon: {
    fontSize: '1rem',
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: '100%',
    marginBlock: '.15rem',
    alignSelf: 'center',
    marginRight: '.1rem',
    marginLeft: '.5rem',
    '&:hover': {
      cursor: 'pointer',
      color: 'green',
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

function RenderContent(data) {
  /* shift menu button here */
  const getCommentStatus = (input) => {
    let label = 'Public';
    if (String(input) === String(0)) {
      label = 'Private';
    }
    return label;
  };
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const classes = useStyles();
  const getActualTime = (date) => {
    if (!date) {
      return '';
    }
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === '-') {
        date_safari = date_safari.replace('-', '/');
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(moment(date_safari).subtract(difference_safari, 'm')).format(
        'MMM,DD, hh:mm a'
        // ' DD, MMM YYYY HH:MM a'
      );
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(moment(serverDate).subtract(difference, 'm')).format(
        'MMM,DD, hh:mm a'
        // 'DD, MMM YYYY HH:MM a'
      );

      return setTime;
    }
  };
  /* const getActualTime = (date) => {
    //2022-02-23 09:52:33 server date example
    let date_safari = date;

    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === '-') {
        date_safari = date_safari.replace('-', '/');
      }
    }

    const serverDate = new Date(date); // todays date
    const difference = serverDate.getTimezoneOffset(); // another date
    const time = moment(moment(serverDate).subtract(difference, 'm'));
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(moment(date_safari).subtract(difference_safari, 'm'));
      // console.log(setTime, 'at-actual');
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(moment(serverDate).subtract(difference, 'm'));

      // console.log(setTime, 'at-actual');
      return setTime;
    }
    // return time;
  }; */
  const title = (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='subtitle2'>{data.user_name}</Typography>

        <div>
          <div className='d-flex'>
            <span
              className='mr-2'
              style={{
                background: 'rgb(246, 247, 248)',
                borderRadius: 3,
                padding: '1px 3px',
                color: `${String(data.status) === String(1) ? '#00aa55' : 'rgb(212, 69, 69)'}`,
                fontWeight: 'bold',
                fontSize: '10px',
              }}
            >
              {getCommentStatus(data.status)}
            </span>
            <Typography
              alignSelf='center'
              component='span'
              variant='body2'
              sx={{ color: 'text.secondary', fontSize: 10 }}
            >
              {/* <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 14, height: 14 }} /> */}
              {data && getActualTime(data.created_date)}

              {/* {data && moment(data.created_date).format('DD, MMM YYYY hh:mm:sa')} */}
            </Typography>
          </div>
        </div>
      </div>

      {data && <div dangerouslySetInnerHTML={getHtml(data.comment)}></div>}
      {/* <Typography component='span' variant='body2' sx={{ color: 'text.secondary' }}>
        {data && data.comment}
  
      </Typography> */}
    </>
  );

  return {
    avatar: <Avatar alt='title' src={baseUri + data.user_image} />,
    title,
    // detail: notification
  };
}
function NotificationItem({
  commentObj,
  setEditComment,
  commentObjIndex,
  setComment,
  commentsData,
  setCommentData,
  setCommentUpdate,
  getCommentsList,
  setSelectedCommentStatus,
  getTaskList,
}) {
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === 'Apple Computer, Inc.') {
      is_safari = true;
    }
    return is_safari;
  };
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { avatar, title } = RenderContent(commentObj);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeletingComment, setIsDeletingComment] = React.useState(false);

  // console.log(commentObj, 'comment')
  const handleEditComment = (commentObj) => {
    setSelectedCommentStatus(commentObj.status);
    console.log(commentObj, commentObjIndex, 'obj');
    setComment(commentObj.comment);
    setEditComment(commentObj);
    setCommentUpdate(true);
    setAnchorEl(null);
  };
  const handleDeleteDialogOpen = (state) => {
    setIsDeleteDialogOpen(state);
  };
  const handleDeleteComment = async () => {
    setIsDeleteDialogOpen(true);
    console.log(commentsData, 'obj');
    setIsDeletingComment(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('comment_id', commentObj.id);
    /* delete locally */
    setCommentUpdate(false);
    setComment('');
    let data = [...commentsData];
    commentsData.map((comment, index) => {
      if (comment.id === commentObj.id) {
        console.log(comment, 'to be deleted');
        data.splice(index, 1);
      }
    });
    setCommentData(data);
    console.log(data, 'after-del');
    /* delete through API */

    let response = await deleteComment(formData);
    console.log(response, 'response-delete');
    if (response.code === 200) {
      setIsDeleteDialogOpen(false);
      setIsDeletingComment(false);
      enqueueSnackbar('Comment deleted', { variant: 'success' });
      getTaskList();
      getCommentsList();
    } else {
      setIsDeleteDialogOpen(false);
      setIsDeletingComment(false);
      getTaskList();
      getCommentsList();
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };
  const isCreator = (commentObj) => {
    let userId = localStorage.getItem('user_id');
    const { action_perform_by } = commentObj;
    let creator = false;
    if (action_perform_by === userId) {
      creator = true;
    }
    return creator;
  };

  return (
    <Stack direction='row' alignItems='flex-start' className='comment-card my-2'>
      <ListItem
        disableGutters
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          width: '100%',
          pr: 0,
          // bgcolor: '#eaeaea'
        }}
      >
        <ListItemText primary={title} />
      </ListItem>

      <div className='pt-1'>
        <CommentPopover
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          isCreator={isCreator}
          handleEditComment={handleEditComment}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
          commentObj={commentObj}
        />
      </div>
      <ConfirmDeleteComment
        handleDelete={handleDeleteComment}
        commentObj={commentObj}
        commentsData={commentsData}
        setCommentData={setCommentData}
        isDeleteDialogOpen={isDeleteDialogOpen}
        handleDeleteDialogOpen={handleDeleteDialogOpen}
        setAnchorEl={setAnchorEl}
        isDeletingComment={isDeletingComment}
      />
    </Stack>
  );
}

export default function CommentsDrawer({
  onCloseComment,
  isTaskData,
  getTaskList,
  isCommentOpen,
  markCommentAsRead,
  verifiedListing,
  projectDetail,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { sendSocketNotification } = useSocket();
  const { id } = useParams();
  const [comment, setComment] = React.useState('');
  const [status, setStatus] = React.useState(localStorage.getItem('allow_check_comments') === "1" ? '0':'1');
  const [selectedCommentStatus, setSelectedCommentStatus] = React.useState('0');
  const [commentData, setCommentData] = React.useState([]);

  const addCommentRef = React.useRef();
  const [isCommentUpdating, setIsCommentUpdating] = useState(false);
  const [editComment, setEditComment] = useState();
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [sendingComment, setSendingComment] = useState(false);
  const [updatingComment, setUpdatingComment] = useState(false);

  const handleCancelUpdate = () => {
    setIsCommentUpdating(false);
    setComment('');
    setStatus('1');
  };
  const applyBlankTargetOnAnchorTag = (id) => {
    set_blank_target(id);
  };
  const getCommentsList = async () => {
    console.log(isTaskData.task_id, 'isTaskData.task_id');
    console.log(isTaskData, 'isTaskData');
    const formData = new FormData(); // formdata object
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', isTaskData.task_id);
    const result = await taskComments(formData);
    console.log(result, 'task-comments');
    if (result.code === 200) {
      console.log(result, 'comments-result');
      let reversed = result.comment_list.reverse();
      setCommentData(reversed);
      setTimeout(() => {
        //to open link in new tab
        applyBlankTargetOnAnchorTag('team-portal-comments-listing');
      }, 200);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const handleAddComments = async (e) => {
    e.preventDefault();

    if (isCommentUpdating) {
      setUpdatingComment(true);
      console.log(editComment, 'edit-comment-object');
      console.log('status', selectedCommentStatus)
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('commnet_id', editComment.id);
      formData.append('comment', comment);
      formData.append('status', selectedCommentStatus);
      console.log(commentData);
      /* editing locally */
      let data = [];
      commentData.map((userComment, index) => {
        if (userComment.id === editComment.id) {
          userComment['comment'] = comment;
          userComment['status'] = selectedCommentStatus;
        }
        data.push(userComment);
      });
      console.log(data, 'edited comment data');
      setCommentData(data);
      setComment('');
      setIsCommentUpdating(false);

      /* editing through api */
      let response = await EditComment(formData);
      console.log(response, 'response');
      if (response.code === 200) {
        setUpdatingComment(false);
        console.log('comment added');
        getCommentsList();
        getTaskList();
        // enqueueSnackbar('Comment edited', { variant: 'success' })
      } else {
        setUpdatingComment(false);
        getTaskList();
        getCommentsList();
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    if (!isCommentUpdating) {
      if (comment.trim() === ' ') {
        enqueueSnackbar('Comment cannot be empty', { variant: 'error' });
        return;
      }
      if (comment.trim() === '') {
        enqueueSnackbar('Comment cannot be empty', { variant: 'error' });
        return;
      }
      setSendingComment(true);
      console.log(isCommentUpdating, 'add comment');
      console.log("Status report",status);
      const formData = new FormData();
      formData.append('token', localStorage.getItem('token'));
      formData.append('task_id', isTaskData.task_id);
      formData.append('comment', comment);
      formData.append('comment_image', '');
      formData.append('status', status);

      const result = await addTaskComments(formData);
      if (result.code === 200) {
        setSendingComment(false);
        console.log(result, 'result');
        getCommentsList();
        getTaskList();
        setComment('');
        if (String(isTaskData.visibilityStatus) !== '0') {
          //for admin
          // const payload_admin = {
          //   receiver_id: projectDetail.project_manager_id,
          //   message: 'A new comment has been added',
          //   project_id: id,
          //   task_id: isTaskData.task_id,
          //   project_name: projectDetail.project_name,
          //  notification_type: 'task',
          // };
          // console.log(payload_admin, 'admin-notification-payload');
          // sendSocketNotification(payload_admin);
          if (String(localStorage.getItem('user_id')) !== String(isTaskData.task_creator_id)) {
            //for task creator
            const payload_creator = {
              receiver_id: isTaskData.task_creator_id,
              message: 'A new comment has been added',
              project_id: id,
              task_id: isTaskData.task_id,
              project_name: projectDetail.project_name,
              notification_type: 'task',
            };
            console.log(payload_creator, 'creator-notification-payload');
            sendSocketNotification(payload_creator);
          }
          //for team
          isTaskData.members.map((member) => {
            if (String(localStorage.getItem('user_id')) !== String(member.user_id)) {
              const payload_team = {
                receiver_id: member.user_id,
                message: 'A new comment has been added',
                project_id: id,
                task_id: isTaskData.task_id,
                project_name: projectDetail.project_name,
                notification_type: 'task',
              };
              console.log(payload_team, 'members-notification-payload');
              sendSocketNotification(payload_team);
            }
          });
        }
      } else {
        setSendingComment(false);
        getTaskList();
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
  };
  const isSendButtonDisabled = () => {
    let disabled = true;

    if (isTaskData.isAssigned === false) {
      return true;
    }
    if (localStorage.getItem('user_id') == isTaskData.task_creator_id) {
      disabled = false;
    }
    if (isTaskData.isAssigned === true) {
      disabled = false;
    }
    if (sendingComment) {
      disabled = true;
    }
    if (!sendingComment) {
      disabled = false;
    }

    return disabled;
  };
  const markCommentsAsRead = async (taskId) => {
    console.log(taskId, 'mark as read');
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('task_id', taskId);
    console.log(...formData, 'mark-read-req');
    const response = await markAllCommentsAsRead(formData);
    if (response.code === 200) {
      console.log(response, 'read-resp');

      markCommentAsRead(taskId);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };
  useEffect(async () => {
    if (isCommentOpen) {
      getCommentsList();
      if (verifiedListing === false) {
        console.log('marking-read');
        markCommentsAsRead(isTaskData.task_id);
      }
      console.log(isTaskData, 'task-at-comment-drawer');
    }
    setCommentData([]);
  }, [isCommentOpen]);
  useEffect(() => {
    if (isCommentOpen) {
      setIsCommentUpdating(false);
      setComment('');
      setIsTextFieldFocused(false);
    }
    // console.log(isTaskData, 'task-at-comment-drawer');
  }, [isCommentOpen]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor='right'
        open={isCommentOpen}
        onClose={onCloseComment}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '450px', md: '450px' },
            border: 'none',
            overflow: 'hidden',
          },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ px: 1 }}>
          <IconButton onClick={onCloseComment}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <div className='comments-outerContainer' style={{ marginTop: 10 }}>
          <div className='comments-container' id='team-portal-comments-listing'>
            <div role='button' className='py-1' style={{ paddingInline: '5px' }}>
              {/* <Tooltip title='Task' placement='top'> */}
              <Typography style={{ fontsize: 16, fontWeight: 'bold' }}>
                {isTaskData.task}
              </Typography>
              {/* </Tooltip> */}
            </div>
            {/* <InfoBar /> */}
            <ScrollToBottom className='messages'>
              <Scrollbar>
                {commentData.length > 0 ? (
                  commentData.map((commentObj, index) => (
                    <NotificationItem
                      key={index}
                      getCommentsList={getCommentsList}
                      commentsData={commentData}
                      setCommentData={setCommentData}
                      targetRef={addCommentRef}
                      setEditComment={setEditComment}
                      commentStatus={status}
                      commentObj={commentObj}
                      setComment={setComment}
                      commentObjIndex={index}
                      setCommentUpdate={setIsCommentUpdating}
                      isTextFieldFocused={isTextFieldFocused}
                      setIsTextFieldFocused={setIsTextFieldFocused}
                      setSelectedCommentStatus={setSelectedCommentStatus}
                      selectedCommentStatus={selectedCommentStatus}
                      getTaskList={getTaskList}
                    />
                  ))
                ) : (
                  <Typography>No comments yet</Typography>
                )}
              </Scrollbar>
            </ScrollToBottom>
            {/* <Input /> */}
            <form className={`form ${classes.formMargin}`} onSubmit={handleAddComments}>
              <div className='comments-input'></div>
              <div className='commentItemParent'>
                <CkEditor
                  mode={isTaskData.isAssigned ? false : true}
                  content={comment}
                  setContent={(value) => setComment(value)}
                />
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row' style={{ paddingInline: '15px' }}>
                    <div className='pr-2 pl-md-0'>
                  
                   
                      <FormControl component='fieldset'>
                        {!isCommentUpdating ? (
                          <RadioGroup
                            row
                            aria-label='gender'
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            name='row-radio-buttons-group'
                          >
                            <FormControlLabel
                              value={'1'}
                              control={
                                <Radio
                                  disabled={
                                    localStorage.getItem('user_id') == isTaskData.task_creator_id ||
                                    isTaskData.isAssigned === true
                                      ? false
                                      : true
                                  }
                                />
                              }
                              label='Public'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                            <FormControlLabel
                              value={'0'}
                              control={
                                <Radio
                                  disabled={
                                    localStorage.getItem('user_id') == isTaskData.task_creator_id ||
                                    isTaskData.isAssigned === true
                                      ? false
                                      : true
                                  }
                                />
                              }
                              label='Private'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                          </RadioGroup>
                        ) 
                       : (
                         <RadioGroup
                            row
                            aria-label='gender'
                            value={selectedCommentStatus}
                            onChange={(e) => setSelectedCommentStatus(e.target.value)}
                            name='row-radio-buttons-group'
                          >
                            <FormControlLabel
                              value='1'
                              control={<Radio />}
                              label='Public'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                            <FormControlLabel
                              value='0'
                              control={<Radio />}
                              label='Private'
                              style={{ marginBottom: 0, fontSize: 10 }}
                            />
                          </RadioGroup>
                        )}
                      </FormControl>
                    </div>
                    <div className='mt-1 pr-2 pl-md-0' style={{ marginLeft: 'auto' }}>
                      {!isCommentUpdating ? (
                        <Button
                          className={classes.noShadow}
                          variant='contained'
                          type='submit'
                          style={{ width: '5rem' }}
                          disabled={isSendButtonDisabled()}
                        >
                          {sendingComment ? 'Sending...' : 'Send'}
                        </Button>
                      ) : (
                        <Button
                          className={classes.noShadow}
                          variant='contained'
                          type='submit'
                          style={{ width: '5rem' }}
                          disabled={
                            localStorage.getItem('user_id') == isTaskData.task_creator_id ||
                            isTaskData.isAssigned === true
                              ? false
                              : true
                          }
                        >
                          Update
                        </Button>
                      )}
                      {isCommentUpdating && (
                        <span onClick={handleCancelUpdate} className={classes.cancelBtn}>
                          cancel
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* confirmation dialog */}
      </Drawer>
    </>
  );
}
