import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Form, FormikProvider } from "formik";
import editFill from "@iconify/icons-eva/edit-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import roundClearAll from "@iconify/icons-ic/round-clear-all";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import AddIcon from "@mui/icons-material/Add";
import { set, sub, formatDistanceToNow } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import clockFill from "@iconify/icons-eva/clock-fill";
// material
import {
  Box,
  Radio,
  Avatar,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
//

import CkEditor from "src/components/editor/CKEditor";
import { set_blank_target } from "src/utils/DomUtils";
import Scrollbar from "src/components/Scrollbar";
import { updateSaleDescription } from "src/DAL/Project/Project";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

EditSaleSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object,
};

const useStyles = makeStyles(({ theme }) => ({
  textArea: {
    "&.Mui-focused": { outline: "green" },
  },
  edit: {
    marginLeft: ".2rem",
    "& :hover": {
      cursor: "pointer",
    },
  },
  editCommentIcon: {
    fontSize: "1rem",
  },
  formMargin: {
    marginTop: 10,
  },
  cancelBtn: {
    width: "100%",
    marginBlock: ".15rem",
    alignSelf: "center",
    marginRight: ".1rem",
    marginLeft: ".5rem",
    "&:hover": {
      cursor: "pointer",
      color: "green",
    },
  },
  noShadow: {
    boxShadow: "none",
  },
}));
export default function EditSaleSidebar({
  isOpenEditSaleSidebar,
  onCloseSaleSidebar,
  selectedItem,
  getAllSalesListing,
  prevSections,
  sections,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isUpdating, setIsUpdating] = useState(false);
  const [saleDescription, setSaleDescription] = useState("");
  const [questionAns, setQuestionAns] = useState("");
  const [saleTitle, setSaleTitle] = useState("");
  const getHtml = (html) => {
    if (!html) return;
    return { __html: html };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    prevSections.current = sections;
    setIsUpdating(true);
    if (!selectedItem) return;
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_description", saleDescription);
    formData.append("sale_id", selectedItem.id);
    formData.append("sale_title", saleTitle);
    console.log(...formData, "update-sale-desc-req");
    const result = await updateSaleDescription(formData);
    if (result.code === 200) {
      setIsUpdating(false);
      enqueueSnackbar("Sale description updated", { variant: "success" });
      getAllSalesListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsUpdating(false);
      console.log(result, "error");
    }
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    if (!date) {
      return "-";
    }
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === "-") {
        date_safari = date_safari.replace("-", "/");
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(
        moment(date_safari).subtract(difference_safari, "m")
      ).format(" DD, MMM YYYY ");
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(
        moment(serverDate).subtract(difference, "m")
      ).format("DD, MMM YYYY ");

      return setTime;
    }
  };
  const getPhone = (phone) => {
    if (phone === "") {
      return " - ";
    }
    return phone;
  };
  const getQuestionHtml = (html) => {
    return { __html: html };
  };
  useEffect(async () => {
    if (isOpenEditSaleSidebar) {
      if (selectedItem) {
        setSaleDescription(selectedItem.sale_description);
        setQuestionAns(selectedItem.answers_detail);
        setSaleTitle(selectedItem.sale_title ? selectedItem.sale_title : "");
      }
    }
  }, [isOpenEditSaleSidebar]);
  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        open={isOpenEditSaleSidebar}
        onClose={onCloseSaleSidebar}
        PaperProps={{
          sx: {
            width: { xs: "100%", sm: 500 },
            border: "none",
            overflow: "hidden",
          },
          // sx: { width: { xs: 340, md: 380 }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1 }}
        >
          <IconButton onClick={onCloseSaleSidebar}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={2} sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-wrap w-100 align-items-center align-content-center">
                    <div className="mr-3">
                      <Typography
                        component="span"
                        style={{ fontsize: 16, fontWeight: "bold" }}
                      >
                        Name :
                      </Typography>
                      <Typography component="span">
                        {selectedItem &&
                          ` ${selectedItem.first_name} ${selectedItem.last_name}`}
                      </Typography>
                    </div>
                    <div className="mr-3">
                      <Typography
                        component="span"
                        style={{
                          fontsize: 16,
                          fontWeight: "bold",
                          marginTop: 0,
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography component="span">
                        {selectedItem && " " + selectedItem.client_email}
                      </Typography>
                    </div>
                    <div className="mr-3">
                      <Typography
                        component="span"
                        style={{
                          fontsize: 16,
                          fontWeight: "bold",
                          marginTop: 0,
                        }}
                      >
                        Phone :
                      </Typography>
                      <Typography component="span">
                        {selectedItem && getPhone(selectedItem.phone)}
                      </Typography>
                    </div>

                    <div className="mr-3">
                      <Typography
                        component="span"
                        style={{
                          fontsize: 16,
                          fontWeight: "bold",
                          marginTop: 0,
                        }}
                      >
                        Created At :
                      </Typography>
                      <Typography component="span">
                        {selectedItem &&
                          " " + getActualTime(selectedItem.created_at)}
                      </Typography>
                    </div>
                    <div className="mr-3">
                      <Typography
                        component="span"
                        style={{
                          fontsize: 16,
                          fontWeight: "bold",
                          marginTop: 0,
                        }}
                      >
                        Due Date :
                      </Typography>
                      <Typography component="span">
                        {selectedItem &&
                          " " + getActualTime(selectedItem.sale_target_date)}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <div className='col-12'>
                  <Typography
                    component='span'
                    style={{ fontsize: 16, fontWeight: 'bold', marginTop: 0 }}
                  >
                    Facebook id : &nbsp;
                  </Typography>
                  {selectedItem && (
                    <Typography component='a'>
                      {selectedItem.facebook_id && (
                        <a
                          target='_blank'
                          href={'https://www.facebook.com/' + selectedItem.facebook_id}
                        >
                          {'https://www.facebook.com/' + selectedItem.facebook_id}
                        </a>
                      )}
                    </Typography>
                  )}
                </div> */}
              </div>

              {/* {questionAns !== '' && (
                <div>
                  <Typography style={{ fontsize: 16, fontWeight: 'bold', marginTop: 0 }}>
                    Question & Answers:
                  </Typography>
                  <div dangerouslySetInnerHTML={getQuestionHtml(questionAns)}></div>
                </div>
              )} */}
              <Typography style={{ fontsize: 16, fontWeight: "bold" }}>
                Description :
              </Typography>
              <div className="task-description-editor-parent">
                <CkEditor
                  // mode={selectedItem?.is_sale_assigned ? false : true}
                  content={saleDescription}
                  setContent={(value) => setSaleDescription(value)}
                />
              </div>
              <div className="mt-3">
                <TextField
                  // multiline
                  // maxRows={4}
                  // disabled={selectedItem?.is_sale_assigned ? false : true}
                  fullWidth
                  variant="outlined"
                  value={saleTitle}
                  label="Sale Title"
                  onChange={(e) => setSaleTitle(e.target.value)}
                />
              </div>
              <div>
                {/* <Button
                  fullWidth
                  size='medium'
                  type='submit'
                  color='primary'
                  variant='outlined'
                  onClick={handleSubmit}
                  startIcon={<Icon icon={editFill} />}
                  className='mt-2'
                >
                  Update
                </Button> */}
                <LoadingButton
                  fullWidth
                  size="medium"
                  type="submit"
                  color="primary"
                  variant="outlined"
                  startIcon={<Icon icon={editFill} />}
                  className="mt-2"
                  loading={isUpdating}
                  // disabled={selectedItem?.is_sale_assigned ? false : true}
                >
                  Update
                </LoadingButton>
              </div>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
