import {
  Avatar,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  InputAdornment,
  TextField,
  OutlinedInput,
  Button,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import Page from 'src/components/Page';
import { baseUri } from 'src/config/config';
import ChatTabs from './components/ChatTabs';
import GroupList from './components/GroupList';
import TeamList from './components/TeamList';
import AddIcon from '@mui/icons-material/Add';
// import AddGroupDialog from './components/AddGroupDialog';
import ScrollToBottom from 'react-scroll-to-bottom';
import {
  createGroup,
  chatGroupList,
  chatGroupDetail,
  deleteChatGroup,
  editChatGroup,
  sendGroupChatMessage,
  detailWithMessages,
  initialChatMessage,
  detailDirectMessages,
  sendDirectMessage,
  readGroupMessages,
  readDirectMessages,
  deleteMessage,
  setMessageReaction,
} from 'src/DAL/chat/chat';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Scrollbar from 'src/components/Scrollbar';
import ChatHeader from './components/ChatHeader';
import CkEditor from 'src/components/editor/CKEditor';
import SendIcon from '@mui/icons-material/Send';
import ChatMessageItem from './components/ChatMessageItem';
import StyledChatSearch from './components/StyledChatSearch';
import { make_clickable_url_by_className, set_blank_target } from 'src/utils/DomUtils';
import { filter } from 'lodash';
import { useSocket } from 'src/Hooks/socketContext';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import moment from 'moment';
import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom';
// import NewMessageDialog from './components/NewMessageDialog';
import { RestoreFromTrashOutlined } from '@mui/icons-material';
import NewMessageSidebar from './components/NewMessageSidebar';
import MessageMenu from './components/MessageMenu';
import ChatDetailSidebar from './components/ChatDetailSidebar';
import ChatListSidebar from './components/ChatListSidebar';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
const MessageStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  border: '1px solid #c5c5c5',
  '& fieldset': {
    borderWidth: `1px !important`,
    display: 'none',
  },
  paddingRight: 0,
}));
const TeamChat = () => {
  const params = useParams();
  const {
    sendChatNotification,
    socketChatInfo,
    socketGroupInfo,
    getUserProfileData,
    _setUnreadMessageCount,
  } = useSocket();
  const profile = getUserProfileData();

  const { pathname, state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [groupTab, setGroupTab] = useState(true);
  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const [isInitializingChat, setIsInitializingChat] = useState(false);
  const [isLoadingGroupList, setIsLoadingGroupList] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  const [currentGroupDetail, setCurrentGroupDetail] = useState(null);
  const [message, setMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [chatListData, setChatListData] = useState([]);
  const selectedListItem = useRef(undefined);
  const [isDelDialogOpen, setIsDelDialogOpen] = useState(false);
  const [messageMenuAnchorEl, setMessageMenuAnchorEl] = useState(null);
  const [targetDelItem, seTargetDelItem] = useState(undefined);
  const [isDeletingMessage, setIsDeletingMessage] = useState(false);
  const [isChatDetailSidebarOpen, setIsChatDetailSidebarOpen] = useState(false);
  const [isSideChatListOpen, setIsSideChatListOpen] = useState(false);
  const [loadMoreChat, setLoadMoreChat] = useState(false);
  const [isLoadingMoreChat, setIsLoadingMoreChat] = useState(false);
  const initial = useRef(true);
  const [chatFile, setChatFile] = useState(null);
  const [chatImage, setChatImage] = useState(null);
  const [paginationDetail, setPaginationDetail] = useState(null);
  const initial_group = useRef(true);
  const initial_scroll = useRef(true);
  const scrollRef = useRef();
  const scrollRefSm = useRef();
  let shift_pressed = false;
  //

  const handleAreaChange = (e) => {
    const { value } = e.target;
    console.log(e.nativeEvent);
    console.log(value, 'val');
    console.log(shift_pressed, 'shift');
    if (shift_pressed) {
      setMessage(value);
      console.log('line break');
      return;
    }
    if (!shift_pressed) {
      if (e.nativeEvent.inputType === 'insertLineBreak') {
        handleSendMessage(e);
        console.log('send message');
        return;
      }
    }
    setMessage(value);
  };
  const handleAreaKeyUp = (e) => {
    const { key } = e;
    // shift_pressed = false;
    if (key == 'Shift') {
      shift_pressed = false;
    }
  };
  const handleAreaKeyDown = (e) => {
    // console.log(e.keyCode);
    if (e.shiftKey) {
      shift_pressed = true;
    }
  };

  //

  const toggleSideChatList = () => {
    setIsSideChatListOpen((prev) => !prev);
  };
  const handleOpenMessageMenu = (e, item) => {
    setMessageMenuAnchorEl(e.currentTarget);
    seTargetDelItem(item);
    console.log(item, 'at-del');
  };
  const toggleDelDialog = () => {
    setIsDelDialogOpen(!isDelDialogOpen);
  };
  const applyReaction = async (data, item) => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('message_id', item.id);
    formData.append('icon', data.icon);
    console.log(currentGroupDetail, 'messages');
    const getUpdatedReactions = (reactions) => {
      let updated_reactions = Array.from(reactions);
      let current_user = localStorage.getItem('user_id');
      let reaction_exist = false;
      let reaction_exist_index = undefined;
      reactions.map((reaction, i) => {
        reaction.user.map((user) => {
          if (String(user.user_id) === String(current_user)) {
            reaction_exist = true;
            reaction_exist_index = i;
          }
        });
      });
      console.log(reaction_exist);
      if (reaction_exist) {
        updated_reactions = updated_reactions.map((item, index) => {
          if (index === reaction_exist_index) {
            return { ...item, icon: data.icon };
          }
          return item;
        });
        return updated_reactions;
      }
      if (!reaction_exist) {
        updated_reactions.push({
          icon: data.icon,
          user: [
            {
              first_name: profile?.first_name,
              last_name: profile?.last_name,
              user_id: current_user,
              image: profile?.image,
            },
          ],
        });
        return updated_reactions;
      }
    };
    const getUpdatedMessages = (message_id) => {
      let messages = currentGroupDetail.messages;
      const updated = messages.map((item) => {
        if (String(item.id) === String(message_id)) {
          return { ...item, reactions: getUpdatedReactions(item.reactions) };
        }
        return item;
      });
      return updated;
    };
    setCurrentGroupDetail({ ...currentGroupDetail, messages: getUpdatedMessages(item.id) });
    const result = await setMessageReaction(formData);
    console.log(result, 'reaction-res');
    if (result.code === 200) {
      if (String(item.chat_id) === '0') {
        console.log('group-chat');
        // chat_id = item.group_id;
        currentGroupDetail.detail.team.forEach((item) => {
          const payload = {};
          const chat_info = { ...currentGroupDetail.detail };
          payload.chat_info = chat_info;
          payload.receiver_id = item.user_id;
          payload.notification_type = 'chat';
          payload.notification_action = 'chat_reaction';
          console.log(payload, 'paylo-members');
          sendChatNotification(payload);
        });
        //for creator
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.chat_info = chat_info;
        payload.receiver_id = currentGroupDetail.detail.group_creator_id;
        payload.notification_type = 'chat';
        payload.notification_action = 'chat_reaction';
        sendChatNotification(payload);
      }
      if (String(item.group_id) === '0') {
        console.log('direct-chat');
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.chat_info = chat_info;
        payload.notification_type = 'chat';
        payload.notification_action = 'chat_reaction';
        let ids = [currentGroupDetail.detail.reciever_id, currentGroupDetail.detail.sender_id];
        console.log(ids, 'ids');
        let [receiver] = ids.filter(
          (item) => String(item) !== String(localStorage.getItem('user_id'))
        );
        payload.receiver_id = receiver;
        sendChatNotification(payload);
      }
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleOnDelMessage = async () => {
    if (!targetDelItem) {
      return;
    }
    console.log(targetDelItem, 'on-del-msg');
    const tgt = Array.from(currentGroupDetail.messages);
    let updated = tgt.filter((msg) => String(msg.id) !== String(targetDelItem.id));
    console.log(updated, 'updt');
    console.log(targetDelItem.chat_id, 'is-chat_id');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('message_id', targetDelItem.id);
    console.log(...data, 'del-msg-req');
    console.log(currentGroupDetail.detail, 'current-detail');

    setIsDeletingMessage(true);
    const result = await deleteMessage(data);
    if (result.code === 200) {
      setIsDeletingMessage(false);
      setCurrentGroupDetail({
        ...currentGroupDetail,
        messages: updated,
      });
      setMessageMenuAnchorEl(null);
      toggleDelDialog();
      enqueueSnackbar('Message deleted', { variant: 'success' });
      //
      if (String(targetDelItem.type) === 'individual') {
        console.log(targetDelItem.type, 'tape');
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.chat_info = chat_info;
        payload.notification_type = 'chat';
        payload.notification_action = 'delete_message';
        let ids = [currentGroupDetail.detail.reciever_id, currentGroupDetail.detail.sender_id];
        console.log(ids, 'ids');
        let [receiver] = ids.filter(
          (item) => String(item) !== String(localStorage.getItem('user_id'))
        );
        payload.receiver_id = receiver;
        console.log(payload, 'payload-del');
        sendChatNotification(payload);
      }
      if (String(targetDelItem.type) === 'group') {
        console.log(targetDelItem.type, 'tape');
        currentGroupDetail.detail.team.forEach((item) => {
          const payload = {};
          const chat_info = { ...currentGroupDetail.detail };
          payload.chat_info = chat_info;
          payload.receiver_id = item.user_id;
          payload.notification_type = 'chat';
          payload.notification_action = 'delete_message';
          console.log(payload, 'paylo-members');
          // sendChatNotification(payload);
        });
        //for creator
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.chat_info = chat_info;
        payload.receiver_id = currentGroupDetail.detail.group_creator_id;
        payload.notification_type = 'chat';
        payload.notification_action = 'delete_message';
        console.log(payload, 'paylo');
        console.log(payload, 'paylo-creator');
        sendChatNotification(payload);
        getChatGroupList();
      }
    } else {
      setIsDeletingMessage(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleLocalChatSearch = (input, array) => {
    let filtered = filter(
      array,
      (item) => item.name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  document.onvisibilitychange = () => {
    let visibility = document.visibilityState;
    if (visibility === 'visible') {
      if (currentGroupDetail) {
        const [target_chat] = chatListData.filter(
          (item) => String(item.id) === String(currentGroupDetail.detail.id)
        );
        console.log(target_chat, 'target_chat');
        if (Number(target_chat.unread_message_count) !== 0) {
          setTimeout(() => {
            markAsRead(currentGroupDetail.detail);
          }, 1000);
        }
      }
    }
  };
  const onUserClick = (e, item) => {
    setAddGroupOpen(false);
    setIsSideChatListOpen(false);
    console.log(item);
    console.log(chatListData[0], 'list-item-original');
    const sender_id = localStorage.getItem('user_id');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('message', '');
    data.append('member_id', item.user_id);
    let result = handleInitializeMessage(data);
    if (result) {
      toggleAddGroup();
    }
  };
  const markAsRead = async (item) => {
    console.log(item, 'itom');
    console.log(currentGroupDetail?.detail, 'cur-detail');
    if (!isTabActive()) {
      return;
    }
    if (!item) {
      return;
    }
    console.log('mark-read-hit');
    const chat_id = item.id;
    console.log(item, 'read-item');
    setChatListData((data) =>
      data.map((item) => {
        if (String(item.id) === String(chat_id)) {
          return { ...item, unread_message_count: '0' };
        } else {
          return item;
        }
      })
    );
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    if (String(item.chat_type) === '0') {
      data.append('group_id', chat_id);
      console.log(...data, 'read-msg-req');
      const result = await readGroupMessages(data);
      console.log(result, 'read-message result');
      if (result.code === 200) {
        //
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
    if (String(item.chat_type) === '1') {
      data.append('chat_id', chat_id);
      console.log(...data, 'read-msg-req');
      const result = await readDirectMessages(data);
      console.log(result, 'read-message result');
      if (result.code === 200) {
        //
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
  };
  const getFilteredChatList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === '') {
      return data;
    }
    if (searchValue !== '') {
      let filtered = handleLocalChatSearch(searchValue, data);
      return filtered;
    }
  };
  const canSendMessage = () => {
    if (!currentGroupDetail) {
      console.log('return at currentGroupDetail ');
      return false;
    }
    let is_after_start = false;
    let is_before_end = false;
    let end_time = moment(currentGroupDetail.detail.chat_end_time).format('hh:mm a');
    let start_time = moment(currentGroupDetail.detail.chat_start_time).format('hh:mm a');
    let current_time = moment().format('hh:mm a');
    console.log(current_time, 'current_time');
    var current = moment(`${current_time}`, 'hh:mm a');
    var StartTime = moment(`${start_time}`, 'hh:mm a');
    var endTime = moment(`${end_time}`, 'hh:mm a');
    console.log(current, 'current_time');
    console.log(start_time, 'start_time');
    console.log(end_time, 'end_time');
    console.log(current.isAfter(StartTime), current_time, 'is after', start_time); // true
    console.log(current.isBefore(endTime), current_time, 'is before', end_time); // true
    //
    if (current.isAfter(StartTime) === true) {
      is_after_start = true;
    }
    if (current.isBefore(endTime) === true) {
      is_before_end = true;
    }
    if (is_after_start === false || is_before_end === false) {
      console.log('you CANNOT send message');
      return false;
    }
    if (is_after_start === true && is_before_end === true) {
      console.log('you can send message');
      return true;
    }
  };
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!currentGroupDetail) {
      return;
    }
    if (!currentGroupDetail) {
      return;
    }

    if (!chatFile) {
      if (!message || message.trim() === '') {
        enqueueSnackbar('Message cannot be empty', { variant: 'error' });
        return;
      }
    }
    console.log(selectedListItem.current, 'current-list-item');
    console.log(currentGroupDetail.detail, 'current-chat-detail');

    if (String(selectedListItem.current.chat_type) === '1') {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      data.append('message', message.trim());
      data.append('chat_id', currentGroupDetail.detail.id);
      if (chatFile) {
        data.append('file', chatFile);
      }
      console.log(...data, 'send-direct-msg-req');
      // return;
      setIsSendingMessage(true);
      const result = await sendDirectMessage(data);
      console.log(result, 'send-message-res');
      if (result.code === 200) {
        setChatFile(null);
        setChatImage(null);
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.notification_type = 'chat';
        payload.notification_action = 'add_message';
        payload.chat_info = chat_info;
        payload.message = message.trim();
        let ids = [currentGroupDetail.detail.reciever_id, currentGroupDetail.detail.sender_id];
        console.log(ids, 'ids');
        let [receiver] = ids.filter(
          (item) => String(item) !== String(localStorage.getItem('user_id'))
        );
        payload.receiver_id = receiver;
        console.log(payload, 'paylo');
        sendChatNotification(payload);
        setIsSendingMessage(false);
        setMessage('');
        // let show_loader = false;
        // getDetailDirectMessages(currentGroupDetail.detail, show_loader);
        getChatGroupList();
      } else {
        setIsSendingMessage(false);
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
    if (String(selectedListItem.current.chat_type) === '0') {
      const res = canSendMessage();
      if (res === false) {
        enqueueSnackbar('You can send message only in group time limit', { variant: 'error' });
        return;
      }
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      data.append('message', message.trim());
      data.append('group_creator_id', currentGroupDetail.detail.group_creator_id);
      data.append('group_id', currentGroupDetail.detail.id);
      console.log(...data, 'send-msg-req');
      if (chatFile) {
        data.append('file', chatFile);
      }
      // return;
      setIsSendingMessage(true);
      const result = await sendGroupChatMessage(data);
      console.log(result, 'send-message-res');
      if (result.code === 200) {
        setChatFile(null);
        setChatImage(null);
        currentGroupDetail.detail.team.forEach((item) => {
          const payload = {};
          const chat_info = { ...currentGroupDetail.detail };
          payload.chat_info = chat_info;
          payload.receiver_id = item.user_id;
          payload.notification_type = 'chat';
          payload.notification_action = 'add_message';
          payload.message = message.trim();
          console.log(payload, 'paylo');
          sendChatNotification(payload);
        });
        //for creator
        const payload = {};
        const chat_info = { ...currentGroupDetail.detail };
        payload.chat_info = chat_info;
        payload.receiver_id = currentGroupDetail.detail.group_creator_id;
        payload.notification_type = 'chat';
        payload.notification_action = 'add_message';
        payload.message = message.trim();
        console.log(payload, 'paylo');
        sendChatNotification(payload);
        setIsSendingMessage(false);
        setMessage('');
        getChatGroupList();
        // let show_loader = false;
        // getGroupDetailWithMessages(currentGroupDetail.detail.id, show_loader);
      } else {
        setIsSendingMessage(false);
        enqueueSnackbar(result.message, { variant: 'error' });
      }
    }
  };
  const setSelectedGroup = (data) => {
    setCurrentGroupDetail(data);
  };

  const toggleTabs = (value) => {
    setGroupTab(value);
  };
  const toggleAddGroup = () => {
    setAddGroupOpen(!addGroupOpen);
  };
  const toggleChatDetailSidebar = () => {
    setIsChatDetailSidebarOpen(!isChatDetailSidebarOpen);
  };
  const handleInitializeMessage = async (form_data) => {
    console.log(...form_data, 'add-chat-req');
    setIsInitializingChat(true);
    const result = await initialChatMessage(form_data);
    if (result.code === 200) {
      let chat_id = result.chat_id;
      setIsInitializingChat(false);
      toggleAddGroup();
      getChatGroupList(chat_id);
      return true;
    } else {
      setIsInitializingChat(false);
      enqueueSnackbar(result.message, { variant: 'error' });
      return false;
    }
  };
  const setFirstSelectedChat = (data) => {
    console.log(data, 'data--');
    if (data.length > 0) {
      const first_item = data[0];
      selectedListItem.current = first_item;
      getDetailByType(first_item);
    }
  };
  const setTargetSelectedChat = (data) => {
    console.log(data, 'target-data');
    if (!data) {
      return;
    }
    selectedListItem.current = data;
    getDetailByType(data);
  };
  const setActiveItem = (item) => {
    console.log(item, 'set-active');
    let data = Array.from(chatListData);
    let updated = data.map((list) => {
      if (String(list.id) === String(item.id)) {
        return { ...list, isActive: true };
      }
      return { ...list, isActive: false };
    });
    // console.log(updated);
    setChatListData(updated);
  };
  const setActiveLocalItem = (item) => {
    let data = Array.from(chatListData);
    let updated = data.map((list) => {
      if (String(list.id) === String(item.id)) {
        return { ...list, isActive: true };
      }
      return { ...list, isActive: false };
    });
    // console.log(updated);
    setChatListData(updated);
  };
  const isChatActive = (item) => {
    console.log(item, 'itm');
    if (!currentGroupDetail) {
      return false;
    }
    if (String(currentGroupDetail.detail.id) === String(item.id)) {
      return true;
    }
    if (String(currentGroupDetail.detail.id) === String(item.id)) {
      return true;
    }

    return false;
  };
  const isTabActive = () => {
    if (String(document.visibilityState) === 'visible') {
      return true;
    }
    return false;
  };
  const handleRefreshChat = async () => {
    console.log(socketChatInfo, 'socket-info-at-refresh');
    console.log(currentGroupDetail, 'cur-detail-at-refresh');
    if (!currentGroupDetail) {
      return;
    }
    if (!socketChatInfo) {
      return;
    }
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    const result = await chatGroupList(data);
    console.log(result, 'chat-group-list');

    if (result.code === 200) {
      const isActive = (id, item) => {
        if (String(id) === String(currentGroupDetail.detail.id)) {
          return true;
        }
        return false;
      };
      const getCount = (item) => {
        if (String(item.id) === String(currentGroupDetail.detail.id)) {
          let make_zero = isTabActive();
          if (make_zero) {
            return 0;
          } else {
            return item.unread_message_count;
          }
          //
        }
        return item.unread_message_count;
      };
      let modified_chat_list = result.chat_list.map((item) => {
        return {
          ...item,
          isActive: isActive(item.id, item),
          unread_message_count: getCount(item),
        };
      });
      setChatListData(modified_chat_list);
      if (String(currentGroupDetail.detail.id) === String(socketChatInfo.chat_info.id)) {
        console.log(currentGroupDetail, 'found-it');
        //fetch detail for this chat_id
        if (String(socketChatInfo.chat_info.chat_type) === '0') {
          let no_scroll = true;
          console.log(socketChatInfo.chat_info.chat_type, 'is-type');
          getGroupDetailWithMessages(socketChatInfo.chat_info.id, false, no_scroll);
        }
        if (String(socketChatInfo.chat_info.chat_type) === '1') {
          let no_scroll = true;
          console.log(socketChatInfo.chat_info.chat_type, 'is-type');
          getDetailDirectMessages(socketChatInfo.chat_info, false, no_scroll);
        }
      }
    } else {
    }
  };
  const getChatGroupList = async (chat_id, is_socket_event) => {
    // chat_id is req only when this fn called by initializeChat
    let is_target_chat = true;
    if (String(chat_id) === '') {
      is_target_chat = false;
    }
    if (String(chat_id) === null) {
      is_target_chat = false;
    }
    if (!chat_id) {
      is_target_chat = false;
    }
    const { id } = params;
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    if (!is_socket_event) {
      setIsLoadingGroupList(true);
    }
    const result = await chatGroupList(data);
    console.log(result, 'chat-group-list');
    if (result.code === 200) {
      const getActiveStatus = (data, item) => {
        //if chat_id ? make that one active
        if (is_target_chat === true) {
          if (data.length === 0) {
            return false;
          }

          if (String(item.id) === String(chat_id)) {
            return true;
          } else return false;
        }
        if (is_target_chat === false) {
          if (data.length === 0) {
            return false;
          }
          const first = data[0];
          if (String(item.id) === String(first.id)) {
            return true;
          } else return false;
        }
      };
      setIsLoadingGroupList(false);
      let target_chat_data = null;
      if (is_target_chat === true) {
        result.chat_list.map((item) => {
          if (String(item.id) === String(chat_id)) {
            target_chat_data = item;
          }
        });
      }
      let modified_chat_list = result.chat_list.map((item) => {
        return {
          ...item,
          isActive: getActiveStatus(result.chat_list, item),
        };
      });
      setChatListData(modified_chat_list);
      // setChatListData(result.chat_list);
      if (result.chat_list.length > 0) {
        if (is_target_chat === true) {
          setTargetSelectedChat(target_chat_data);
        }
        if (is_target_chat === false) {
          setFirstSelectedChat(result.chat_list);
        }
      }
      if (result.chat_list.length === 0) {
        setCurrentGroupDetail(null);
        setIsLoadingDetail(false);
        // return;
      }
    } else {
      setIsLoadingGroupList(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const getDetailByType = (item, show_loader) => {
    // console.log(item);
    setIsSideChatListOpen(false);
    selectedListItem.current = item;
    if (String(item.chat_type) === '0') {
      setPaginationDetail(null);
      setLoadMoreChat(false);
      getGroupDetailWithMessages(item.id, show_loader, item);
    }
    if (String(item.chat_type) === '1') {
      setPaginationDetail(null);
      setLoadMoreChat(false);
      getDetailDirectMessages(item, show_loader);
    }
  };
  const getGroupDetailWithMessages = async (id, show_loader, item, no_scroll) => {
    console.log(no_scroll, 'no_scroll');
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    data.append('group_id', id);
    console.log(...data, 'group-detail-req');
    if (show_loader) {
      setIsLoadingDetail(true);
    }
    const result = await detailWithMessages(data);

    console.log(result, 'group-detail');
    if (result.code === 200) {
      if (item) {
        if (Number(item.unread_message_count) !== 0) {
          markAsRead(item);
        }
      }
      setIsLoadingDetail(false);
      setCurrentGroupDetail({
        detail: result.group_data,
        messages: result.group_message_list,
        pagination: { next_url: result.next_url, total_count: result.total_count },
      });
      setPaginationDetail({
        next_url: result.next_url,
        total_count: result.total_count,
      });
      setTimeout(() => {
        handleBlankTarget('message-items-container');
        make_clickable_url_by_className('clickable-link');
      }, 500);

      if (no_scroll === true) {
        return;
      }
      console.log('called after');
      setTimeout(() => {
        toBottom();
      }, 100);
    } else {
      setIsLoadingDetail(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getDetailDirectMessages = async (item, show_loader, no_scroll) => {
    console.log(no_scroll, 'no_scroll');
    const data = new FormData();
    console.log(item, 'at-detail-direct');
    // return;
    data.append('token', localStorage.getItem('token'));
    data.append('reciever_id', item.sender_id);
    data.append('sender_id', item.reciever_id);
    if (show_loader) {
      setIsLoadingDetail(true);
    }
    // return;
    console.log(...data, 'direct-message-detail-req');
    const result = await detailDirectMessages(data);

    console.log(result, 'direct-messages-detail');
    if (result.code === 200) {
      if (item) {
        if (Number(item.unread_message_count) !== 0) {
          markAsRead(item);
        }
      }
      setIsLoadingDetail(false);
      setCurrentGroupDetail({
        detail: result.message_data,
        messages: result.messages_list,
        pagination: { next_url: result.next_url, total_count: result.total_count },
      });
      setPaginationDetail({
        next_url: result.next_url,
        total_count: result.total_count,
      });
      setTimeout(() => {
        handleBlankTarget('message-items-container');
        make_clickable_url_by_className('clickable-link');
      }, 500);
      if (no_scroll === true) {
        return;
      }
      console.log('called after');
      setTimeout(() => {
        toBottom();
      }, 100);
    } else {
      setIsLoadingDetail(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const getMessages = () => {
    const isCreator = (item) => {
      if (!item) {
        return false;
      }
      if (String(item.user_id) === String(localStorage.getItem('user_id'))) {
        return true;
      }
      return false;
    };
    if (!currentGroupDetail) {
      return [];
    }
    let messages = currentGroupDetail.messages.map((item) => {
      return { ...item, isCreator: isCreator(item) };
    });
    return messages;
  };
  const isLast = (i) => {
    const messages_length = Number(getMessages().length);
    if (Number(i + 1) === messages_length) {
      return true;
    }
    return false;
  };
  const isMsgLarge = (text) => {
    if (!text) {
      return false;
    }
    let length = text.length;
    if (length >= 300) {
      return true;
    }
    return false;
  };
  const handleBlankTarget = (id) => {
    set_blank_target(id);
  };
  const removeImage = () => {
    setChatFile(null);
    setChatImage(null);
  };
  const handleKeyDown = (e) => {
    if (String(e.key) === 'Enter') {
      console.log('enterer');
      handleSendMessage(e);
    }
  };
  const handleFileChange = (e) => {
    if (!e.target) {
      return;
    }
    if (!e.target.files) {
      return;
    }
    if (!e.target.files[0]) {
      return;
    }
    /* image/png,image/jpeg,image/jpg */
    console.log(e.target.files[0], 'files-');
    let can_upload = false;
    let file_type = '0';
    const { type, size } = e.target.files[0];
    console.log(size, 'file-size');
    if (size > 10000000) {
      // 10000000 bytes is 10mb
      enqueueSnackbar('Image size cannot be above 10mb', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    let allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
    allowed_types.map((item) => {
      if (item === String(type)) {
        can_upload = true;
      }
    });
    console.log(can_upload);
    if (!can_upload) {
      enqueueSnackbar('Please choose an image', { variant: 'error' });
      return;
    }
    if (can_upload) {
      setChatFile(e.target.files[0]);
      setChatImage(URL.createObjectURL(e.target.files[0]));
    }
  };
  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };
  ///
  const toTop = () => {
    const elements = document.getElementsByClassName('scroller');
    for (let element of elements) {
      if (element) {
        element.scrollTop = 0;
      }
    }
  };
  const toBottom = () => {
    const elements = document.getElementsByClassName('scroller');
    for (let element of elements) {
      if (element) {
        element.scrollTop = 9999999999;
      }
    }
  };
  const handleScroll = (e) => {
    if (initial_scroll.current === false) {
      showLoadMore(e.target.scrollTop);
    }
    initial_scroll.current = false;
  };
  const showLoadMore = (position) => {
    if (position === 0) {
      setLoadMoreChat(true);
      let scrollers = document.getElementsByClassName('scroller');
      console.log(position, 'top');
      return;
    }
    setLoadMoreChat(false);
  };

  const handleLoadMoreChat = async () => {
    console.log('load-more hit');
    console.log(paginationDetail);
    console.log(currentGroupDetail);
    if (paginationDetail.next_url === '') {
      return;
    }
    let url = baseUri + paginationDetail.next_url;
    if (String(currentGroupDetail.detail.chat_type) === '0') {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      data.append('group_id', currentGroupDetail.detail.id);
      console.log(...data, 'next-page-req');
      setIsLoadingMoreChat(true);
      const result = await axios.post(url, data);
      console.log(result.data, 'next-res');
      if (result.data.code === 200) {
        let merged_messages = [...result.data.group_message_list, ...currentGroupDetail.messages];
        setCurrentGroupDetail({
          detail: result.data.group_data,
          messages: merged_messages,
          pagination: { next_url: result.data.next_url, total_count: result.data.total_count },
        });
        setPaginationDetail({
          next_url: result.data.next_url,
          total_count: result.data.total_count,
        });
        setIsLoadingMoreChat(false);
        setTimeout(() => {
          make_clickable_url_by_className('clickable-link');
        }, 400);
      } else {
        setIsLoadingMoreChat(false);
        enqueueSnackbar(result.data.message, { variant: 'error' });
      }
    }
    if (String(currentGroupDetail.detail.chat_type) === '1') {
      const data = new FormData();
      data.append('token', localStorage.getItem('token'));
      data.append('sender_id', currentGroupDetail.detail.sender_id);
      data.append('reciever_id', currentGroupDetail.detail.reciever_id);
      console.log(...data, 'next-page-req');
      setIsLoadingMoreChat(true);
      const result = await axios.post(url, data);
      console.log(result.data, 'next-res');
      // for (let i = 0; i < scrollers.length; i++) {
      //   let item = scrollers.item(i);
      //   item.scrollTop = 30;
      // }
      if (result.data.code === 200) {
        let merged_messages = [...result.data.messages_list, ...currentGroupDetail.messages];
        setCurrentGroupDetail({
          detail: result.data.message_data,
          messages: merged_messages,
          pagination: { next_url: result.data.next_url, total_count: result.data.total_count },
        });
        setPaginationDetail({
          next_url: result.data.next_url,
          total_count: result.data.total_count,
        });
        setIsLoadingMoreChat(false);
        setTimeout(() => {
          make_clickable_url_by_className('clickable-link');
        }, 400);
      } else {
        setIsLoadingMoreChat(false);
        enqueueSnackbar(result.data.message, { variant: 'error' });
      }
    }
  };

  const loadMoreVisible = (position) => {
    if (isLoadingDetail) {
      return false;
    }
    if (!loadMoreChat) {
      return false;
    }
    if (paginationDetail?.next_url === '') {
      return false;
    }
    return true;
  };

  ///
  React.useEffect(() => {
    getChatGroupList();
  }, []);
  React.useEffect(() => {
    if (initial.current === false) {
      if (socketChatInfo) {
        console.log(socketChatInfo.chat_info, 'trigger event hit');
        handleRefreshChat();
      }
    }
    initial.current = false;
  }, [socketChatInfo]);
  React.useEffect(() => {
    if (initial_group.current === false) {
      if (socketGroupInfo) {
        console.log(socketGroupInfo, ' socketGroupInfo event hit');
        getChatGroupList();
      }
    }
    initial_group.current = false;
  }, [socketGroupInfo]);
  React.useEffect(() => {
    let total_unread = 0;
    console.log(chatListData, 'list---');
    chatListData.map((item) => {
      total_unread = total_unread + Number(item.unread_message_count);
    });
    console.log(total_unread, 'unread--');
    if (total_unread > 0) {
      document.title = `(${total_unread}) ${
        total_unread > 1 ? 'unread messages' : 'unread message'
      }`;
    }
    if (total_unread === 0) {
      _setUnreadMessageCount(0);
      document.title = 'Team Chat';
    }
  }, [chatListData]);
  React.useEffect(() => {
    if (loadMoreChat === true) {
      handleLoadMoreChat();
    }
  }, [loadMoreChat]);
  let show_chat = profile.allow_chat;
  if (String(show_chat) !== '1') {
    return <Navigate to='/dashboard' replace />;
  }
  return (
    <Page title='Team Chat' style={{ padding: 0 }}>
      <Container maxWidth='xl'>
        <div className='row chat-container common-border'>
          <div className='col-3 chat-users common-border'>
            <div className='chat-tabs'>
              <div className='d-flex w-100 position-relative' style={{ bottom: '4px' }}>
                <span className='w-100'>
                  <StyledChatSearch
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    placeHolder='Search...'
                  />
                </span>
              </div>
              {/* <ChatTabs groupTabActive={groupTab} toggleTabs={toggleTabs} /> */}
            </div>
            {/* <Divider className='mt-1' /> */}
            <Scrollbar>
              <GroupList
                isLoadingGroups={isLoadingGroupList}
                isLoadingDetail={isLoadingDetail}
                onGetDetail={getDetailByType}
                currentItem={currentGroupDetail}
                isChatActive={isChatActive}
                setActiveItem={setActiveItem}
                shouldNavigate={true}
                chatListData={getFilteredChatList(chatListData)}
              />
            </Scrollbar>
            {!isLoadingGroupList && (
              <>
                {chatListData.length === 0 && (
                  <div className='center-progress'>
                    <Typography textAlign='center' fontWeight='bold'>
                      No messages yet.
                    </Typography>
                  </div>
                )}
              </>
            )}
            {isLoadingGroupList && (
              <div className='center-progress'>
                <CircularProgress />
              </div>
            )}

            <Tooltip title='New Message' arrow>
              <IconButton onClick={toggleAddGroup} className='add-chat-btn' aria-label='add'>
                <AddIcon htmlColor='#fff' />
              </IconButton>
            </Tooltip>
          </div>
          <div className='col-9 chat-messages common-border'>
            <div className='chat-tabs'>
              <ChatHeader
                currentGroup={currentGroupDetail}
                getChatGroupList={getChatGroupList}
                getGroupDetailWithMessages={getGroupDetailWithMessages}
                setSelectedGroup={setSelectedGroup}
                toggleDetailSidebar={toggleChatDetailSidebar}
              />
            </div>
            <div className='chat-messages-parent'>
              <div className='messages-container' id='message-items-container'>
                {!isLoadingDetail && (
                  <>
                    <div className={`load-more-chat ${loadMoreVisible() ? 'd-flex' : 'd-none'}`}>
                      {isLoadingMoreChat && (
                        <CircularProgress style={{ height: '18px', width: '18px' }} />
                      )}
                    </div>
                    <div ref={scrollRef} onScroll={handleScroll} className='scroller'>
                      {getMessages().map((item, i) => (
                        <ChatMessageItem
                          applyReaction={applyReaction}
                          onEdit={(e, item) => {
                            handleOpenMessageMenu(e, item);
                          }}
                          key={i}
                          item={item}
                          isLastItem={isLast(i)}
                        />
                      ))}
                    </div>
                  </>
                  // <ScrollToBottom
                  //   initialScrollBehavior='auto'
                  //   behavior='auto'
                  //   followButtonClassName='hidden-element'
                  //   mode='bottom'
                  //   className='messages-container'
                  // >
                  //   {getMessages().map((item, i) => (
                  //     <ChatMessageItem
                  //       onEdit={(e, item) => {
                  //         handleOpenMessageMenu(e, item);
                  //       }}
                  //       key={i}
                  //       item={item}
                  //     />
                  //   ))}
                  // </ScrollToBottom>
                )}

                {isLoadingDetail && (
                  <div className='center-progress'>
                    <CircularProgress />
                  </div>
                )}
                {!isLoadingDetail && (
                  <>
                    {getMessages().length < 1 && (
                      <div className='center-progress'>
                        <Typography textAlign='center' fontWeight='bold'>
                          No messages yet.
                        </Typography>
                      </div>
                    )}
                  </>
                )}
                <MessageMenu
                  anchorEl={messageMenuAnchorEl}
                  setAnchorEl={setMessageMenuAnchorEl}
                  handleOnEdit={() => null}
                  handleOnDel={handleOnDelMessage}
                  toggle={() => null}
                  isDelDialogOpen={isDelDialogOpen}
                  toggleDelDialog={toggleDelDialog}
                  isDeletingMessage={isDeletingMessage}
                />
              </div>
              {chatListData.length > 0 && (
                <div className='chat-bottom-bar'>
                  <div className='message-editor'>
                    <div className='row align-items-center'>
                      <div className='col-11 pr-0'>
                        <div className='text-area-parent'>
                          <textarea
                            value={message}
                            onChange={handleAreaChange}
                            onKeyDown={handleAreaKeyDown}
                            onKeyUp={handleAreaKeyUp}
                            className='send-message-area'
                          ></textarea>
                          <div className='send-icon-parent'>
                            {isSendingMessage ? (
                              <IconButton>
                                <CircularProgress style={{ width: 22, height: 22 }} />
                              </IconButton>
                            ) : (
                              <IconButton disabled={isSendingMessage} onClick={handleSendMessage}>
                                <SendIcon style={{ width: 22, height: 22 }} />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        {/* <MessageStyle
                          placeholder={`${chatFile ? 'image description' : 'message'}`}
                          onKeyDown={handleKeyDown}
                          disabled={isSendingMessage}
                          value={message}
                          onChange={onMessageChange}
                          endAdornment={
                            isSendingMessage ? (
                              <IconButton>
                                <CircularProgress style={{ width: 22, height: 22 }} />
                              </IconButton>
                            ) : (
                              <IconButton disabled={isSendingMessage} onClick={handleSendMessage}>
                                <SendIcon style={{ width: 22, height: 22 }} />
                              </IconButton>
                            )
                          }
                          variant='outlined'
                          type='text'
                        /> */}
                      </div>
                      <div className='col-1 pl-0'>
                        <div className='d-flex align-items-center justify-content-center'>
                          <label htmlFor='icon-button-file' className='m-0'>
                            <input
                              className='d-none'
                              accept='image/png,image/jpg,image/jpeg'
                              id='icon-button-file'
                              type='file'
                              value=''
                              onChange={handleFileChange}
                            />
                            <Tooltip arrow title='upload image'>
                              <IconButton
                                color='primary'
                                aria-label='upload picture'
                                component='span'
                              >
                                <PhotoSizeSelectActualIcon />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <CkEditor content={message} setContent={(value) => setMessage(value)} /> */}
                  </div>
                  {chatFile && (
                    <div className='floating-image-bar'>
                      <div className='message-image-container'>
                        <div style={{ height: '50px' }}>
                          <img
                            width='50px'
                            style={{ maxWidth: 'inherit' }}
                            src={chatImage}
                            alt='chat image'
                          />
                        </div>
                        <Tooltip title='remove' arrow>
                          <div onClick={removeImage} className='message-image-cross'>
                            <CancelIcon />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* hidden on screens above 768px */}
          <div className='col-12 chat-messages-full common-border'>
            <div className='chat-tabs'>
              <ChatHeader
                currentGroup={currentGroupDetail}
                getChatGroupList={getChatGroupList}
                getGroupDetailWithMessages={getGroupDetailWithMessages}
                setSelectedGroup={setSelectedGroup}
                toggleDetailSidebar={toggleChatDetailSidebar}
                toggleSideChatList={toggleSideChatList}
              />
            </div>
            <div className='chat-messages-parent '>
              <div className='messages-container' id='message-items-container'>
                {!isLoadingDetail && (
                  <div ref={scrollRef} onScroll={handleScroll} className='scroller'>
                    {getMessages().map((item, i) => (
                      <ChatMessageItem
                        onEdit={(e, item) => {
                          handleOpenMessageMenu(e, item);
                        }}
                        key={i}
                        item={item}
                      />
                    ))}
                  </div>
                  // <ScrollToBottom
                  //   initialScrollBehavior='auto'
                  //   behavior='auto'
                  //   followButtonClassName='hidden-element'
                  //   mode='bottom'
                  //   className='messages-container'
                  // >
                  //   {getMessages().map((item, i) => (
                  //     <ChatMessageItem
                  //       onEdit={(e, item) => {
                  //         handleOpenMessageMenu(e, item);
                  //       }}
                  //       key={i}
                  //       item={item}
                  //     />
                  //   ))}
                  // </ScrollToBottom>
                )}

                {isLoadingDetail && (
                  <div className='center-progress'>
                    <CircularProgress />
                  </div>
                )}
                {!isLoadingDetail && (
                  <>
                    {getMessages().length < 1 && (
                      <div className='center-progress'>
                        <Typography textAlign='center' fontWeight='bold'>
                          No messages yet.
                        </Typography>
                      </div>
                    )}
                  </>
                )}
                <MessageMenu
                  anchorEl={messageMenuAnchorEl}
                  setAnchorEl={setMessageMenuAnchorEl}
                  handleOnEdit={() => null}
                  handleOnDel={handleOnDelMessage}
                  toggle={() => null}
                  isDelDialogOpen={isDelDialogOpen}
                  toggleDelDialog={toggleDelDialog}
                  isDeletingMessage={isDeletingMessage}
                />
              </div>
              {chatListData.length > 0 && (
                <div className='chat-bottom-bar'>
                  <div className='message-editor'>
                    <div className='row align-items-center'>
                      <div className='col-11 pr-0'>
                        <div className='text-area-parent'>
                          <textarea
                            value={message}
                            onChange={handleAreaChange}
                            onKeyDown={handleAreaKeyDown}
                            onKeyUp={handleAreaKeyUp}
                            className='send-message-area'
                          ></textarea>
                          <div className='send-icon-parent'>
                            {isSendingMessage ? (
                              <IconButton>
                                <CircularProgress style={{ width: 22, height: 22 }} />
                              </IconButton>
                            ) : (
                              <IconButton disabled={isSendingMessage} onClick={handleSendMessage}>
                                <SendIcon style={{ width: 22, height: 22 }} />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        {/* <MessageStyle
                          onKeyDown={handleKeyDown}
                          disabled={isSendingMessage}
                          value={message}
                          onChange={onMessageChange}
                          endAdornment={
                            isSendingMessage ? (
                              <IconButton>
                                <CircularProgress style={{ width: 22, height: 22 }} />
                              </IconButton>
                            ) : (
                              <IconButton disabled={isSendingMessage} onClick={handleSendMessage}>
                                <SendIcon style={{ width: 22, height: 22 }} />
                              </IconButton>
                            )
                          }
                          variant='outlined'
                          placeholder={`${chatFile ? 'image description' : 'message'}`}
                          type='text'
                        /> */}
                      </div>
                      <div className='col-1 pl-0'>
                        <div className='d-flex align-items-center justify-content-center'>
                          <label htmlFor='icon-button-file' className='m-0'>
                            <input
                              value=''
                              className='d-none'
                              accept='image/png,image/jpg,image/jpeg'
                              id='icon-button-file'
                              type='file'
                              onChange={handleFileChange}
                            />
                            <Tooltip arrow title='upload image'>
                              <IconButton
                                color='primary'
                                aria-label='upload picture'
                                component='span'
                              >
                                <PhotoSizeSelectActualIcon />
                              </IconButton>
                            </Tooltip>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <CkEditor content={message} setContent={(value) => setMessage(value)} /> */}
                  </div>
                  {chatFile && (
                    <div className='floating-image-bar'>
                      <div className='message-image-container'>
                        <div style={{ height: '50px' }}>
                          <img
                            width='50px'
                            style={{ maxWidth: 'inherit' }}
                            src={chatImage}
                            alt='chat image'
                          />
                        </div>
                        <Tooltip title='remove' arrow>
                          <div onClick={removeImage} className='message-image-cross'>
                            <CancelIcon />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* dialogs */}
        {/* <NewMessageDialog
          isLoading={isInitializingChat}
          isOpen={addGroupOpen}
          toggle={toggleAddGroup}
          onInitializeMessage={handleInitializeMessage}
        /> */}
        {/* sidebars */}
        <NewMessageSidebar
          isLoading={isInitializingChat}
          isOpen={addGroupOpen}
          toggle={toggleAddGroup}
          onInitializeMessage={handleInitializeMessage}
          onUserClick={onUserClick}
        />
        <ChatDetailSidebar
          detail={currentGroupDetail}
          isOpen={isChatDetailSidebarOpen}
          toggle={toggleChatDetailSidebar}
        />
        <ChatListSidebar
          toggleAddGroup={toggleAddGroup}
          isOpen={isSideChatListOpen}
          toggle={toggleSideChatList}
        >
          <div className='col-12 chat-users-hidden common-border'>
            <div className='chat-tabs'>
              <div className='d-flex w-100 position-relative' style={{ bottom: '4px' }}>
                <span className='w-100'>
                  <StyledChatSearch
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    placeHolder='Search...'
                  />
                </span>
              </div>
              {/* <ChatTabs groupTabActive={groupTab} toggleTabs={toggleTabs} /> */}
            </div>
            {/* <Divider className='mt-1' /> */}
            <Scrollbar>
              <GroupList
                isLoadingGroups={isLoadingGroupList}
                isLoadingDetail={isLoadingDetail}
                onGetDetail={getDetailByType}
                currentItem={currentGroupDetail}
                isChatActive={isChatActive}
                setActiveItem={setActiveItem}
                shouldNavigate={true}
                chatListData={getFilteredChatList(chatListData)}
              />
            </Scrollbar>
            {!isLoadingGroupList && (
              <>
                {chatListData.length === 0 && (
                  <div className='center-progress'>
                    <Typography textAlign='center' fontWeight='bold'>
                      No messages yet.
                    </Typography>
                  </div>
                )}
              </>
            )}
            {isLoadingGroupList && (
              <div className='center-progress'>
                <CircularProgress />
              </div>
            )}
          </div>
        </ChatListSidebar>
      </Container>
    </Page>
  );
};

export default TeamChat;
