import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
export default function SideMenuPopover({
  isOpen,
  onClose,
  anchorEl,
  handleOpenDialog,
  sections,
  handleMoveSection,
  closeParentPopover,
  selectedRow,
  data,
}) {
  const handleClose = () => {
    onClose();
  };
  const handleMove = (section) => {
    let result = handleMoveSection(section);
    handleClose();
    closeParentPopover();
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '...');
      return result;
    }
    return string;
  };
  const getFiltered = (_data) => {
    if (!_data) {
      return [];
    }
    if (selectedRow) {
      let sectionId = selectedRow.sectionId;
      let filtered = _data.filter((item) => String(item.id) !== String(sectionId));
      return filtered;
    }
    return [];
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ sx: { marginLeft: '-0.5rem', marginTop: '1rem' } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <List>
          {getFiltered(data).length > 0 ? (
            getFiltered(data).map((section, index) => {
              return (
                <ListItem key={index} sx={{ padding: '.2rem' }}>
                  <ListItemButton onClick={() => handleMove(section)}>
                    <ListItemText>
                      <Typography sx={{ fontSize: '0.8rem' }}>
                        {truncateText(section.section_name, 30)}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <ListItem disabled sx={{ padding: '.2rem' }}>
              <ListItemButton disabled>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.8rem' }}>No sections yet</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
}
