import { invokeApi } from "../../bl_libs/invokeApi";

export const projectListing = async (data) => {
  const requestObj = {
    path: `api/projects/client_project_listing.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addProject = async (data) => {
  const requestObj = {
    path: `api/projects/add_project_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allowedSalesActiveTeam = async (data) => {
  const requestObj = {
    path: `api/team/get_team_manager_team_list.php`,
    // path: `api/team/get_sale_enable_team_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleAssociate = async (data) => {
  const requestObj = {
    path: `api/sales/update_associate.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeProjectCategories = async (data) => {
  const requestObj = {
    path: `api/manager_team/get_manager_category_by_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const detailProjectV2 = async (data) => {
  const requestObj = {
    path: `api/projects/detail_project_with_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addProjectTeam = async (data) => {
  const requestObj = {
    path: `api/team_project/add_project_by_team_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const detailProject = async (data) => {
  const requestObj = {
    path: `api/projects/detail_project.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const userProfile = async (data) => {
  const requestObj = {
    path: `api/user_profile/detail_profile.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editProject = async (data) => {
  const requestObj = {
    path: `api/projects/edit_project.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editProjectTeam = async (data) => {
  const requestObj = {
    path: `api/team_project/edit_project_by_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteProject = async (data) => {
  const requestObj = {
    path: `api/projects/delete_project.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteProjectTeam = async (data) => {
  const requestObj = {
    path: `api/team_project/delete_project_by_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const taskList = async (data) => {
//   const requestObj = {
//     path: `api/task/task_list.php`,
//     method: 'POST',
//     headers: {},
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
/* for testing */
export const taskList = async (data) => {
  const requestObj = {
    path: `api/task/task_list_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const verifiedTaskList = async (data) => {
  const requestObj = {
    path: `api/task/get_verified_task_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allTeamTasks = async (data) => {
  const requestObj = {
    path: `api/task/all_task_list_by_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allCompletedTasks = async (data) => {
  const requestObj = {
    path: `api/task/get_completed_task_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allTargetDatePassedTasks = async (data) => {
  const requestObj = {
    path: `api/task/get_pass_target_date_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const allSalesListing = async (data) => {
  const requestObj = {
    path: `api/sales/user_sales_list_new.php`,
    // path: `api/sales/user_sales_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateOptionalSaleAssociates = async (data) => {
  const requestObj = {
    path: `api/sales/update_optional_associate.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleSectionsListing = async (data) => {
  const requestObj = {
    path: `api/sale_section/section_sale_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleTargetDate = async (data) => {
  const requestObj = {
    path: `api/sales/update_sale_target_date.php`,
    // path: `api/sales/user_sales_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleStatus = async (data) => {
  const requestObj = {
    path: `api/sales/update_sale_completed_status.php`,
    // path: `api/sales/user_sales_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateSaleDescription = async (data) => {
  const requestObj = {
    path: `api/sales/update_description.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeSaleSectionListing = async (data) => {
  const requestObj = {
    path: `api/sale_section/section_sales_list_for_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const saleSectionListingForAddSale = async (data) => {
  const requestObj = {
    path: `api/sale_section/section_sales_list_for_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addSaleByTeam = async (data) => {
  const requestObj = {
    path: `api/sales/add_sale_by_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const moveSaleToSection = async (data) => {
  const requestObj = {
    path: `api/sales/update_section_id.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const activeProductListing = async (data) => {
  const requestObj = {
    path: `api/product/active_products_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addTask = async (data) => {
  const requestObj = {
    path: `api/task/add_task_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTask = async (data) => {
  const requestObj = {
    path: `api/task/delete_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const changePriority = async (data) => {
  const requestObj = {
    path: `api/task/change_task_priority.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

// export const changeStatus = async (data) => {
//   const requestObj = {
//     path: `api/task/change_task_status.php`,
//     method: 'POST',
//     headers: {},
//     postData: data,
//   };
//   return invokeApi(requestObj);
// };
/* for testing */
export const changeStatus = async (data) => {
  const requestObj = {
    path: `api/task/change_task_status_v1.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskMembers = async (data) => {
  const requestObj = {
    path: `api/task/add_member_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const markAllCommentsAsRead = async (data) => {
  const requestObj = {
    path: `api/task_comment/delete_view_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteTaskMembers = async (data) => {
  const requestObj = {
    path: `api/task/delete_member_task.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addTaskTargetDate = async (data) => {
  const requestObj = {
    path: `api/task/change_target_date.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskFilter = async (data) => {
  const requestObj = {
    path: `api/task/task_filter.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const taskFilterTeam = async (data) => {
  const requestObj = {
    path: `api/task/task_filter_for_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskOrder = async (data) => {
  const requestObj = {
    path: `api/task/change_task_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/add_task_files.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const removeTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/delete_task_files.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const detailTaskFiles = async (data) => {
  const requestObj = {
    path: `api/task/task_files_detail.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const changeTaskDetail = async (data) => {
  const requestObj = {
    path: `api/task/edit_task_text.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const taskComments = async (data) => {
  const requestObj = {
    path: `api/task_comment/comment_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskComments = async (data) => {
  const requestObj = {
    path: `api/task_comment/add_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditComment = async (data) => {
  const requestObj = {
    path: `api/task_comment/edit_task_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteComment = async (data) => {
  const requestObj = {
    path: `api/task_comment/delete_comment.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
/* <<<<<<<<<DASHBOARD>>>>>>>>> */
export const getUserDashboardTasks = async (data) => {
  const requestObj = {
    path: `api/user_dashboard/user_dashboard.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const uploadEditorImage = async (data) => {
  const requestObj = {
    path: `api/general_image/editor_images.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
/*  */
export const taskListCollapsable = async (data) => {
  const requestObj = {
    path: `api/task/task_list_for_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const taskSectionsList = async (data) => {
  const requestObj = {
    path: `api/task_section/task_section_list_for_team.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const addTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/add_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaskSectionOrder = async (data) => {
  const requestObj = {
    path: `api/task_section/update_task_section_order.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/delete_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const makeSectionAsDefault = async (data) => {
  const requestObj = {
    path: `api/task_section/update_is_default.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const moveTaskToSection = async (data) => {
  const requestObj = {
    path: `api/task/update_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateTaskSection = async (data) => {
  const requestObj = {
    path: `api/task_section/edit_task_section.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const allNotesList = async (data) => {
  const requestObj = {
    path: `api/notes/note_list.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const addNotes = async (data) => {
  const requestObj = {
    path: `api/notes/add_note.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};


export const editNotes = async (data) => {
  const requestObj = {
    path: `api/notes/edit_note.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteNotes = async (data) => {
  const requestObj = {
    path: `api/notes/delete_note.php`,
    method: "POST",
    headers: {},
    postData: data,
  };
  return invokeApi(requestObj);
};
