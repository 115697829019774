import React, { useState, useRef } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import { Typography, IconButton, Tooltip, Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { baseUri } from '../../../config/config';
// components
import MenuPopover from '../../../components/MenuPopover';
import { useSnackbar } from 'notistack';

function AddMembers(props) {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const [openMemberSelect, setOpenMemberSelect] = useState(false);
  // const [taskstatus, setTaskStatus] = useState(LANGS[0]);
  const handleClose = () => {
    setOpenMemberSelect(false);
  };

  const handleOpenMemberSelect = () => {
    setOpenMemberSelect(true);
  };

  const handleAdd = (value, id) => {
    console.log(value, 'handle-add');
    props.onAddMember(value, props.onId);
  };
  const showDeleteButton = (member, id) => {
    return true;
    console.log(member, 'memmm');
    const member_id = member.user_id;
    const user_id = localStorage.getItem('user_id');
    let can_delete = false;
    props.taskData.filter((task, index) => {
      if (String(task.task_id) === String(id)) {
        console.log(task, 'filter-found');
        task.members.filter((member) => {
          if (String(member.task_assigner_id) === String(user_id)) {
            can_delete = true;
          }
        });
      }
    });
    console.log(can_delete, 'show?');
    // return true;
    return can_delete;
  };
  const handleDelete = (member, id) => {
    const user_id = localStorage.getItem('user_id');
    let can_delete = false;
    props.taskData.map((task, index) => {
      if (String(task.task_id) === String(id)) {
        console.log(task, 'found-task');
        task.members.map((member, index) => {
          if (String(member.task_assigner_id) === String(user_id)) {
            can_delete = true;
          }
        });
      }
    });
    console.log(member, 'handle-delete');
    if (!can_delete) {
      enqueueSnackbar('You Are Not Allow To Unassign this member', { variant: 'error' });
      return;
    }
    props.onDeleteMember(member, props.onId);
  };

  const getSelectedMember = (team) => {
    const updatedList = props.membersData.map((x, i) => {
      // let can_delete = showDeleteButton(x, props.onId);
      // x = { ...x, canDelete: can_delete };
      const findMember = team.find((t) => String(x.user_id) === String(t.user_id));
      if (findMember) {
        return {
          ...x,
          isSelected: true,
        };
      }

      return {
        ...x,
        isSelected: false,
      };
    });

    return updatedList;
  };

  return (
    <>
      <Tooltip title='Add Member'>
        <Avatar
          ref={anchorRef}
          onClick={() => handleOpenMemberSelect()}
          style={{ width: 30, height: 30, cursor: 'pointer' }}
        >
          <AddIcon style={{ color: 'green' }} />
        </Avatar>
      </Tooltip>
      <MenuPopover open={openMemberSelect} onClose={handleClose} anchorEl={anchorRef.current}>
        <List>
          {getSelectedMember(props.assignMembers).length > 0 ? (
            getSelectedMember(props.assignMembers).map((member, index) => (
              <React.Fragment key={index}>
                {member.isSelected === false ? (
                  <ListItem
                    onClick={() => handleAdd(member, props.onId)}
                    style={{ cursor: 'pointer' }}
                    className='list-hov'
                  >
                    <ListItemIcon>
                      {member.image !== '' ? (
                        <Avatar
                          alt='Remy Sharp'
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                ) : (
                  <ListItem
                    style={{
                      borderLeft: '2px solid #00AB55',
                      borderRadius: 3,
                      backgroundColor: 'rgba(0, 171, 85, 0.08)',
                      margin: 2,
                    }}
                    secondaryAction={
                      showDeleteButton(member) === true ? (
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={() => handleDelete(member, props.onId)}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : null
                    }
                  >
                    <ListItemIcon>
                      {member.image !== '' ? (
                        <Avatar
                          alt='Remy Sharp'
                          src={baseUri + member.image}
                          style={{ height: 30, width: 30 }}
                        />
                      ) : (
                        <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                          {member.first_name.substring(0, 1)}
                        </Avatar>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {member.first_name} {member.last_name}
                    </ListItemText>
                  </ListItem>
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem disabled>
              <ListItemText>
                <Typography style={{ fontSize: '0.7rem' }}>Team members not added</Typography>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </MenuPopover>
    </>
  );
}

export default AddMembers;
