import {
  Container,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Switch,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import Page from "src/components/Page";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filter, sample } from "lodash";
import { useSnackbar } from "notistack";
import {
  activeSaleSectionListing,
  allowedSalesActiveTeam,
  allSalesListing,
  moveSaleToSection,
  updateOptionalSaleAssociates,
  updateSaleStatus,
  updateSaleTargetDate,
} from "src/DAL/Project/Project";
import { useState } from "react";
import { baseUri } from "src/config/config";
import StyledSearch from "../Tasks/components/search/StyledSearch";
import moment from "moment";
import { noTask } from "src/assets";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "src/components/Loader";
import EditSaleSidebar from "./components/EditSaleSidebar";
import FilterByProduct from "./components/FilterByProduct";
import PaidStatusPopover from "./components/PaidStatusPopover";
import SaleListAccordion from "./components/SaleListAccordion";
import MoreMenuPopover from "./components/MoreMenuPopover";
import { useNavigate } from "react-router-dom";
import AnswerSidebar from "./components/AnswerSidebar";
import { useSocket } from "src/Hooks/socketContext";
import { ButtonSelect, SaleSection } from "src/components";
import SaleSearch from "./components/SaleSearch";
const AllSalesListing = () => {
  const SALE_SORT_OPTIONS = [
    {
      label: "None",
      value: "none",
      onClick: (_data, _index) => onSortClick(_data, _index),
    },
    {
      label: "Due Date",
      value: "due-date",
      onClick: (_data, _index) => onSortClick(_data, _index),
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchValue, setSearchValue] = useState("");
  const [saleSortSelectedIndex, setSaleSortSelectedIndex] = useState(0);
  const [displaySectionSelectedIndex, setDisplaySectionSelectedIndex] =
    useState(0);
  const [mySalesOnly, setMySalesOnly] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const { getUserProfileData } = useSocket();
  const user = getUserProfileData();
  const [loading, setLoading] = useState(true);
  const [productOptions, setProductOptions] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [isSidebarOpen, seIsSidebarOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGettingSections, setIsGettingSections] = useState(false);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [isAnswerSidebarOpen, setIsAnswerSidebarOpen] = useState(false);
  const [selectedAns, setSelectedAns] = useState(undefined);
  const [associateList, setAssociateList] = useState([]);
  const prevSections = useRef([]);
  const prevProducts = useRef([]);
  const onSortClick = (_option, _index) => {
    console.log(_option);
    setSaleSortSelectedIndex(Number(_index));
  };
  const getCommaSeparated = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getDisplaySection = () => {
    let section = sectionList[displaySectionSelectedIndex - 1];
    if (!section) {
      return undefined;
    }
    return section;
  };
  const getDisplaySectionsList = () => {
    let default_value = {
      label: "All sections",
      value: "all",
      onClick: (option, index) => handleSelectDisplaySection(option, index),
    };
    if (!sectionList) {
      return [default_value];
    }
    let list = sectionList.map((item) => {
      return {
        label: item.section_name,
        value: item.section_id,
        onClick: (option, index) => handleSelectDisplaySection(option, index),
      };
    });
    return [default_value, ...list];
  };
  const handleSelectDisplaySection = (_option, _index) => {
    setDisplaySectionSelectedIndex(_index);
    console.log(_option, _index, "onclick");
    navigate("/all-sales-listing", {
      state: {
        display_section_id: _option.value,
      },
    });
  };
  const getAllSalesListing = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await allSalesListing(formData);
    if (result.code === 200) {
      console.log(result, "rez");
      setLoading(false);
      let section_list = result.sections_list.map((section, i) => {
        return {
          ...section,
          ["expanded"]: i === 0,
          ["section_sales"]: section.section_sales.map((item) => {
            let new_user_id = String(localStorage.getItem("user_id"))
            let new_optional_associate = JSON.parse(item.optional_associate)
            let optional_associate_find_id = new_optional_associate.find((x)=> x == new_user_id)

            return {
              ...item,
              ["amount"]: getCommaSeparated(Number(item.amount)),
              ["is_sale_assigned"]:
                String(item.associate_id) ===
                String(localStorage.getItem("user_id")) || optional_associate_find_id === new_user_id,
            };
          }),
        };
      });
      console.log(section_list, "modif");
      setSectionList(section_list);
      let merged = [];
      let unique = [];
      result.sections_list.forEach((section) => {
        let result = getProductNames(section.section_sales);
        if (result) {
          result.map((product) => {
            if (!unique.includes(product.product_id)) {
              unique.push(product.product_id);
              merged.push(product);
            }
          });
        }
      });
      console.log(merged, "merged-product-options");
      setProductOptions(merged);
    } else {
      setLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  
  const isExpanded = (id) => {
    if (prevSections.current) {
      let expand = false;
      prevSections.current.forEach((section) => {
        if (String(section.section_id) === String(id)) {
          expand = section.expanded;
        }
      });
      return expand;
    }
  };
  const getSections = () => {
    // console.log(sectionList, 'sls');
    return sectionList;
  };
  const expandSection = (id) => {
    let changed_expand_state = sectionList.map((item) => {
      if (String(item.section_id) === String(id)) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      }
      return item;
    });
    console.log(changed_expand_state, "changed-exp");
    prevSections.current = changed_expand_state;
    setSectionList((sections) =>
      sections.map((item) => {
        if (String(item.section_id) === String(id)) {
          return {
            ...item,
            expanded: !item.expanded,
          };
        }
        return item;
      })
    );
  };
  const handleChangeSearchValue = (value) => {
    setSearchValue(value);
  };
  const detectSafariBrowser = () => {
    let is_safari = false;
    if (navigator.vendor === "Apple Computer, Inc.") {
      is_safari = true;
    }
    return is_safari;
  };
  const getActualTime = (date) => {
    let date_safari = date;
    // date_safari = date_safari.replaceAll('-', '/');
    for (let i = 0; i < date_safari.length; i++) {
      if (date_safari.charAt(i) === "-") {
        date_safari = date_safari.replace("-", "/");
      }
    }
    const serverDate = new Date(date);
    const difference = serverDate.getTimezoneOffset();
    const serverDate_safari = new Date(date_safari);
    const difference_safari = serverDate_safari.getTimezoneOffset();
    if (detectSafariBrowser()) {
      const setTime = moment(
        moment(date_safari).subtract(difference_safari, "m")
      ).format("DD, MMM YYYY hh:mm:s a ");
      return setTime;
    }
    if (!detectSafariBrowser()) {
      const setTime = moment(
        moment(serverDate).subtract(difference, "m")
      ).format("DD, MMM YYYY hh:mm:s a ");

      return setTime;
    }
  };
  const handleLocalTaskSearch = (input, array) => {
    const concat_string = (sale) => {
      return `${sale.first_name} ${sale.last_name} ${sale.client_email} ${sale.phone} ${sale.product_name} `;
    };
    let filtered = filter(
      array,
      (sale) =>
        concat_string(sale).toLowerCase().indexOf(input.toLowerCase()) !== -1
      // (sale) => sale.product_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredList = (data) => {
    /* sorting */
    let sorter = SALE_SORT_OPTIONS[saleSortSelectedIndex];
    if (sorter.value === "due-date") {
      console.log(sorter, "sorter");
      let sorted_data = data.sort((a, b) => {
        const getDate = (item) => {
          console.log(item, "otm");
          if (!item.sale_target_date) {
            return "";
          }
          if (!item) {
            return "";
          }
          return item.sale_target_date;
        };
        let targetDateA = getDate(a);
        let targetDateB = getDate(b);
        let res1 = 0;
        let res2 = 0;
        let dateAResult = moment(targetDateA).isAfter(targetDateB);
        console.log(dateAResult, "dateAResult");
        if (dateAResult === true) {
          res1 = 1;
        }
        a = { ...a, ["isDateGreater"]: res1 };
        let dateBResult = moment(targetDateB).isAfter(targetDateA);
        console.log(dateBResult, "dateBResult");
        if (dateBResult === true) {
          res2 = 1;
        }
        b = { ...b, ["isDateGreater"]: res2 };
        return a.isDateGreater - b.isDateGreater;
      });

      data = Array.from(sorted_data);
    }
    if (sorter.value === "none") {
      let sorted_data = data.sort((a, b) => {
        const getDate = (item) => {
          /* same date issue */
          if (!item.sale_created_at) {
            // if (!item.sale_target_date) {
            return "";
          }
          if (!item) {
            return "";
          }
          return item.sale_created_at;
          // return item.sale_target_date;
        };
        let targetDateA = getDate(a);
        let targetDateB = getDate(b);
        let res1 = 0;
        let res2 = 0;
        let dateAResult = moment(targetDateA).isAfter(targetDateB);

        if (dateAResult === true) {
          res1 = 1;
        }
        a = { ...a, ["isDateGreater"]: res1 };
        let dateBResult = moment(targetDateB).isAfter(targetDateA);

        if (dateBResult === true) {
          res2 = 1;
        }
        b = { ...b, ["isDateGreater"]: res2 };
        return b.isDateGreater - a.isDateGreater;
      });

      data = Array.from(sorted_data);
    }
    if (productOptions.length > 0) {
      let filtered_data = [];
      let only_my_sales = [];
      let selected = [];
      productOptions.map((option, index) => {
        if (option.isSelected === true) {
          selected.push(option);
        }
      });
      // console.log(selected, 'is-selected');
      selected.forEach((selected_option) => {
        data.map((sale, index) => {
          if (String(sale.product_id) === String(selected_option.product_id)) {
            filtered_data.push(sale);
          }
        });
      });
      if (selected.length > 0) {
        data = filtered_data;
      }
    }
    if (mySalesOnly) {
      let filtered = data.filter((item) => item.is_sale_assigned === true);
      data = Array.from(filtered);
    }

    if (searchValue !== "") {
      const filtered = handleLocalTaskSearch(searchValue, data);
      return filtered;
    }
    return data;
  };
  const isSelected = (id) => {
    if (prevProducts.current) {
      let selected = false;
      prevProducts.current.forEach((product) => {
        if (String(product.product_id) === String(id)) {
          if (product.isSelected === true) {
            selected = true;
          }
        }
      });
      return selected;
    }
  };
  const getActiveAssociateList = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await allowedSalesActiveTeam(formData);
    if (result.code === 200) {
      console.log(result, "associate-list");
      let associates = result.manager_team_list.map((team) => {
        return { ...team, isSelected: false };
      });
      setAssociateList(associates);
    } else {
      console.log(result, "associate-list");
    }
  };
  const getProductNames = (data) => {
    console.log(data, "data,");
    let options = [];
    let unique = [];
    if (data.length > 0) {
      data.map((task) => {
        if (!unique.includes(String(task.product_id))) {
          unique.push(String(task.product_id));
          options.push({
            product_id: task.product_id,
            label: task.product_name,
            isSelected: isSelected(task.product_id),
            // isSelected: false,
          });
        }
      });
      console.log(options, "product-options");
      return options;
      // setProductOptions(options);
    }
  };

  const handleSelectProduct = (value, data) => {
    console.log(value, "val");
    const { product_id } = value;
    productOptions.map((productOption, index) => {
      if (String(productOption.product_id) === String(product_id)) {
        let options = Array.from(productOptions);
        options[index] = { ...productOption, ["isSelected"]: true };

        setProductOptions(options);
        prevProducts.current = options;
        console.log(prevProducts.current, "prev-pro");
      }
    });
  };
  const handleUnselectProduct = (value, data) => {
    const { product_id } = value;
    productOptions.map((productOption, index) => {
      if (String(productOption.product_id) === String(product_id)) {
        let options = Array.from(productOptions);
        options[index] = { ...productOption, ["isSelected"]: false };

        setProductOptions(options);
        prevProducts.current = options;
        console.log(prevProducts.current, "prev-pro");
      }
    });
  };
  const getDate = (date) => {
    // let formatted = moment(date).format('YYYY-MM-DD, HH:MM');
    let time = getActualTime(date);
    return time;
  };
  const closeSidebar = () => {
    seIsSidebarOpen(false);
  };
  const getPhone = (phone) => {
    if (phone !== "") {
      let text = `${" - " + phone}`;
      return text;
    }
    return "";
  };
  const openSidebar = (data) => {
    setSelectedItem(data);
    seIsSidebarOpen(true);
  };
  const handleMoveSection = async (data) => {
    console.log(data, "target-section");
    console.log(selectedRow, "target-row");
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", selectedRow.id);
    formData.append("section_id", data.section_id);
    formData.append("client_email", selectedRow.client_email);
    console.log(...formData, "move-to-section-req");
    const result = await moveSaleToSection(formData);
    if (result.code === 200) {
      console.log(result, "move-to-res");
      enqueueSnackbar("Sale moved to section", { variant: "success" });
      getAllSalesListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleOpenMenu = (e, sale_item) => {
    console.log(sale_item, "target-task");
    setMenuAnchorEl(e.currentTarget);
    setIsMenuOpen(true);
    setSelectedRow(sale_item);
  };
  const fetchSectionListing = async () => {
    setIsGettingSections(true);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeSaleSectionListing(formData);
    if (result.code === 200) {
      setSectionList(result.active_sale_section);
      console.log(result, "sales-sections");
      setIsGettingSections(false);
    } else {
      setIsGettingSections(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const goToAddSale = () => {
    navigate("/add-sale");
  };
  const handleSaleStatusChange = async (payload) => {
    const { sale_item } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }
    const updated_status = payload.event.target.checked === true ? "1" : "0";
    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return { ...sale, completed_status: updated_status };
      }
      return sale;
    });
    const updated_section = { ...target_section, section_sales: updated_sales };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    formData.append("completed_status", updated_status);
    const result = await updateSaleStatus(formData);
    if (result.code === 200) {
      enqueueSnackbar(
        `Sale marked as ${updated_status === "1" ? "complete" : "incomplete"}`,
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getAllSalesListing();
    }
  };
  const handleSaleTargetDateChange = async (payload) => {
    const { sale_item } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }

    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return { ...sale, sale_target_date: payload.date };
      }
      return sale;
    });
    const updated_section = { ...target_section, section_sales: updated_sales };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    formData.append(
      "sale_target_date",
      moment(payload.date).format("YYYY-MM-DD")
    );
    console.log(...formData, "update-sale-date-req");
    const result = await updateSaleTargetDate(formData);
    if (result.code === 200) {
      enqueueSnackbar("Due Date Updated", { variant: "success" });
    } else {
      getAllSalesListing();
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeOptionalAssociates = async (payload) => {
    const { sale_item, member_action, member } = payload;
    const target_section = sectionList.find(
      (s) => String(s.section_id) === String(sale_item.sale_section_id)
    );
    if (!target_section) {
      return;
    }
    let updated_optional_associates = [];
    const getUpdatedAssociates = (members) => {
      const users = JSON.parse(members);
      if (!users) {
        return members;
      }
      if (member_action === "pop") {
        let updated = users.filter(
          (u_id) => String(u_id) !== String(member.user_id)
        );
        updated_optional_associates = [...updated];
        return JSON.stringify(updated);
      }
      if (member_action === "push") {
        let copy = [...users];
        copy.push(member.user_id);
        updated_optional_associates = [...copy];
        return JSON.stringify(copy);
      }
      return members;
    };
    let updated_sales = target_section.section_sales.map((sale) => {
      if (String(sale.id) === String(sale_item.id)) {
        return {
          ...sale,
          optional_associate: getUpdatedAssociates(sale.optional_associate),
        };
      }
      return sale;
    });
    const updated_section = { ...target_section, section_sales: updated_sales };

    setSectionList((prev) =>
      prev.map((s) => {
        if (String(s.section_id) === String(target_section.section_id)) {
          return updated_section;
        }
        return s;
      })
    );

    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("sale_id", sale_item.id);
    updated_optional_associates.forEach((u_id, i) => {
      formData.append(`optional_associate[${i}]`, u_id);
    });
    console.log(...formData, "opt-assoc-upd");

    const result = await updateOptionalSaleAssociates(formData);
    console.log(result, "res-opt-ast");
    if (result.code === 200) {
      enqueueSnackbar("Other Associates updated successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getAllSalesListing();
    }
  };
  const handleSaleAction = (action) => {
    console.log(action, "handleSaleAction");
    const { action_title, payload } = action;

    if (!action_title || !payload) {
      return;
    }
    if (String(action_title) === "open_sale_card_menu") {
      setSelectedRow(payload.sale_item);
      handleOpenMenu(payload.event, payload.sale_item);
    }
    if (String(action_title) === "open_sale_card_sidebar") {
      openSidebar(payload.sale_item);
    }
    if (String(action_title) === "open_answer_detail") {
      setSelectedAns(payload.answer_detail);
      setIsAnswerSidebarOpen(true);
    }

    if (String(action_title) === "sale_status_change") {
      handleSaleStatusChange(payload);
    }
    if (String(action_title) === "sale_target_date_change") {
      handleSaleTargetDateChange(payload);
    }
    if (String(action_title) === "change_optional_associates") {
      handleChangeOptionalAssociates(payload);
    }
  };
  const handleSectionAction = (action) => {
    console.log(action, "handleSectionAction");
  };

  useEffect(() => {
    getActiveAssociateList();
    getAllSalesListing();
    // fetchSectionListing();
  }, []);
  if (loading) {
    return <Loader />;
  }
  let allow = user.sales_option;
  if (!allow) {
    return <Navigate to="/project" />;
  }
  if (String(allow) === "0") {
    return <Navigate to="/project" />;
  }
  return (
    <Page title="All Sales | Support Task Portal">
      <Container maxWidth="xl">
        <div className="row main-header">
          <div
            className="col-6  pl-0 align-items-center"
            style={{ display: "flex" }}
          >
            <Tooltip title="Click to View Description">
              <span role="button">
                <Typography
                  style={{ fontsize: 16, fontWeight: "bold", paddingLeft: 8 }}
                >
                  All Sales Listing
                </Typography>
              </span>
            </Tooltip>
          </div>
          <div
            className="col-6  pl-0 align-items-center justify-content-end"
            style={{ display: "flex" }}
          >
            <span role="button">
              <Button
                style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
                variant="contained"
                type="submit"
                onClick={goToAddSale}
              >
                Add Sale
              </Button>
            </span>
          </div>
        </div>
        <div
          style={{ flexWrap: "wrap" }}
          className="d-flex align-items-center mt-3"
        >
          <div>
            <SaleSearch
              placeHolder="Search..."
              query={searchValue}
              onChange={handleChangeSearchValue}
            />
          </div>
          <div className="ml-auto d-flex">
            {/* <div className="mr-2">
              <ButtonSelect
                title="Display Section"
                options={getDisplaySectionsList()}
                selectedIndex={displaySectionSelectedIndex}
              />
            </div> */}
            <div className="mr-2">
              <ButtonSelect
                title="Sort by"
                options={SALE_SORT_OPTIONS}
                selectedIndex={saleSortSelectedIndex}
              />
            </div>
            <div className="mr-2">
              <FilterByProduct
                onSelectProject={handleSelectProduct}
                onUnselectProject={handleUnselectProduct}
                productList={productOptions}
              />
            </div>
            <div className="ml-auto">
              <Tooltip title="Show only my sale leads">
                <div className="ml-auto">
                  My Sales :
                  <Switch
                    checked={mySalesOnly}
                    onChange={(e) => setMySalesOnly(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Stack spacing={2}>
            {sectionList.map((section) => (

              <SaleSection
                section={section}
                query={searchValue}
                key={section.section_id}
                onSaleAction={handleSaleAction}
                onSectionAction={handleSectionAction}
                associates={associateList}
                filters={{
                  mySale: mySalesOnly,
                  selectedProducts: productOptions.filter(
                    (p) => p.isSelected === true
                  ),
                  sorting: SALE_SORT_OPTIONS[saleSortSelectedIndex],
                }}
              />
            ))}
          </Stack>
          {/* {displaySectionSelectedIndex !== 0 ? (
            <>
              {getSections().length > 0 ? (
                <>
                  <SaleListAccordion
                    expandSection={expandSection}
                    section={getDisplaySection()}
                    data={getFilteredList(getDisplaySection().section_sales)}
                    sectionId={getDisplaySection().section_id}
                    handleOpenMenu={handleOpenMenu}
                    openSidebar={openSidebar}
                    getPhone={getPhone}
                    openAnswer={() => setIsAnswerSidebarOpen(true)}
                    setSelectedAns={(val) => setSelectedAns(val)}
                    selectedAns={selectedAns}
                    sectionList={sectionList}
                    associates={associateList}
                    setAssociates={setAssociateList}
                    setSectionList={setSectionList}
                    getAllSalesListing={getAllSalesListing}
                  />
                </>
              ) : (
                <>
                  <div className='col-12 mt-5'>
                    <img src={noTask} alt='no task' style={{ margin: 'auto' }} />
                    <Typography
                      style={{
                        textAlign: 'center',
                        paddingLeft: 50,
                        paddingTop: 10,
                      }}
                    >
                      No Sales Found
                    </Typography>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {getSections().length > 0 ? (
                getSections().map((section, index) => (
                  <SaleListAccordion
                    key={index}
                    expandSection={expandSection}
                    section={section}
                    data={getFilteredList(section.section_sales)}
                    sectionId={section.section_id}
                    handleOpenMenu={handleOpenMenu}
                    openSidebar={openSidebar}
                    getPhone={getPhone}
                    openAnswer={() => setIsAnswerSidebarOpen(true)}
                    setSelectedAns={(val) => setSelectedAns(val)}
                    selectedAns={selectedAns}
                    sectionList={sectionList}
                    associates={associateList}
                    setAssociates={setAssociateList}
                    setSectionList={setSectionList}
                    getAllSalesListing={getAllSalesListing}
                  />
                ))
              ) : (
                <div className='col-12 mt-5'>
                  <img src={noTask} alt='no task' style={{ margin: 'auto' }} />
                  <Typography
                    style={{
                      textAlign: 'center',
                      paddingLeft: 50,
                      paddingTop: 10,
                    }}
                  >
                    No Sales Found
                  </Typography>
                </div>
              )}
            </>
          )} */}
        </div>

        {/* sidebars */}
        <EditSaleSidebar
          selectedItem={selectedItem}
          isOpenEditSaleSidebar={isSidebarOpen}
          onCloseSaleSidebar={closeSidebar}
          getAllSalesListing={getAllSalesListing}
          prevSections={prevSections}
          // sections={sectionList}
          sections={getSections()}
        />
        <AnswerSidebar
          selectedAns={selectedAns}
          isOpen={isAnswerSidebarOpen}
          onClose={() => setIsAnswerSidebarOpen(false)}
        />
        {/* Popovers */}
        <MoreMenuPopover
          isOpen={isMenuOpen}
          onOpen={() => setIsMenuOpen(true)}
          onClose={() => setIsMenuOpen(false)}
          anchorEl={menuAnchorEl}
          selectedRow={selectedRow}
          // sections={sectionList}
          sections={getSections()}
          handleMoveSection={handleMoveSection}
        />
      </Container>
    </Page>
  );
};

export default AllSalesListing;
