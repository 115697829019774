import React, { useEffect, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Typography, AvatarGroup, IconButton, Tooltip, Popover } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { baseUri } from '../../../config/config';
// components
import MenuPopover from '../../../components/MenuPopover';

function MembersListingV2(props) {
  const anchorRef = useRef(null);
  const [openMemberListing, setOpenMemberListing] = useState(false);

  const handleClose = () => {
    setOpenMemberListing(false);
  };

  const handleOpenMemberListing = () => {
    setOpenMemberListing(true);
  };

  const handleAdd = (value) => {
    props.onAddMember(value, props.onId);
  };

  const handleDelete = (value) => {
    props.onDeleteMember(value, props.onId);
  };
  const getSelectedMember = (team) => {
    const updatedList = props.assignMembers.map((x, i) => {
      const findMember = team.find((t) => String(x.user_id) === String(t.user_id));
      if (findMember) {
        return {
          ...x,
          isSelected: true,
        };
      }

      return {
        ...x,
        isSelected: false,
      };
    });
    return updatedList;
  };

  return (
    <>
      <Tooltip title='Assignees'>
        <AvatarGroup
          max={3}
          style={{ cursor: 'pointer' }}
          ref={anchorRef}
          onClick={() => handleOpenMemberListing()}
        >
          {props.membersList.map((member, index) => (
            <span key={index}>
              {member.image !== '' ? (
                <Avatar
                  alt='Remy Sharp'
                  src={baseUri + member.image}
                  style={{ height: 30, width: 30 }}
                />
              ) : (
                <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                  {member.first_name.substring(0, 1)}
                </Avatar>
              )}
            </span>
          ))}
        </AvatarGroup>
      </Tooltip>
      <MenuPopover open={openMemberListing} onClose={handleClose} anchorEl={anchorRef.current}>
        {getSelectedMember(props.assignMembers).length > 0 ? (
          getSelectedMember(props.assignMembers).map((member, index) => (
            // <ListItem>
            //   <ListItemAvatar>
            //     <Avatar alt="Remy Sharp">{x.name.substring(0, 1)}</Avatar>
            //   </ListItemAvatar>
            //   <ListItemText primary={x.name} />
            // </ListItem>
            <React.Fragment key={index}>
              {member.isSelected === false ? (
                <ListItem
                  onClick={() => handleAdd(member)}
                  style={{ cursor: 'pointer' }}
                  className='list-hov'
                >
                  <ListItemIcon>
                    {member.image !== '' ? (
                      <Avatar
                        alt='Remy Sharp'
                        src={baseUri + member.image}
                        style={{ height: 30, width: 30 }}
                      />
                    ) : (
                      <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                        {member.first_name.substring(0, 1)}
                      </Avatar>
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {member.first_name} {member.last_name}
                  </ListItemText>
                </ListItem>
              ) : (
                <ListItem
                  style={{
                    borderLeft: '2px solid #00AB55',
                    borderRadius: 3,
                    backgroundColor: 'rgba(0, 171, 85, 0.08)',
                    margin: 2,
                  }}
                  secondaryAction={
                    <IconButton edge='end' aria-label='delete' onClick={() => handleDelete(member)}>
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    {member.image !== '' ? (
                      <Avatar
                        alt='Remy Sharp'
                        src={baseUri + member.image}
                        style={{ height: 30, width: 30 }}
                      />
                    ) : (
                      <Avatar alt='Remy Sharp' style={{ height: 30, width: 30 }}>
                        {member.first_name.substring(0, 1)}
                      </Avatar>
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    {member.first_name} {member.last_name}
                  </ListItemText>
                </ListItem>
              )}
            </React.Fragment>
          ))
        ) : (
          <ListItem disabled>
            <ListItemText>
              <Typography style={{ fontSize: '0.7rem' }}>Team members not added</Typography>
            </ListItemText>
          </ListItem>
        )}
      </MenuPopover>
    </>
  );
}

export default MembersListingV2;
