import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  Chip,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// components
import MenuPopover from "../../../components/MenuPopover";
import VerifiedIcon from "src/components/VerifiedIcon";
import { status_color_and_value } from "src/constants";
// ----------------------------------------------------------------------

// const Status = [
//   {
//     value: 0,
//     label: 'Pending',
//     color: 'warning',
//     // icon: '/static/icons/ic_flag_en.svg'
//   },
//   {
//     value: 1,
//     label: 'In Progress',
//     color: 'secondary',
//     // icon: '/static/icons/ic_flag_de.svg'
//   },
//   // {
//   //   value: 2,
//   //   label: 'In Review',
//   //   color: 'warning'
//   //   // icon: '/static/icons/ic_flag_fr.svg'
//   // },
//   {
//     value: 3,
//     label: 'Completed',
//     color: 'primary',
//     // icon: '/static/icons/ic_flag_fr.svg'
//   },
//   {
//     value: 5,
//     label: 'Verified',
//     color: 'success',
//     // icon: '/static/icons/ic_flag_fr.svg'
//   },
//   {
//     value: 4,
//     label: 'Cancelled',
//     color: 'error',
//     // icon: '/static/icons/ic_flag_fr.svg'
//   },
// ];

// ----------------------------------------------------------------------

export default function StatusPopover(props) {
  const anchorRef = useRef(null);
  const [openStatus, setOpenStatus] = useState(false);
  const handleStatusValue = (e, data) => {
    props.handleStatus(data.value, props.id);
    handleClose();
  };

  const handleClose = () => {
    setOpenStatus(false);
  };

  const handleOpenStatus = () => {
    console.log(props.id, "at-open");
    if (props.shouldOpen === false) {
      return;
    }
    if (props.isCreator === true || props.isAssigned === true) {
      setOpenStatus(true);
    }
  };
  const isStatusSelected = (option) => {
    let found = status_color_and_value.find(
      (item) => String(item.value) === String(option.value)
    );
    if (found) {
      if (String(found.value) === String(props.status)) {
        return true;
      }
    }
    return false;
  };
  const getChipColor = (value) => {
    const chip = status_color_and_value.find(
      (chip) => String(chip.value) === String(value)
    );
    if (chip) {
      return chip.color;
    }
    return "#fff";
  };
  const getFilteredStatus = (option) => {
    let can = false;
    if (props.isCreator === true) {
      can = true;
    }
    if (!can) {
      let filtered = status_color_and_value.filter((option) => {
        if (
          String(option.value) !== String(5) &&
          String(option.value) !== String(4)
        ) {
          return option;
        }
      });

      return filtered;
    }
    return status_color_and_value;
  };
  const getChipLabel = (value) => {
    const chip = status_color_and_value.find(
      (chip) => String(chip.value) === String(value)
    );
    if (chip) {
      return chip.label;
    }
    return "";
  };

  return (
    <>
      <Tooltip title={getChipLabel(props.status)}>
        <IconButton
          onClick={handleOpenStatus}
          style={{ backgroundColor: "rgba(0, 171, 85, 0.08)" }}
        >
          {String(props.status) !== String(5) && (
            <CircleIcon
              sx={{ fontSize: "0.7rem" }}
              ref={anchorRef}
              htmlColor={getChipColor(props.status)}
              // color={String(props.status) === 3 ? Status[3].color : getChipColor(props.status)}
            />
          )}
          {String(props.status) === String(5) && (
            <VerifiedIcon forIconButton={true} anchorRef={anchorRef} />
          )}
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={openStatus}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {getFilteredStatus().map((option, i) => (
            <MenuItem
              key={option.value}
              selected={isStatusSelected(option)}
              sx={{ py: 1, px: 2.5 }}
              onClick={(e) => handleStatusValue(e, option)}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                {String(option.value) !== String(5) && (
                  <CircleIcon 
                  htmlColor={option.color} />
                )}
                {String(option.value) === String(5) && (
                  <VerifiedIcon forIconButton={false} />
                )}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "body2", fontSize: "12" }}
              >
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
