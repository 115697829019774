import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import {
  Box,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import Tooltip from "@mui/material/Tooltip";
// components
import MenuPopover from "../../../components/MenuPopover";
import { priority_color_and_value } from "src/constants";

// ----------------------------------------------------------------------

export default function PriorityPopover(props) {
  const anchorRef = useRef(null);
  const [openpriority, setOpenPriority] = useState(false);

  const handleClose = () => {
    setOpenPriority(false);
  };

  const handlePriority = (e, data) => {
    // setTaskPriority(value);
    props.handleTaskPriority(data.value, props.id, props.task);
    handleClose();
  };

  const handleOpenPriority = () => {
    if (props.shouldOpen === false) {
      return;
    }
    if (props.isCreator || props.isAssigned) {
      setOpenPriority(true);
    }
  };

  const getFlagColor = (value) => {
    const option = priority_color_and_value.find(
      (option) => String(option.value) === String(value)
    );

    return option.color;
  };

  const getFlagLabel = (value) => {
    const flag = priority_color_and_value.find(
      (flag) => String(flag.value) === String(value)
    );
    return flag.label;
  };
  const isPrioritySelected = (option) => {
    let found = priority_color_and_value.find(
      (item) => String(item.value) === String(option.value)
    );
    if (found) {
      if (String(found.value) === String(props.priority)) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <Tooltip title={`Priority ${getFlagLabel(props.priority)}`}>
        <IconButton
          style={{
            backgroundColor: "rgba(0, 171, 85, 0.08)",
            marginLeft: ".3rem",
          }}
          ref={anchorRef}
          onClick={handleOpenPriority}
        >
          <FlagIcon
            style={{ fontSize: "0.6em" }}
            htmlColor={getFlagColor(props.priority)}
          />
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={openpriority}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ py: 1 }}>
          {priority_color_and_value.map((option, i) => (
            <MenuItem
              key={option.value}
              selected={isPrioritySelected(option)}
              sx={{ py: 1, px: 2.5 }}
              onClick={(e) => handlePriority(e, option)}
            >
              <ListItemIcon style={{ marginRight: 0 }}>
                <FlagIcon htmlColor={getFlagColor(option.value)} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ variant: "body2", fontSize: "12" }}
              >
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
