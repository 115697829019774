import * as React from "react";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { useSnackbar } from "notistack";

// material
import {
  Container,
  CircularProgress,
  Stack,
  Typography,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import StyledSearch from "../../pages/Tasks/components/search/StyledSearch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { deepPurple } from "@mui/material/colors";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { filter } from "lodash";
// components
import Page from "../../components/Page";
import {
  ProjectSort,
  ProjectList,
  Widget,
  ProjectFilterSidebar,
} from "../../components/_dashboard/projects";
import AddProject from "./AddProject";
//
import PRODUCTS from "../../_mocks_/products";
import {
  projectListing,
  deleteProject,
  deleteProjectTeam,
  activeProjectCategories,
} from "../../DAL/Project/Project";
import ProjectFilter from "../../components/_dashboard/projects/ProjectFilter";
import {
  _get_project_categories_from_localStorage,
  _set_project_categories_in_localStorage,
} from "src/DAL/LocalStorage/LocalStorage";
import { useSocket } from "../../Hooks/socketContext";
// ----------------------------------------------------------------------

// ============================================ styling=========================

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function ProjectListing() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { getUserProfileData } = useSocket();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [opendialog, setOpenDialog] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [allowProject, setAllowProject] = useState(true);
  const [all, setAll] = useState(true);
  const [team, setTeam] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [separated, setSeparated] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const params = useParams();
  const [options, setOptions] = useState({
    all: true,
    team: false,
    personal: false,
  });
  const profile = getUserProfileData();

  const handleFilterButton = (e) => {
    let reset = {
      all: false,
      team: false,
      personal: false,
    };
    setOptions({ ...reset, [e.target.id]: true });
  };

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_project) =>
        _project.project_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const getFilteredProjectListing = (listing, type) => {
    const userId = localStorage.getItem("user_id");
    let projects = [];
    if (type === "all") {
      if (searchValue !== "") {
        console.log(searchValue);
        const filtered = handleLocalTaskSearch(searchValue, data);
        console.log(filtered, "filtered-only");
        // return filtered;
        projects = filtered;
      }
      projects = data;
    }
    if (type === "team") {
      if (searchValue !== "") {
        console.log(searchValue);
        const filtered = handleLocalTaskSearch(
          searchValue,
          separated.Project_list
        );
        console.log(filtered, "filtered-only");
        // return filtered;
        projects = filtered;
      }
      // console.log(team, 'team-only')
      projects = separated.Project_list;
    }
    if (type === "personal") {
      if (searchValue !== "") {
        console.log(searchValue);
        const filtered = handleLocalTaskSearch(
          searchValue,
          separated.team_project_list
        );
        console.log(filtered, "filtered-only");
        // return filtered;
        projects = filtered;
      }
      projects = separated.team_project_list;
    }

    const selected_categories = categoriesList.filter((c) => c.is_checked);

    if (selected_categories.length === 0) {
      if (searchValue !== "") {
        console.log(searchValue);
        const filtered = handleLocalTaskSearch(searchValue, projects);
        console.log(filtered, "filtered-only");
        return filtered;
      }
      return projects;
    }

    if (selected_categories.length > 0) {
      const allowed_projects = [];

      projects.map((project) => {
        categoriesList.map((category) => {
          if (String(project.project_category) === String(category.id)) {
            if (category.is_checked) {
              allowed_projects.push(project);
            }
          }
        });
      });
      if (searchValue !== "") {
        console.log(searchValue);
        const filtered = handleLocalTaskSearch(searchValue, allowed_projects);
        console.log(filtered, "filtered-only");
        return filtered;
      }
      return allowed_projects;
    }

    return projects;
  };
  const handleOpenDialig = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // editProjectTeam apis
  // deleteProjectTeam

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleApplyFilter = () => {
    //
  };

  const handleResetFilter = () => {
    const categories = categoriesList.map((c) => {
      return { ...c, is_checked: false };
    });
    setCategoriesList(categories);
    _set_project_categories_in_localStorage([]);
    setOpenFilter(false);
  };

  const handleChangeInCategories = (id, value) => {
    const categories = categoriesList.map((category) => {
      if (String(category.id) === String(id)) {
        return { ...category, is_checked: value };
      } else {
        return category;
      }
    });

    let selected_categories_id = [];

    categories.forEach((category) => {
      if (category.is_checked) {
        selected_categories_id.push(category.id);
      }
    });

    _set_project_categories_in_localStorage(selected_categories_id);
    setCategoriesList(categories);
  };

  const projectDelete = async (id) => {
    // setData([]);
    setIsDeletingProject(true);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("project_id", id);
    const result = await deleteProjectTeam(formData);
    if (result.code === 200) {
      enqueueSnackbar("Project deleted", { variant: "success" });
      setIsDeletingProject(false);
      fetchListing();
    } else {
      setIsDeletingProject(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const fetchListing = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    if (String(params.id) !== "all") {
      formData.append("category_id", params.id);
    }
    console.log(...formData, "listin-req");
    const result = await projectListing(formData);
    console.log(result, "project-listing-result");
    if (result.code === 200) {
      let mergedData = [...result.Project_list, ...result.team_project_list];
      console.log(mergedData, "merged");
      setSeparated(result);
      setData(mergedData);
      setIswaiting(false);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleAfterSort = (sortedItems) => {
    console.log(sortedItems, "sorted-projects");
    // getFilteredProjectListing(
    //   data,
    //   options.all
    //     ? "all"
    //     : options.team
    //     ? "team"
    //     : options.personal
    //     ? "personal"
    //     : "all"
    // );
    setData(sortedItems);
  };
  const fetchActiveProjectCategory = async () => {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await activeProjectCategories(formData);
    if (result.code === 200) {
      let selected_categories = _get_project_categories_from_localStorage();

      selected_categories = selected_categories ? selected_categories : [];

      const categories = result.category_list.map((c) => {
        if (selected_categories.includes(String(c.category_id))) {
          return { ...c, id: c.category_id, is_checked: true };
        }
        return { ...c, id: c.category_id, is_checked: false };
      });
      setCategoriesList(categories);
      fetchListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    fetchActiveProjectCategory();
  }, []);

  React.useEffect(() => {
    if (options.all === true) {
      getFilteredProjectListing(data, "all");
    }
    if (options.team === true) {
      getFilteredProjectListing(data, "team");
    }
    if (options.personal === true) {
      getFilteredProjectListing(data, "personal");
    }
  }, [options]);
  if (iswaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <Page title="Projects | Support Task Portal">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" mb={5}>
          <div className="mr-2">
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <Typography variant="h4">{location.state?.catagory_name}</Typography>
          {profile.allow_project === "1" && (
            <Button
              variant="contained"
              onClick={() => navigate("/projects/add-project")}
              startIcon={<Icon icon={plusFill} />}
              className="ml-auto"
            >
              New Project
            </Button>
          )}
        </Stack>

        <Stack
          mb={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="d-flex">
            <div>
              <Button
                id="all"
                onClick={handleFilterButton}
                sx={{ marginRight: 1 }}
                color="inherit"
                variant={options.all ? "contained" : "outlined"}
                className={options.all && "filterBtnActive"}
              >
                All
              </Button>
            </div>
            <div>
              <Button
                id="team"
                onClick={handleFilterButton}
                sx={{ marginRight: 1 }}
                color="inherit"
                variant={options.team ? "contained" : "outlined"}
                className={options.team && "filterBtnActive"}
              >
                Team
              </Button>
            </div>
            <div>
              <Button
                id="personal"
                onClick={handleFilterButton}
                color="inherit"
                variant={options.personal ? "contained" : "outlined"}
                className={options.personal && "filterBtnActive"}
              >
                Personal
              </Button>
            </div>
          </div>
          {/* 
          <ProjectFilter
            categoryList={categoriesList}
            handleChangeInCategories={handleChangeInCategories}
            onCloseFilter={handleCloseFilter}
            onOpenFilter={handleOpenFilter}
            isOpenFilter={openFilter}
            onResetFilter={handleResetFilter}
            applyFilter={handleApplyFilter}
          /> */}
        </Stack>
        <Stack mb={3} direction="row" alignItems="center">
          <div className="d-flex w-100">
            <span>
              <StyledSearch
                value={searchValue}
                onChange={handleChangeSearchValue}
                placeHolder="Search Project..."
              />
            </span>
          </div>
        </Stack>
        <ProjectList
          projects={getFilteredProjectListing(
            data,
            options.all
              ? "all"
              : options.team
              ? "team"
              : options.personal
              ? "personal"
              : "all"
          )}
          afterSort={handleAfterSort}
          dragDisabled={searchValue === ""}
          onDeleteProject={projectDelete}
          isDeletingProject={isDeletingProject}
        />
        {/* <Widget /> */}

        {/* ============================================== Add Project component========================= */}

        <Dialog open={opendialog} onClose={handleCloseDialog}>
          <IconButton
            onClick={handleCloseDialog}
            style={{ marginLeft: "auto" }}
          >
            <HighlightOffIcon />
          </IconButton>
          <DialogTitle style={{ textAlign: "center" }}>Add Project</DialogTitle>
          <DialogContent>
            <AddProject />
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleCloseDialog}>Cancel</Button> */}
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
