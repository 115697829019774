import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import {
  Container,
  Stack,
  Typography,
  Button,
  Grid,
  Avatar,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import DatePicker from "react-datepicker";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FullCalender from "./FullCalender";
import {
  addTaskTargetDate,
  changePriority,
  changeStatus,
  detailTaskFiles,
  getUserDashboardTasks,
} from "src/DAL/Project/Project";
import CircleIcon from "@mui/icons-material/Circle";
import FlagIcon from "@mui/icons-material/Flag";
import { useSnackbar } from "notistack";
import { getLocaleDate } from "src/utils";
import { MuiDrawer, TaskDateTimePicker, TaskTimePicker } from "src/components";
import {
  priority_color_and_value,
  status_color_and_value,
} from "src/constants";
import StatusPopover from "../Tasks/components/Status";
import PriorityPopover from "../Tasks/components/Priority";
import { baseUri, s3baseUrl } from "src/config/config";
import moment from "moment";
import { useSocket } from "src/Hooks/socketContext";
import { make_clickable_url_by_className } from "src/utils/DomUtils";
import { Icon } from "@iconify/react";

/* <<--------------------------------------------------------->> */
const CalenderTasks = () => {
  const [loadingEvents, setLoadingEvents] = useState(true);
  const { sendSocketNotification } = useSocket();
  const [calenderEvents, setCalenderEvents] = useState([]);
  const [taskFiles, setTaskFiles] = useState([]);
  const [eventDetailOpen, setEventDetailOpen] = useState(false);
  const [loadingTaskFiles, setLoadingTaskFiles] = useState(false);
  const [selectedEventData, setSelectedEventData] = useState(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  /* <<--------------------------------------------------------->> */
  const getTaskFiles = async (data) => {
    console.log(data, "dota");
    const formData = new FormData(); // formdata object
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", data.task_id);
    const result = await detailTaskFiles(formData);
    if (result.code === 200) {
      setLoadingTaskFiles(false);
      setTaskFiles(result.task_files);
    } else {
      setLoadingTaskFiles(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleNavToProject = (eventData) => {
    if (!eventData) {
      return;
    }
    const { task_id, project_id } = eventData;
    // navigate(`/project/${project_id}/${task_id}`);
    navigate(`/projects/project/${project_id}`, { state: eventData });
  };
  const handleTaskStatus = async (value, taskId) => {
    console.log(value, taskId);
    if (!selectedEventData) {
      return;
    }
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("status", value);
    formData.append("task_id", taskId);
    console.log(...formData, "status-req");
    const updatedTask = (detail) => {
      return { ...detail, task_status: value };
    };
    let updated_events = Array.from(calenderEvents);
    updated_events = updated_events.map((item) => {
      if (String(item.more.task_id) === String(taskId)) {
        return { ...item, more: updatedTask(item.more) };
      }
      return item;
    });
    const result = await changeStatus(formData);
    if (result.code === 200) {
      setCalenderEvents(updated_events);
      setSelectedEventData((prev) => {
        return { ...prev, task_status: value };
      });
      enqueueSnackbar("Task status updated", { variant: "success" });
      getDashboardTasks();
      const payload = {
        receiver_id: selectedEventData.project_manager_id,
        message: "Your Task Status has been Changed",
        project_id: selectedEventData.project_id,
        task_id: selectedEventData.task_id,
        project_name: selectedEventData.project_name,
        notification_type: "task",
      };
      console.log(payload, "notification-payload");
      sendSocketNotification(payload);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleTaskPriority = async (value, id, task) => {
    console.log(value, id, task);
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_priority", value);
    formData.append("task_id", id);
    console.log(...formData, "priority-req");
    const updatedTask = (detail) => {
      return { ...detail, task_priority: value };
    };
    let updated_events = Array.from(calenderEvents);
    updated_events = updated_events.map((item) => {
      if (String(item.more.task_id) === String(id)) {
        return { ...item, more: updatedTask(item.more) };
      }
      return item;
    });

    const result = await changePriority(formData);
    if (result.code === 200) {
      setCalenderEvents(updated_events);
      setSelectedEventData((prev) => {
        return { ...prev, task_priority: value };
      });
      enqueueSnackbar("Task priority updated", { variant: "success" });
      getDashboardTasks();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const onTargetDateTimeChange = (new_date_time, taskId) => {
    console.log(new_date_time, "new-date");

    handleTargetDate(new_date_time, taskId);
  };
  const handleTargetDate = async (date, taskId) => {
    console.log(date, taskId);
    // const target_date = moment(date).format("YYYY-MM-DD");
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", taskId);
    formData.append("target_date", date);
    const updatedTask = (detail) => {
      return { ...detail, target_date: date };
    };
    let updated_events = Array.from(calenderEvents);
    updated_events = updated_events.map((item) => {
      if (String(item.more.task_id) === String(taskId)) {
        return { ...item, more: updatedTask(item.more) };
      }
      return item;
    });
    const result = await addTaskTargetDate(formData);
    if (result.code === 200) {
      enqueueSnackbar("Task target date updated", { variant: "success" });
      getDashboardTasks();
      setCalenderEvents(updated_events);
      setSelectedEventData((prev) => {
        return { ...prev, target_date: date };
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getStatusProps = (value) => {
    if (!value) {
      return "";
    }

    let found = status_color_and_value.find(
      (item) => String(item.value) === String(value)
    );
    if (found) {
      return found;
    }
  };
  const getPriorityProps = (value) => {
    if (!value) {
      return "";
    }

    let found = priority_color_and_value.find(
      (item) => String(item.value) === String(value)
    );
    if (found) {
      return found;
    }
  };
  const openEventDetail = (data) => {
    setEventDetailOpen(true);
    setLoadingTaskFiles(true);
    getTaskFiles(data);
    setTimeout(() => {
      make_clickable_url_by_className("dashboard-task-description");
    }, 400);
  };
  const closeEventDetail = () => {
    setSelectedEventData(undefined);
    setEventDetailOpen(false);
  };
  const handleEventClick = (info) => {
    console.log(info);
    const { event, jsEvent, el, view } = info;
    const { _def } = event;
    console.log(_def, "_def");
    setSelectedEventData(_def.extendedProps.more);
    openEventDetail(_def.extendedProps.more);
  };
  const isDateIsoWithTime = (date) => {
    if (!date) {
      return false;
    }
    const valid_iso_with_time = date.includes("T") && date.includes("Z");
    return valid_iso_with_time;
  };
  const getDashboardTasks = async () => {
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    const result = await getUserDashboardTasks(formData);
    console.log(result, "result");
    if (result.code === 200) {
      const { celender_task_array } = result;
      let custom_events = [];
      celender_task_array.map((task) => {
        custom_events.push({
          title: task.task_name,
          start: task.target_date,
          more: task,
        });
      });

      console.log(custom_events, "custom_events");
      setCalenderEvents(custom_events);
      setLoadingEvents(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  /* <<--------------------------------------------------------->> */
  useEffect(() => {
    getDashboardTasks();
  }, []);
  /* <<--------------------------------------------------------->> */
  return (
    <Page title="All Tasks | Support Task Portal">
      <Container maxWidth="xl" style={{ backgroundColor: "#fff" }}>
        <Stack direction="row" alignItems="center" mb={3}>
          <Typography variant="h4">Calender </Typography>
        </Stack>
        <FullCalender
          loading={loadingEvents}
          events={calenderEvents}
          onEventClick={handleEventClick}
        />
      </Container>
      <MuiDrawer
        isOpen={eventDetailOpen}
        onClose={closeEventDetail}
        title={"Event Details"}
      >
        <Stack spacing={1}>
          {selectedEventData && (
            <>
              <Grid container direction="row" columnGap={1}>
                <Grid item xs>
                  <Typography fontWeight="bold">
                    {selectedEventData.task_name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" rowSpacing={{ xs: 2, sm: 0 }}>
                <Grid item sx={{ display: "flex" }} xs={6} sm={4}>
                  <Stack direction="row" alignItems="center">
                    <div>{/* <Typography>Date </Typography> */}</div>
                    <div>
                      <>
                        <span>
                          <TaskDateTimePicker
                            value={selectedEventData.target_date}
                            onChange={(new_date) =>
                              onTargetDateTimeChange(
                                new_date,
                                selectedEventData.task_id
                              )
                            }
                            task={selectedEventData}
                          />
                        </span>
                        {/* {selectedEventData.target_date === "" ? (
                          <DatePicker
                            // dateFormat="YYYY-MM-DD"
                            value={selectedEventData.target_date}
                            selected={selectedEventData.target_date}
                            onSelect={(date) =>
                              handleTargetDate(date, selectedEventData.task_id)
                            }
                            minDate={new Date()}
                            targetTask={selectedEventData}
                            customInput={
                              <ExampleCustomInput
                                targetTask={selectedEventData}
                              />
                            }
                          />
                        ) : (
                          <DatePicker
                            // dateFormat="YYYY-MM-DD"
                            value={selectedEventData.target_date}
                            selected={new Date(selectedEventData.target_date)}
                            onSelect={(date) =>
                              handleTargetDate(date, selectedEventData.task_id)
                            }
                            minDate={new Date()}
                            targetTask={selectedEventData}
                            customInput={
                              <ExampleCustomInput
                                targetTask={selectedEventData}
                              />
                            }
                          />
                        )} */}
                      </>
                    </div>
                  </Stack>
                </Grid>
                <Grid item sx={{ display: "flex" }} xs={6} sm={2}>
                  <Tooltip title="created by">
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <div>{/* <Typography>Creator</Typography> */}</div>
                      {selectedEventData.image ? (
                        <Avatar
                          sx={{ height: 30, width: 30 }}
                          src={baseUri + selectedEventData.image}
                        />
                      ) : (
                        <Avatar sx={{ height: 30, width: 30 }}>
                          {selectedEventData.first_name.slice(0, 2)}
                        </Avatar>
                      )}
                    </Stack>
                  </Tooltip>
                </Grid>
                <Grid item sx={{ display: "flex" }} xs={6} sm={2}>
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <div>{/* <Typography>Status</Typography> */}</div>
                    <StatusPopover
                      isAssigned={true}
                      isCreator={
                        localStorage.getItem("user_id") ===
                        selectedEventData.task_creator_id
                      }
                      status={selectedEventData.task_status}
                      id={selectedEventData.task_id}
                      handleStatus={handleTaskStatus}
                      onStatusChecked={() => {}}
                      shouldOpen={true}
                    />
                  </Stack>
                </Grid>
                <Grid item sx={{ display: "flex" }} xs={6} sm={2}>
                  <Stack alignItems="center" direction="row" spacing={1}>
                    <div>{/* <Typography>Priority</Typography> */}</div>
                    <PriorityPopover
                      isAssigned={true}
                      isCreator={
                        localStorage.getItem("user_id") ===
                        selectedEventData.task_creator_id
                      }
                      priority={selectedEventData.task_priority}
                      id={selectedEventData.task_id}
                      handleTaskPriority={handleTaskPriority}
                      shouldOpen={true}
                      task={selectedEventData}
                    />
                  </Stack>
                </Grid>
              </Grid>
              {selectedEventData.end_time && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography fontWeight="bold">End time :</Typography>
                    <Typography>
                      {moment(selectedEventData.end_time).format("LT")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">Description :</Typography>
                  {!selectedEventData.task_distribution && (
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.text.disabled,
                      }}
                    >
                      No description added
                    </Typography>
                  )}
                  <div
                    className="dashboard-task-description"
                    dangerouslySetInnerHTML={{
                      __html: selectedEventData.task_distribution,
                    }}
                  ></div>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">Project :</Typography>

                  <Typography>{selectedEventData.project_name}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">Task files :</Typography>
                  {!loadingTaskFiles && (
                    <>
                      {taskFiles.length === 0 && (
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.text.disabled,
                          }}
                        >
                          No files added.
                        </Typography>
                      )}
                    </>
                  )}
                  {loadingTaskFiles ? (
                    <CircularProgress size="1.3rem" />
                  ) : (
                    <div className="row">
                      {taskFiles &&
                        taskFiles.map((x, index) => (
                          <div key={index} className="col-2 mt-1">
                            <a
                              href={baseUri + x.task_image}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {String(x.file_type) === "0" ? (
                                <img
                                  src={baseUri + x.task_image}
                                  alt="task images"
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 5,
                                    cursor: "pointer",
                                    backgroundSize: "cover",
                                  }}
                                />
                              ) : (
                                <div>
                                  <Tooltip
                                    arrow
                                    title={x.file_name ? x.file_name : ""}
                                  >
                                    <Icon
                                      width="50px"
                                      height="50px"
                                      icon="bxs:file-pdf"
                                    />
                                  </Tooltip>
                                </div>
                              )}
                            </a>
                          </div>
                        ))}
                    </div>
                  )}
                </Grid>
              </Grid>
              <div className="row justify-content-center mt-5">
                <Button
                  onClick={() => handleNavToProject(selectedEventData)}
                  variant="contained"
                >
                  Go to task detail
                </Button>
              </div>
            </>
          )}
        </Stack>
      </MuiDrawer>
    </Page>
  );
};

export default CalenderTasks;
const getDateColor = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return "rgb(0, 171, 85)";
    }

    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return false;
    }
    return true;
  }
  return false;
};
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => {
    return (
      <Tooltip title="Target Date">
        {value !== "" ? (
          <>
            <a
              role="button"
              tabIndex="0"
              className="btn theme-text-color"
              onClick={onClick}
              onKeyDown={onClick}
              ref={ref}
              style={{
                textDecorationLine: "underline",
                fontSize: 12,
                padding: 0,
                color: getDateColor(value, targetTask.status),
              }}
            >
              {moment(value).format("DD MMM, YYYY")}
            </a>
            {showDangerIcon(value, targetTask.status) && (
              <>
                <PriorityHighIcon
                  htmlColor="red"
                  style={{ fontSize: "1rem" }}
                />
              </>
            )}
          </>
        ) : (
          <a
            role="button"
            tabIndex="0"
            className="btn theme-text-color"
            onClick={onClick}
            onKeyDown={onClick}
            ref={ref}
            style={{
              textDecorationLine: "underline",
              fontSize: 12,
              padding: 0,
            }}
          >
            Select Date
          </a>
        )}
      </Tooltip>
    );
  }
);
