import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Badge,
  Chip,
} from '@mui/material';
import Scrollbar from '../../../components/Scrollbar';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
//

// ----------------------------------------------------------------------

export default function AllTasksFilter({
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  categoryList,
  handleChangeInFilter,
  status_array,
  priority_array,
  // formik
}) {
  //   const [priorityArray, setPriorityArray] = useState(priority_array);
  //   const [statusArray, setStatusArray] = useState(status_array);
  const handleChange = (option, checked, targetIndex, targetArray, type) => {
    handleChangeInFilter(option, checked, targetIndex, targetArray, type);
  };
  //   const handleChangeInFilter = (option, checked, targetIndex, targetArray, type) => {
  //     console.log(option, checked, targetIndex, 'change');
  //     targetArray.map((item, index) => {
  //       if (index === targetIndex) {
  //         if (type === 'status') {
  //           let data = [...targetArray];
  //           data[index] = { ...data[index], isChecked: checked };
  //           setStatusArray(data);
  //         }
  //         if (type === 'priority') {
  //           let data = [...targetArray];
  //           data[index] = { ...data[index], isChecked: checked };
  //           setPriorityArray(data);
  //         }
  //       }
  //     });
  //   };
  return (
    <>
      <div>
        {status_array.map((category, index) => (
          <React.Fragment key={index}>
            {category.isChecked === true && (
              <Chip
                className='text-capitalize mr-1'
                label={category.label}
                color='info'
                style={{ height: 20 }}
              />
            )}
          </React.Fragment>
        ))}
        {priority_array.map((category, index) => (
          <React.Fragment key={index}>
            {category.isChecked === true && (
              <Chip
                className='text-capitalize mr-1'
                label={category.label}
                color='info'
                style={{ height: 20 }}
              />
            )}
          </React.Fragment>
        ))}
        <Button
          disableRipple
          color='inherit'
          endIcon={<Icon icon={roundFilterList} />}
          onClick={onOpenFilter}
          sx={{ backgroundColor: '#f6f7f8', fontWeight: '600' }}
        >
          Filters&nbsp;
        </Button>
      </div>

      {/* <FormikProvider value={formik}> */}
      {/* <Form autoComplete="off" noValidate> */}
      <Drawer
        anchor='right'
        open={isOpenFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant='subtitle1' sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                Status:
              </Typography>
              <FormGroup>
                {status_array.map((item, index) => (
                  <FormControlLabel
                    checked={item.isChecked}
                    onChange={(e) => {
                      handleChange(item.value, e.target.checked, index, status_array, 'status');
                    }}
                    className='text-capitalize'
                    key={index}
                    control={<Checkbox />}
                    label={item.label}
                  />
                ))}
                {/* {PRIORITY_OPTIONS.map((item) => (
                  <FormControlLabel
                    checked={isFilterData[item.value]}
                    key={item}
                    control={
                      <Checkbox
                        // {...getFieldProps('status')}
                        // value={true}
                        // checked={}
                        onChange={(e) => handleChangeStatus(e.target.checked, item.value)}
                      />
                    }
                    label={item.label}
                  />
                ))} */}
              </FormGroup>
              <Typography variant='subtitle1' gutterBottom>
                Priority:
              </Typography>
              <FormGroup>
                {priority_array.map((item, index) => (
                  <FormControlLabel
                    checked={item.isChecked}
                    onChange={(e) => {
                      handleChange(item.value, e.target.checked, index, priority_array, 'priority');
                    }}
                    className='text-capitalize'
                    key={index}
                    control={<Checkbox />}
                    label={item.label}
                  />
                ))}
                {/* {PRIORITY_OPTIONS.map((item) => (
                  <FormControlLabel
                    checked={isFilterData[item.value]}
                    key={item}
                    control={
                      <Checkbox
                        // {...getFieldProps('status')}
                        // value={true}
                        // checked={}
                        onChange={(e) => handleChangeStatus(e.target.checked, item.value)}
                      />
                    }
                    label={item.label}
                  />
                ))} */}
              </FormGroup>
            </div>
          </Stack>
        </Scrollbar>

        <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 1 }}>
          <Button
            fullWidth
            size='large'
            type='submit'
            color='inherit'
            variant='outlined'
            onClick={onResetFilter}
            startIcon={<Icon icon={roundClearAll} />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
      {/* </Form> */}
      {/* </FormikProvider> */}
    </>
  );
}
