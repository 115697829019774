import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
export default function SaleSectionMenu({
  isOpen,
  onClose,
  anchorEl,
  handleOpenDialog,
  sections,
  handleMoveSection,
  closeParentPopover,
  selectedRow,
}) {
  // console.log(selectedRow, 'selected');
  const handleClose = () => {
    onClose();
  };
  const handleMove = (section) => {
    handleMoveSection(section);
    handleClose();
    closeParentPopover();
  };
  const truncateText = (string, maxLength) => {
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(" " + "...");
      return result;
    }
    return string;
  };
  const getFilteredSections = () => {
    let filtered = [];
    if (selectedRow) {
      sections.map((section) => {
        if (String(section.section_id) !== String(selectedRow.section_id)) {
          filtered.push(section);
        }
      });
      return filtered;
    }
    return sections;
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{ sx: { marginLeft: "-0.5rem", marginTop: "1rem" } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <List sx={{ maxHeight: "25rem" }}>
          {getFilteredSections().length > 0 ? (
            getFilteredSections().map((section, index) => {
              return (
                <ListItem key={index} sx={{ padding: ".2rem" }}>
                  <ListItemButton onClick={() => handleMove(section)}>
                    <ListItemText>
                      <Typography sx={{ fontSize: "0.8rem" }}>
                        {truncateText(section.section_name, 30)}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <ListItem disabled sx={{ padding: ".2rem" }}>
              <ListItemButton disabled>
                <ListItemText>
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    No sections yet
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
}
