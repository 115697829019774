import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import { string } from 'yup';
import moment from 'moment';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  //   { value: 'default', label: 'Default', type: 'default' },
  { value: 'newest', label: 'Newest First', type: 'date' },
  { value: 'oldest', label: 'Oldest First', type: 'date' },
  { value: 'due-date', label: 'Due Date', type: 'date' },
  { value: 'priorityDesc', label: 'Priority: High-Low', type: 'priority' },
  { value: 'priorityAsc', label: 'Priority: Low-High', type: 'priority' },
];
const priority = [
  {
    value: 0,
    label: 'Critical',
    color: 'error',
    // icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 1,
    label: 'High',
    color: 'primary',
    // icon: '/static/icons/ic_flag_de.svg'
  },
  {
    value: 2,
    label: 'Medium',
    color: 'success',
    // icon: '/static/icons/ic_flag_fr.svg'
  },
  {
    value: 3,
    label: 'Low',
    color: 'info',
    // icon: '/static/icons/ic_flag_fr.svg'
  },
];
export default function AllTasksSort({ data, selectedIndex, setSelectedIndex, onSortChange }) {
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleSort = (type, option) => {
    if (!data) return [];
    if (!data.length > 0) return [];
    if (type === 'default') {
      return [];
    }
    if (type === 'priority') {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ['intPriority']: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ['intPriority']: t2Priority };
        if (option === 'priorityDesc') {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === 'date') {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === 'oldest') {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ['isDateGreater']: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ['isDateGreater']: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === 'newest') {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ['isDateGreater']: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ['isDateGreater']: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });
      console.log(sorted, 'date sort');
      return sorted;
      //
    }
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = async (event, index) => {
    console.log('isindex', index);
    setSelectedIndex(index);
    setOpen(null);
    onSortChange(SORT_BY_OPTIONS[index].type, SORT_BY_OPTIONS[index].value);
  };

  return (
    <>
      <Button
        color='inherit'
        disableRipple
        onClick={handleOpen}
        sx={{ backgroundColor: '#f6f7f8' }}
        endIcon={<Icon color='#00ab55' icon={open ? chevronUpFill : chevronDownFill} />}
      >
        <Typography component='span' sx={{ color: '#00ab55', fontWeight: '600' }}>
          Sort By :&nbsp;
        </Typography>
        <Typography component='span' variant='subtitle2' sx={{ color: 'text.secondary' }}>
          {SORT_BY_OPTIONS[selectedIndex].label}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option, index) => (
          <MenuItem
            key={option.value}
            // onClick={handleClose}
            sx={{ typography: 'body2' }}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
