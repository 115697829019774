import {
  Container,
  Tooltip,
  Typography,
  IconButton,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Input,
  TextField,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Skeleton,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Page from "src/components/Page";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { filter, uniq } from "lodash";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { baseUri } from "src/config/config";
import StyledSearch from "src/pages/Tasks/components/search/StyledSearch";
import moment from "moment";
import { noTask } from "src/assets";
import {
  Navigate,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Loader, { TaskDateTimePicker, TaskTimePicker } from "src/components/";

import CircleIcon from '@mui/icons-material/Circle';
import { Icon } from "@iconify/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSocket } from "src/Hooks/socketContext";
import { set_blank_target } from "src/utils/DomUtils";
//icons
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CommentIcon from "@mui/icons-material/Comment";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
// import CircleIcon from "@mui/icons-material/Circle";
import PanoramaWideAngleSelectIcon from '@mui/icons-material/PanoramaWideAngleSelect';
import SquareIcon from '@mui/icons-material/Square';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import FeedIcon from '@mui/icons-material/Feed';
import FolderIcon from '@mui/icons-material/Folder';
import HexagonIcon from '@mui/icons-material/Hexagon';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
//components
import PriorityPopover from "src/pages/Tasks/components/Priority";
import StatusPopover from "src/pages/Tasks/components/Status";
import { SORT_BY_OPTIONS } from "src/constants";
import {
  changeStatus,
  changePriority,
  addTaskMembers,
  deleteTaskMembers,
  addTaskTargetDate,
} from "src/DAL/Project/Project";
import AddMembersV2 from "./AddMembersV2";
import AddMembers from "src/pages/Tasks/components/AddMembers";
import MembersListingV2 from "./MembersListingV2";
import MembersListing from "src/pages/Tasks/components/MembersListing";
import { format, isPast, parseISO } from "date-fns";

const TaskSectionAccordion = ({
  section,
  expandSection,
  fetchTaskListing,
  projectMembers,
  projectDetail,
  setOpenEditTask,
  setIsSectionMenuOpen,
  setSectionMenuAnchorEl,
  searchValue,
  setSearchValue,
  setTaskData,
  taskSortStatus,
  setRowData,
  setIsCommentDrawerOpen,
  setTaskSectionsData,
  data,
  handleOnDragEnd,
  openAccordionMenu,
  accordionIndex,
  selectedIndex,
  taskType,
  isMyTodoOn,
  setOpenPriority,
  setOpenStatus,
  setOpenTotalMemberListing,
  taskSectionsData,
  personal,
}) => {
  //hooks
  const { sendSocketNotification, getUserProfileData } = useSocket();

  const user = getUserProfileData();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let _status = undefined;
  if (String(taskType) === "1") {
    _status = 1;
  }
  if (String(taskType) === "0") {
    _status = 0;
  }
  const handleChange = (data) => {
    if (data) {
      expandSection(data.section_id);
    }
  };
  const openMenu = (e, section) => {
    openAccordionMenu(e, section);
  };

  const shouldStatusOpen = () => {
    let should = true;

    return should;
  };
  //status handling
  const handleStatusChecked = async (status, data) => {
    console.log(status, data, "AT STATUS");
    if (status === true) {
      setTaskSectionsData((sections) =>
        sections.map((section) => {
          return {
            ...section,
            section_tasks: section.section_tasks.map((task) => {
              if (String(task.task_id) === String(id)) {
                // console.log(task, 'add-member');
                data = task;
                return {
                  ...task,
                  status: 3,
                };
              }
              return task;
            }),
          };
        })
      );
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 3);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        fetchTaskListing(0, _status);
        //
        console.log(data, "tgtt");
        if (String(data.visibilityStatus) !== "0") {
          //for admin
          const payload_admin = {
            receiver_id: projectDetail.project_manager_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          console.log(payload_admin, "admin-notification-payload");
          sendSocketNotification(payload_admin);
          //for task creator
          if (
            String(localStorage.getItem("user_id")) !==
            String(data.task_creator_id)
          ) {
            const payload_creator = {
              receiver_id: data.task_creator_id,
              message: "Your Task Status has been Changed",
              project_id: id,
              task_id: data.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload_creator, "creator-notification-payload");
            sendSocketNotification(payload_creator);
          }
          //for team
          data.members.map((member) => {
            if (
              String(localStorage.getItem("user_id")) !== String(member.user_id)
            ) {
              const payload_team = {
                receiver_id: member.user_id,
                message: "Your Task Status has been Changed",
                project_id: id,
                task_id: data.task_id,
                project_name: projectDetail.project_name,
                notification_type: "task",
              };
              console.log(payload_team, "members-notification-payload");
              sendSocketNotification(payload_team);
            }
          });
        }
        enqueueSnackbar("Task Status changed", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        fetchTaskListing(0, _status);
      }
    } else {
      setTaskSectionsData((sections) =>
        sections.map((section) => {
          return {
            ...section,
            section_tasks: section.section_tasks.map((task) => {
              if (String(task.task_id) === String(id)) {
                // console.log(task, 'add-member');
                data = task;
                return {
                  ...task,
                  status: 1,
                };
              }
              return task;
            }),
          };
        })
      );
      console.log(data, "tgtt");
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 1);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        fetchTaskListing(0, _status);
        if (String(data.visibilityStatus) !== "0") {
          //for admin
          const payload_admin = {
            receiver_id: projectDetail.project_manager_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          console.log(payload_admin, "admin-notification-payload");
          sendSocketNotification(payload_admin);
          //for task creator
          if (
            String(localStorage.getItem("user_id")) !==
            String(data.task_creator_id)
          ) {
            const payload_creator = {
              receiver_id: data.task_creator_id,
              message: "Your Task Status has been Changed",
              project_id: id,
              task_id: data.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload_creator, "creator-notification-payload");
            sendSocketNotification(payload_creator);
          }
          //for team
          data.members.map((member) => {
            if (
              String(localStorage.getItem("user_id")) !== String(member.user_id)
            ) {
              const payload_team = {
                receiver_id: member.user_id,
                message: "Your Task Status has been Changed",
                project_id: id,
                task_id: data.task_id,
                project_name: projectDetail.project_name,
                notification_type: "task",
              };
              console.log(payload_team, "members-notification-payload");
              sendSocketNotification(payload_team);
            }
          });
        }
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        fetchTaskListing(0, _status);
      }
    }
  };
  const handleTaskStatus = async (value, data) => {
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(id)) {
              // console.log(task, 'add-member');
              return {
                ...task,
                status: value,
              };
            }
            return task;
          }),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("status", value);
    formData.append("task_id", data.task_id);

    const result = await changeStatus(formData);
    if (result.code === 200) {
      console.log("refetching-tasks");
      console.log(data, "task-data");
      fetchTaskListing(0, _status);
      if (String(data.visibilityStatus) !== "0") {
        //for admin
        const payload_admin = {
          receiver_id: projectDetail.project_manager_id,
          message: "Your Task Status has been Changed",
          project_id: id,
          task_id: data.task_id,
          project_name: projectDetail.project_name,
          notification_type: "task",
        };
        console.log(payload_admin, "admin-notification-payload");
        sendSocketNotification(payload_admin);
        //for task creator
        if (
          String(localStorage.getItem("user_id")) !==
          String(data.task_creator_id)
        ) {
          const payload_creator = {
            receiver_id: data.task_creator_id,
            message: "Your Task Status has been Changed",
            project_id: id,
            task_id: data.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          console.log(payload_creator, "creator-notification-payload");
          sendSocketNotification(payload_creator);
        }
        //for team
        data.members.map((member) => {
          if (
            String(localStorage.getItem("user_id")) !== String(member.user_id)
          ) {
            const payload_team = {
              receiver_id: member.user_id,
              message: "Your Task Status has been Changed",
              project_id: id,
              task_id: data.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload_team, "members-notification-payload");
            sendSocketNotification(payload_team);
          }
        });
      }
      if (String(value) === "5") {
        enqueueSnackbar("Task moved to verified listing", { variant: "info" });
      }
      if (String(value) !== "5") {
        enqueueSnackbar("Task status changed", { variant: "success" });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing(0, _status);
    }
  };
  const handleTaskClick = (task) => {
    handleOpenEditTask(task);
  };
  const navigateToVerifiedListing = (taskId) => {
    navigate(`/verified-tasks`, {
      state: { project_id: id, project_detail: projectDetail, task_id: taskId },
    });
  };
  const navigateToProject = (taskId) => {
    console.log(location.state, "state");
    if (location.state) {
      if (location.state.project_id) {
        navigate(`/project/${location.state.project_id}`, {
          state: {
            project_id: location.state.project_id,
            project_detail: projectDetail,
            task_id: taskId,
          },
        });
      }
    } else {
      navigate(`/project/${id}`, {
        state: {
          project_id: id,
          project_detail: projectDetail,
          task_id: taskId,
        },
      });
    }
  };
  const handleCloseMemberSelect = (id) => {
    setOpenStatus(false);
    setOpenPriority(false);
    setOpenTotalMemberListing(false);
    setTaskSectionsData((section) =>
      section.section_tasks.map((task) => {
        if (String(task.id) === String(id)) {
          return {
            ...task,
            openMemberSelect: false,
            openMemberListing: false,
          };
        }
        return task;
      })
    );
  };
  const handleOpenMemberSelect = (value, id) => {
    setTaskSectionsData((section) => {
      section.section_tasks.map((task) => {
        if (String(task.id) === String(id)) {
          return {
            ...task,
            openMemberSelect: value,
          };
        }
        return task;
      });
    });
  };
  const handleOpenCommentDrawer = (data) => {
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  const showCustomSnackbar = (taskId, message) => {
    const action = (key) => (
      <>
        <Button
          onClick={() => {
            // console.log(`Moved task id is ${taskId}`);
            navigateToProject(taskId);
          }}
          style={{ color: "white" }}
        >
          View
        </Button>
      </>
    );
    enqueueSnackbar(message, {
      variant: "info",
      autoHideDuration: 3000,
      action: action,
    });
  };
  //priority handling
  const handlePriority = async (value, _id) => {
    let target_task = null;
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(_id)) {
              // console.log(task, 'add-member');
              target_task = task;
              return {
                ...task,
                priority: value,
              };
            }
            return task;
          }),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_priority", value);
    formData.append("task_id", _id);
    console.log(target_task, "tgt");
    const result = await changePriority(formData);
    if (result.code === 200) {
      // fetchTaskListing();
      enqueueSnackbar("Task priority changed", { variant: "success" });
      fetchTaskListing(0, _status);
      if (String(target_task.visibilityStatus) !== "0") {
        //for admin
        // const payload_admin = {
        //   receiver_id: projectDetail.project_manager_id,
        //   message: 'Your Task Priority has been Changed',
        //   project_id: id,
        //   task_id: target_task.task_id,
        //   project_name: projectDetail.project_name,
        // };
        // console.log(payload_admin, 'admin-notification-payload');
        // sendSocketNotification(payload_admin);
        //for task creator
        if (
          String(localStorage.getItem("user_id")) !==
          String(target_task.task_creator_id)
        ) {
          const payload_creator = {
            receiver_id: target_task.task_creator_id,
            message: "Your Task Priority has been Changed",
            project_id: id,
            task_id: target_task.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          console.log(payload_creator, "creator-notification-payload");
          sendSocketNotification(payload_creator);
        }
        //for team
        target_task.members.map((member) => {
          if (
            String(localStorage.getItem("user_id")) !== String(member.user_id)
          ) {
            const payload_team = {
              receiver_id: member.user_id,
              message: "Your Task Priority has been Changed",
              project_id: id,
              task_id: target_task.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload_team, "members-notification-payload");
            sendSocketNotification(payload_team);
          }
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing(0, _status);
    }
  };
  //members handling
  const handleAddMembers = async (value, _id) => {
    let target_task = null;
    let assigner = localStorage.getItem("user_id");
    value = { ...value, ["task_assigner_id"]: assigner };
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", _id);
    console.log(value, "add-mem");
    console.log(taskSectionsData, "b4");
    console.log(...formData, "b4");

    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(_id)) {
              // console.log(task, 'add-member');

              task = { ...task, ["isAssigned"]: true };
              task.members.push(value);
              return task;
            }
            return task;
          }),
        };
      })
    );
    taskSectionsData.map((section) => {
      section.section_tasks.map((task) => {
        if (String(task.task_id) === String(_id)) {
          // console.log(task, 'add-member');
          target_task = task;
        }
      });
    });
    console.log(target_task, "tgts");
    const result = await addTaskMembers(formData);
    if (result.code === 200) {
      console.log(taskSectionsData, "after-add");
      fetchTaskListing(0, _status);
      //
      if (String(target_task.visibilityStatus) !== "0") {
        //for team
        target_task.members.map((member) => {
          if (String(member.user_id) === String(value.user_id)) {
            if (
              String(localStorage.getItem("user_id")) !== String(value.user_id)
            ) {
              const payload_team = {
                receiver_id: member.user_id,
                message: "You are added in another task",
                project_id: id,
                task_id: target_task.task_id,
                project_name: projectDetail.project_name,
                notification_type: "task",
              };
              console.log(payload_team, "members-notification-payload");
              sendSocketNotification(payload_team);
            }
          }
        });
      }
      //
    } else {
      fetchTaskListing(0, _status);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleDeleteMembers = async (value, _id) => {
    let target_task = null;
    let assigned = false;
    console.log(id, "id");
    console.log(taskSectionsData, "task-data");
    console.log(value, "value");
    taskSectionsData.map((section) =>
      section.section_tasks.map((task) => {
        if (String(task.task_id) === String(_id)) {
          target_task = task;
          console.log(task, "found-task");
          if (task.isCreator === true) {
            assigned = true;
          }
          if (task.isAssigned === true) {
            assigned = true;
          }
        }
      })
    );
    console.log(target_task, "tgts");
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("team_member_id", value.user_id);
    formData.append("task_id", _id);
    console.log(...formData, "delete-members-req");
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(_id)) {
              // console.log(task, 'add-member');
              return {
                ...task,
                openMemberSelect: false,
                openMemberListing: false,
              };
            }
            return task;
          }),
        };
      })
    );
    const result = await deleteTaskMembers(formData);
    if (result.code === 200) {
      console.log(taskSectionsData, "after-del");
      fetchTaskListing(0, _status);
      if (String(target_task.visibilityStatus) !== "0") {
        //for team
        target_task.members.map((member) => {
          if (String(member.user_id) === String(value.user_id)) {
            if (
              String(localStorage.getItem("user_id")) !== String(value.user_id)
            ) {
              const payload_team = {
                receiver_id: member.user_id,
                message: "You are removed from one task",
                project_id: id,
                task_id: target_task.task_id,
                project_name: projectDetail.project_name,
                notification_type: "task",
              };
              console.log(payload_team, "members-notification-payload");
              sendSocketNotification(payload_team);
            }
          }
        });
      }
    } else {
      fetchTaskListing(0, _status);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  //handle date
  const handleChangeEndTime = async (_task, new_end_date) => {
    /* disable time if no target date */
    if (!_task.targetDate) {
      console.log("no target date!");
      return;
    }
    console.log(new_end_date, "new_end_date");
    console.log(_task, "tsk");
    let target_task = null;
    console.log(new_end_date, "end-date");
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(_task.task_id)) {
              target_task = task;
              return {
                ...task,
                end_time: new_end_date,
              };
            }
            return task;
          }),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("end_time", new_end_date);
    formData.append("target_date", _task.targetDate);
    formData.append("task_id", _task.task_id);
    console.log(...formData, "time-req");
    const result = await addTaskTargetDate(formData);
    console.log(result, "time-es");
    if (result.code === 200) {
      fetchTaskListing(0, _status);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const onTargetDateTimeChange = (new_date_time, task) => {
    console.log(new_date_time, "new-date");

    handleTargetDate(new_date_time, task.task_id);
  };
  const handleTargetDate = async (date, _id) => {
    let target_task = null;
    setTaskSectionsData((sections) =>
      sections.map((section) => {
        return {
          ...section,
          section_tasks: section.section_tasks.map((task) => {
            if (String(task.task_id) === String(_id)) {
              target_task = task;
              return {
                ...task,
                targetDate: date,
              };
            }
            return task;
          }),
        };
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("task_id", _id);
    formData.append("target_date", date);
    // formData.append("target_date", moment(date).format("YYYY-MM-DD"));
    const result = await addTaskTargetDate(formData);
    if (result.code === 200) {
      fetchTaskListing(0, _status);
      if (String(target_task.visibilityStatus) !== "0") {
        //for admin
        // const payload_admin = {
        //   receiver_id: projectDetail.project_manager_id,
        //   message: 'Your Task Target Date has been Changed',
        //   project_id: id,
        //   task_id: target_task.task_id,
        //   project_name: projectDetail.project_name,
        // };
        // console.log(payload_admin, 'admin-notification-payload');
        // sendSocketNotification(payload_admin);
        //for task creator
        if (
          String(localStorage.getItem("user_id")) !==
          String(target_task.task_creator_id)
        ) {
          const payload_creator = {
            receiver_id: target_task.task_creator_id,
            message: "Your Task Target Date has been Changed",
            project_id: id,
            task_id: target_task.task_id,
            project_name: projectDetail.project_name,
            notification_type: "task",
          };
          console.log(payload_creator, "creator-notification-payload");
          sendSocketNotification(payload_creator);
        }
        //for team
        target_task.members.map((member) => {
          if (
            String(localStorage.getItem("user_id")) !== String(member.user_id)
          ) {
            const payload_team = {
              receiver_id: member.user_id,
              message: "Your Task Target Date has been Changed",
              project_id: id,
              task_id: target_task.task_id,
              project_name: projectDetail.project_name,
              notification_type: "task",
            };
            console.log(payload_team, "members-notification-payload");
            sendSocketNotification(payload_team);
          }
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      fetchTaskListing(0, _status);
    }
  };
  //filtering
  const handleLocalTaskSearch = (input, array) => {
    let filtered = filter(
      array,
      (_task) => _task.task.toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };

  //section handling
  const isSection = (task) => {
    return false;
    let is_section = false;
    if (String(task.taskType) === String(2)) {
      is_section = true;
    }
    return is_section;
  };
  //toggles
  const handleOpenSectionMenu = (e, task, index) => {
    console.log(task, "target-task");
    console.log(index, "target-index");
    setSectionMenuAnchorEl(e.currentTarget);
    setIsSectionMenuOpen(true);
    setRowData(task);
  };
  const handleOpenEditTask = (data) => {
    console.log(data, "handle-edit");
    setRowData(data);
    setOpenEditTask(true);
  };
  //drawers
  const openCommentsDrawer = (data) => {
    console.log(data, "handle-comment-open");
    setRowData(data);
    setIsCommentDrawerOpen(true);
  };
  //sorting
  const handleSort = (type, option, data) => {
    if (type === "priority") {
      let sorted = data.sort((a, b) => {
        let t1Priority = a.priority;
        t1Priority = parseInt(t1Priority);
        a = { ...a, ["intPriority"]: t1Priority };
        let t2Priority = b.priority;
        t2Priority = parseInt(t2Priority);
        b = { ...b, ["intPriority"]: t2Priority };
        if (option === "priorityDesc") {
          return a.intPriority - b.intPriority;
        }
        return b.intPriority - a.intPriority;
      });
      return sorted;
    }
    if (type === "date") {
      let sorted = data.sort((a, b) => {
        let aDate = a.createdDate;
        let bDate = b.createdDate;
        if (option === "oldest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return a.isDateGreater - b.isDateGreater;
        }
        if (option === "newest") {
          let res1 = 0;
          let res2 = 0;
          let dateAResult = moment(aDate).isAfter(bDate);
          if (dateAResult === true) {
            res1 = 1;
          }
          a = { ...a, ["isDateGreater"]: res1 };
          let dateBResult = moment(bDate).isAfter(aDate);
          if (dateBResult === true) {
            res2 = 1;
          }
          b = { ...b, ["isDateGreater"]: res2 };
          return b.isDateGreater - a.isDateGreater;
        }
      });

      return sorted;
      //
    }
  };

  //drag handling
  const getDragTitle = () => {
    let title = "Please remove filters";
    if (String(user.allow_reorder_task) === "0") {
      return "Not allowed";
    }
    let filter = JSON.parse(localStorage.getItem(`project_${id}`))?.filter;
    if (filter) {
      if (filter.length > 0) {
        title = "Please remove filters";
      }
    }
    if (taskSortStatus?.status === true) {
      title = "Please remove filters";
    }
    if (searchValue !== "") {
      title = "Please remove filters";
    }
    if (String(SORT_BY_OPTIONS[selectedIndex].value) !== "0") {
      title = "Please remove filters";
    }
    if (isMyTodoOn) {
      title = "Please remove filters";
    }
    return title;
  };
  const isDragDisabled = () => {
    if (personal) {
      return false;
    }
    if (String(user.allow_reorder_task) === "0") {
      return true;
    }
    let disabled = false;
    let filter = JSON.parse(localStorage.getItem(`project_${id}`))?.filter;
    if (filter) {
      if (filter.length > 0) {
        disabled = true;
      }
    }
    if (taskSortStatus?.status === true) {
      disabled = true;
    }
    if (searchValue !== "") {
      disabled = true;
    }
    if (String(SORT_BY_OPTIONS[selectedIndex].value) !== "0") {
      disabled = true;
    }
    if (isMyTodoOn) {
      disabled = true;
    }
    return disabled;
  };

  //other
  const handleCopyTaskLink = (task) => {
    let newLink = "";
    let project_id = id;
    newLink = `${window.location.origin}/project/${project_id}/${task.task_id}`;
    console.log(newLink);
    navigator.clipboard.writeText(newLink);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: "<p>No Description added!</p>" };
    }

    return { __html: html };
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target("task-description-html");
    }, 500);
  };
  const showNewCommentIndicator = (count) => {
    if (!count) return false;
    let show = false;
    let unread_count = Number(count);
    if (unread_count > 0) {
      return true;
    }
  };
  const getTaskCount = () => {
    return data.length;
  };
  const getPosition = () => {
    let count = getTaskCount();
    if (Number(count) < 10) {
      return "29px";
    }
    if (Number(count) >= 1 && Number(count) <= 10) {
      return "34px";
    }
    if (Number(count) > 10 && Number(count) <= 99) {
      return "34px";
    }
    if (Number(count) > 99 && Number(count) < 1000) {
      return "40px";
    }
    if (Number(count) >= 1000 && Number(count) < 10000) {
      return "48px";
    }
  };
  const getUnreadCount = (count) => {
    let message = "0 unread comments";
    if (Number(count) === 1) {
      message = `${count} unread comment`;
      return message;
    }
    if (Number(count) > 0) {
      message = `${count} unread comments`;
      return message;
    }
    return message;
  };
  const isTimePickerDisabled = (task) => {
    if (!task) {
      return true;
    }
    if (!task.targetDate) {
      return true;
    }
    if (task.isCreator) {
      return false;
    }
    if (task.isAssigned === false) {
      return true;
    }

    return false;
  };
  /* <<--------------------------------------------------------->> */

  return (
    <div
      id="accordion-parent"
      style={{ borderRadius: "4px" }}
      className="border mb-2"
    >
      <Accordion
        square={false}
        expanded={section.expanded}
        onChange={(e) => handleChange(section)}
      >
        <div className="accordion-summary-container position-relative d-flex">
          <AccordionSummary
            style={{ width: "100%", paddingRight: "0px" }}
            expandIcon={
              <ExpandMoreIcon
                style={{ marginRight: "4px", marginLeft: "7px" }}
              />
            }
          >
            <div className="d-flex w-100 align-items-center position-relative">
              {String(section.is_default) === "1" && (
                <div
                  className="position-absolute"
                  style={{ zIndex: "2", right: `${getPosition()}` }}
                >
                  <span
                    style={{
                      background: "rgb(0, 171, 85)",
                      borderRadius: 3,
                      padding: "1px 5px",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                      minWidth: "8px",
                    }}
                  >
                    Default
                  </span>
                  {/* <span className='count-circle'>{getCount()}</span> */}
                </div>
              )}
              <div
                className="position-absolute"
                style={{ zIndex: "2", right: "0" }}
              >
                <span
                  style={{
                    background: "rgb(0, 171, 85)",
                    borderRadius: 3,
                    padding: "1px 5px",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "12px",
                    minWidth: "8px",
                    marginLeft: "12px",
                  }}
                >
                  {getTaskCount()}
                </span>
                {/* <span className='count-circle'>{getCount()}</span> */}
              </div>
              <div className="w-100">
                <Typography
                  className="theme-text-color"
                  sx={{
                    width: "90%",
                    flexShrink: 0,
                    fontWeight: "bold",
                    position: "relative",
                  }}
                >
                  {section.section_name}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <div
            className="position-relative d-flex align-items-center"
            style={{ zIndex: "3" }}
          >
            <div id="sale-more-icon-parent d-flex align-items-center">
              <span
                role="button"
                id="sale-more-icon"
                onClick={(e) => openMenu(e, section)}
              >
                <MoreVertIcon
                  htmlColor="#6d7c89"
                  style={{ marginBottom: "2px", marginRight: "8px" }}
                />
              </span>
            </div>
          </div>
        </div>
        <AccordionDetails>
          {section.expanded ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="row1">
                {(provided) => (
                  <>
                    <div className="col-12">
                      <div
                        className="row mt-3"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {data.length > 0 ? (
                          data.map((task, index) => (
                            <Draggable
                              key={String(task.task_id)}
                              draggableId={String(task.task_id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className="w-100 "
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  // style={{ background: isSection(task.task) && '#3ab469' }}
                                >
                                  <Box
                                    id={task.task_id}
                                    sx={{ px: 5, py: 1, boxShadow: 3 }}
                                    // className='not-drag col-12  not-drag-col-outer pl-1'
                                    className={
                                      snapshot.isDragging
                                        ? "onDrag col-12 pl-1 "
                                        : `not-drag-col-outer col-12 pl-1`
                                    }
                                    style={{
                                      // borderLeft: `3px solid ${getStatusColor(task.status)}`,
                                      borderLeft: `3px solid transparent`,
                                    }}
                                  >
                                    <div role="button" tabIndex="0">
                                      <div
                                        style={{
                                          position: "absolute",
                                          right: 10,
                                          top: "50%",
                                          transform: " translateY(-50%)",
                                          zIndex: 2,
                                        }}
                                      >
                                        {isDragDisabled() ? (
                                          <Tooltip title={getDragTitle()}>
                                            <IconButton
                                              style={{ float: "right" }}
                                            >
                                              <DragIndicatorIcon
                                                style={{ color: "#C4CDD5" }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        ) : (
                                          <IconButton
                                            disabled={false}
                                            {...provided.dragHandleProps}
                                            style={{ float: "right" }}
                                          >
                                            <DragIndicatorIcon />
                                          </IconButton>
                                        )}

                                        <IconButton
                                          onClick={(e) =>
                                            handleOpenSectionMenu(
                                              e,
                                              task,
                                              index
                                            )
                                          }
                                        >
                                          <MoreVertIcon />
                                        </IconButton>
                                      </div>
                                      <div
                                        className="d-flex align-items-center"
                                        style={{ paddingBottom: "5px" }}
                                      >
                                       
                                        {localStorage.getItem("user_id") ===
                                        task.task_creator_id ? (
                                          <Tooltip title={
                                            String(task.status) ===
                                            String(0) ? "Pending" : String(task.status) ===
                                            String(1) ? 'In Progress' : String(task.status) ===
                                            String(2) ? 'In Reviewe' : String(task.status) ===
                                            String(3) ? 'Live Completed' : String(task.status) ===
                                            String(4) ? 'Cancelled' : String(task.status) ===
                                            String(5) ? 'Live Verified' : String(task.status) ===
                                            String(6) ? 'Dev Completed' : String(task.status) ===
                                            String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                                          }>

                                          <Checkbox
                                          style={{color: String(task.status) ===
                                            String(0) ? "#f5c900" : String(task.status) ===
                                            String(1) ? '#000' : String(task.status) ===
                                            String(2) ? '#fff' : String(task.status) ===
                                            String(3) ? '#51d821' : String(task.status) ===
                                            String(4) ? '#960031' : String(task.status) ===
                                            String(5) ? '#EC00FF' : String(task.status) ===
                                            String(6) ? '#3792cb' : String(task.status) ===
                                            String(7) ? '#f96300' : '#8C8B10' }}
                                            icon={<SquareIcon/>} 
                                            checked={
                                              String(task.status) ===
                                                String(3) ||
                                              (String(task.status) ===
                                                String(5) &&
                                                true)
                                            }
                                            onChange={(e) =>
                                              handleStatusChecked(
                                                e.target.checked,
                                                task
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                          </Tooltip>

                                        ) : task.isAssigned === false ? (
                                          <Checkbox
                                            disabled
                                            checked={
                                              String(task.status) ===
                                                String(3) ||
                                              (String(task.status) ===
                                                String(5) &&
                                                true)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        ) : (
                                          <Tooltip title={
                                            String(task.status) ===
                                            String(0) ? "Pending" : String(task.status) ===
                                            String(1) ? 'In Progress' : String(task.status) ===
                                            String(2) ? 'In Reviewe' : String(task.status) ===
                                            String(3) ? 'Live Completed' : String(task.status) ===
                                            String(4) ? 'Cancelled' : String(task.status) ===
                                            String(5) ? 'Live Verified' : String(task.status) ===
                                            String(6) ? 'Dev Completed' : String(task.status) ===
                                            String(7) ? 'Dev Verified' : 'QA Verified(dev)' 
                                          }> 
                                          <Checkbox
                                            style={{color: String(task.status) ===
                                              String(0) ? "#f5c900" : String(task.status) ===
                                              String(1) ? '#000' : String(task.status) ===
                                              String(2) ? '#fff' : String(task.status) ===
                                              String(3) ? '#51d821' : String(task.status) ===
                                              String(4) ? '#960031' : String(task.status) ===
                                              String(5) ? '#EC00FF' : String(task.status) ===
                                              String(6) ? '#3792cb' : String(task.status) ===
                                              String(7) ? '#f96300' : '#8C8B10' }}
                                            icon={<SquareIcon/>} 
                                            checked={
                                              String(task.status) ===
                                                String(3) ||
                                              (String(task.status) ===
                                                String(5) &&
                                                true)
                                            }
                                            onChange={(e) =>
                                              handleStatusChecked(
                                                e.target.checked,
                                                task
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                          </Tooltip>
                                        )}

                                        {localStorage.getItem("user_id") ===
                                        task.task_creator_id ? (
                                          <Tooltip
                                            onOpen={(e) => handleOpenTooltip(e)}
                                            title={
                                              <div id="task-description-tooltip">
                                                <div
                                                  id="task-html-description"
                                                  dangerouslySetInnerHTML={getTooltipHtml(
                                                    task.description
                                                  )}
                                                ></div>
                                              </div>
                                            }
                                            placement="top"
                                            arrow
                                          >
                                            <Typography
                                              color={"#5a5a5a"}
                                              component="span"
                                              className="col-enabled"
                                              onClick={() =>
                                                handleTaskClick(task)
                                              }
                                              sx={{ paddingRight: "4rem" }}
                                            >
                                              {task.task}&nbsp;&nbsp;&nbsp;
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            onOpen={(e) => handleOpenTooltip(e)}
                                            title={
                                              <div id="task-description-tooltip">
                                                <div
                                                  id="task-html-description"
                                                  dangerouslySetInnerHTML={getTooltipHtml(
                                                    task.description
                                                  )}
                                                ></div>
                                              </div>
                                            }
                                            placement="top"
                                            arrow
                                          >
                                            <Typography
                                              component="div"
                                              color={"#5a5a5a"}
                                              className={
                                                task.isAssigned === false
                                                  ? "col-disabled"
                                                  : ""
                                              }
                                              onClick={() =>
                                                handleTaskClick(task)
                                              }
                                              sx={{ paddingRight: "4rem" }}
                                            >
                                              {task.task}&nbsp;&nbsp;&nbsp;
                                            </Typography>
                                          </Tooltip>
                                        )}
                                      </div>

                                      <div className="row pl-2">
                                        {/* ================================================= date picker ===================================== */}
                                        <div className="col-lg-4 col-md-3 col-sm-3 mb-1 mb-sm-0">
                                          {task.isCreator === false &&
                                          task.isAssigned === false ? (
                                            <>
                                              {task.targetDate !== "" ? (
                                                <>
                                                  <a
                                                    style={{
                                                      textDecorationLine:
                                                        "underline",
                                                      fontSize: 12,
                                                      color: isPast(
                                                        parseISO(
                                                          task.targetDate
                                                        )
                                                      )
                                                        ? "red"
                                                        : "rgb(0, 171, 85)",
                                                      padding: 0,
                                                    }}
                                                  >
                                                    {/* unassigned_date_here */}
                                                    {format(
                                                      parseISO(task.targetDate),
                                                      "dd MMM yyyy hh:mm aaa"
                                                    )}
                                                    {isPast(
                                                      parseISO(task.targetDate)
                                                    ) && (
                                                      <PriorityHighIcon
                                                        htmlColor="red"
                                                        style={{
                                                          fontSize: "1rem",
                                                        }}
                                                      />
                                                    )}
                                                  </a>
                                                </>
                                              ) : task.isAssigned ? (
                                                <a
                                                  style={{
                                                    textDecorationLine:
                                                      "underline",
                                                    fontSize: 12,
                                                    padding: 0,
                                                  }}
                                                >
                                                  Select Date
                                                </a>
                                              ) : null}
                                            </>
                                          ) : (
                                            <>
                                              <span>
                                                <TaskDateTimePicker
                                                  value={task.targetDate}
                                                  onChange={(new_date) =>
                                                    onTargetDateTimeChange(
                                                      new_date,
                                                      task
                                                    )
                                                  }
                                                  task={task}
                                                />
                                              </span>
                                              {/* {task.targetDate === "" ? (
                                              <DatePicker
                                                // dateFormat="YYYY-MM-DD"
                                                value={task.targetDate}
                                                selected={task.targetDate}
                                                onSelect={(date) =>
                                                  handleTargetDate(
                                                    date,
                                                    task.task_id
                                                  )
                                                }
                                                minDate={new Date()}
                                                targetTask={task}
                                                customInput={
                                                  <ExampleCustomInput
                                                    targetTask={task}
                                                  />
                                                }
                                              />
                                            ) : (
                                              <DatePicker
                                                // dateFormat="YYYY-MM-DD"
                                                value={task.targetDate}
                                                selected={
                                                  new Date(task.targetDate)
                                                }
                                                onSelect={(date) =>
                                                  handleTargetDate(
                                                    date,
                                                    task.task_id
                                                  )
                                                }
                                                minDate={new Date()}
                                                targetTask={task}
                                                customInput={
                                                  <ExampleCustomInput
                                                    targetTask={task}
                                                  />
                                                }
                                              />
                                            )} */}
                                            </>
                                          )}
                                        </div>
                                        {/*  */}
                                        {/* <div className="col-2 mb-1 mb-sm-0">
                                        <TaskTimePicker
                                          disabled={isTimePickerDisabled(task)}
                                          task={task}
                                          endTime={task.end_time}
                                          onSave={(val) =>
                                            handleChangeEndTime(task, val)
                                          }
                                        />
                                      </div> */}

                                        {/*  */}
                                        <div className="col-lg-4 col-md-3 col-sm-3 mb-1">
                                          <div className="row align-items-center ">
                                            <div className="col d-flex flex-row justify-content-start justify-content-sm-center ">
                                              <div className="mr-2">
                                                <Tooltip
                                                  title={`Created by ${task.taskCreatorName}`}
                                                  placement="bottom"
                                                >
                                                  {task.taskCreatorImage !==
                                                  "" ? (
                                                    <Avatar
                                                      alt="Remy Sharp"
                                                      src={
                                                        baseUri +
                                                        task.taskCreatorImage
                                                      }
                                                      style={{
                                                        height: 30,
                                                        width: 30,
                                                      }}
                                                    />
                                                  ) : (
                                                    <Avatar
                                                      alt="Remy Sharp"
                                                      style={{
                                                        height: 30,
                                                        width: 30,
                                                      }}
                                                    >
                                                      {task.taskCreatorName.substring(
                                                        0,
                                                        1
                                                      )}
                                                    </Avatar>
                                                  )}
                                                </Tooltip>
                                              </div>
                                              <div className="mr-2">
                                                <PriorityPopover
                                                  isCreator={task.isCreator}
                                                  isAssigned={task.isAssigned}
                                                  priority={task.priority}
                                                  id={task.task_id}
                                                  task={task}
                                                  handleTaskPriority={
                                                    handlePriority
                                                  }
                                                  shouldOpen={true}
                                                />
                                              </div>
                                              <div className="mr-4">
                                                <StatusPopover
                                                  isAssigned={task.isAssigned}
                                                  isCreator={task.isCreator}
                                                  status={task.status}
                                                  id={task}
                                                  handleStatus={
                                                    handleTaskStatus
                                                  }
                                                  onStatusChecked={
                                                    handleStatusChecked
                                                  }
                                                  shouldOpen={true}
                                                />
                                              </div>
                                              <div className="d-flex align-items-center">
                                                <div
                                                  className="row align-items-center"
                                                  style={{
                                                    position: "relative",
                                                  }}
                                                >
                                                  <div>
                                                    <Tooltip
                                                      onClick={() =>
                                                        handleOpenCommentDrawer(
                                                          task
                                                        )
                                                      }
                                                      // title='Comments'
                                                      title={getUnreadCount(
                                                        task.unreadComments
                                                      )}
                                                    >
                                                      <CommentIcon
                                                        style={{
                                                          fontSize: "1em",
                                                        }}
                                                        htmlColor="#9f9f9f"
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                  {showNewCommentIndicator(
                                                    task.unreadComments
                                                  ) && (
                                                    <span className="new-comment-indicator">
                                                      <CircleIcon
                                                        htmlColor="red"
                                                        style={{
                                                          fontSize: "0.5rem",
                                                        }}
                                                      />
                                                    </span>
                                                  )}
                                                  <div style={{ zIndex: 2 }}>
                                                    <div
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginRight: ".4rem",
                                                      }}
                                                    >
                                                      <div>
                                                        <Typography
                                                          onClick={() =>
                                                            handleOpenCommentDrawer(
                                                              task
                                                            )
                                                          }
                                                          style={{
                                                            paddingInline:
                                                              ".1rem",
                                                          }}
                                                          color="black"
                                                          sx={{
                                                            cursor: "pointer",
                                                            fontSize: 12,
                                                          }}
                                                        >
                                                          {task.commentsCount}
                                                        </Typography>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {String(
                                                  task.visibilityStatus
                                                ) === String(1) && (
                                                  <div>
                                                    <Tooltip
                                                      onClick={() =>
                                                        handleCopyTaskLink(task)
                                                      }
                                                      title="Copy task link"
                                                    >
                                                      <ContentCopyIcon
                                                        style={{
                                                          marginLeft: "1rem",
                                                          color: "grey",
                                                          fontSize: "0.9rem",
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="col-lg-4 col-md-3 col-sm-3 align-items-flex-start"
                                          style={{ display: "flex" }}
                                        >
                                          {String(task.visibilityStatus) ===
                                            "1" &&
                                            task.isAssigned === true && (
                                              <span>
                                                <AddMembers
                                                  onAddMember={handleAddMembers}
                                                  onDeleteMember={
                                                    handleDeleteMembers
                                                  }
                                                  membersData={projectMembers}
                                                  onId={task.task_id}
                                                  assignMembers={task.members}
                                                  taskData={
                                                    section.section_tasks
                                                  }
                                                />
                                              </span>
                                            )}

                                          <MembersListing
                                            isCreator={task.isCreator}
                                            task={task}
                                            onClickPopover={
                                              handleOpenMemberSelect
                                            }
                                            open={task.openMemberSelect}
                                            onClose={() =>
                                              handleCloseMemberSelect(task.id)
                                            }
                                            onAddMember={handleAddMembers}
                                            onDeleteMember={handleDeleteMembers}
                                            onId={task.task_id}
                                            membersData={projectMembers}
                                            assignMembers={task.members}
                                            membersList={task.members}
                                            taskData={section.section_tasks}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                </div>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <div className="col-12">
                            <img
                              height="124px"
                              width="124px"
                              src={noTask}
                              alt="no task"
                              style={{ margin: "auto" }}
                            />
                            <Typography
                              style={{
                                textAlign: "center",
                                paddingLeft: 50,
                                paddingTop: 10,
                              }}
                            >
                              No Task Found
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>

                    {provided.placeholder}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <Skeleton width={"100%"} />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TaskSectionAccordion;
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => (
    <Tooltip arrow title="Target Date">
      {value !== "" ? (
        <>
          <a
            role="button"
            tabIndex="0"
            className="btn"
            onClick={onClick}
            onKeyDown={onClick}
            ref={ref}
            style={{
              textDecorationLine: "underline",
              fontSize: 12,
              //  color: 'green',
              color: getDateColor(value, targetTask.status),
              padding: 0,
            }}
          >
            {getActualDate(value)}
            {/* {moment(value).format('DD MMM, YYYY')} */}
          </a>
          {showDangerIcon(value, targetTask.status) && (
            <>
              <PriorityHighIcon htmlColor="red" style={{ fontSize: "1rem" }} />
            </>
          )}
        </>
      ) : (
        <a
          role="button"
          tabIndex="0"
          className="btn"
          onClick={onClick}
          onKeyDown={onClick}
          ref={ref}
          style={{
            textDecorationLine: "underline",
            fontSize: 12,
            padding: 0,
            //  color: 'green'
            color: getDateColor(value),
          }}
        >
          Select date
        </a>
      )}
    </Tooltip>
  )
);
const getDateColor = (targetDate, status) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return "rgb(0, 171, 85)";
    }
    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  let actual_target_date = getActual(targetDate);
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(actual_target_date).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return false;
    }
    return true;
  }
  return false;
};
const detectSafariBrowser = () => {
  let is_safari = false;
  if (navigator.vendor === "Apple Computer, Inc.") {
    is_safari = true;
  }
  return is_safari;
};
const getActualDate = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("DD, MM YYYY");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "DD, MM YYYY"
    );

    return setTime;
  }
};
const getActual = (date) => {
  if (!date) return "-";
  let date_safari = date;
  // date_safari = date_safari.replaceAll('-', '/');
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date);
  const difference = serverDate.getTimezoneOffset();
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    ).format("YYYY MM DD");
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m")).format(
      "YYYY MM DD"
    );

    return setTime;
  }
};
