import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DateAdapter from '@mui/lab/AdapterMoment';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import closeFill from '@iconify/icons-eva/close-fill';
import CkEditor from 'src/components/editor/CKEditor';
import Scrollbar from 'src/components/Scrollbar';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  ButtonBase,
  Chip,
  CircularProgress,
  DialogContentText,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { Icon } from '@iconify/react';
import { baseUri } from 'src/config/config';
import StyledChatSearch from './StyledChatSearch';
import { filter } from 'lodash';
import { getChatTeam } from 'src/DAL/chat/chat';

export default function NewMessageSidebar({
  onInitializeMessage,
  isOpen,
  toggle,
  onUserClick,
  isLoading,
}) {
  const [projectTeamList, setProjectTeamList] = React.useState([]);
  const [members, setMembers] = React.useState([]);
  const [startTime, setStartTime] = React.useState('');
  const [endTime, setEndTime] = React.useState('');
  const [message, setMessage] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [Image, setImage] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [selectedUser, setSelectedUser] = React.useState('');
  const handleChangeUser = (event) => {
    setSelectedUser(event.target.value);
  };

  const getFilteredList = (data) => {
    if (!data) {
      return [];
    }
    if (searchValue === '') {
      return data;
    }
    if (searchValue !== '') {
      let filtered = handleLocalChatSearch(searchValue, data);
      return filtered;
    }
  };

  const fetchListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('type', '1');
    const result = await getChatTeam(formData);
    console.log(result, 'team-list');
    if (result.code === 200) {
      setProjectTeamList(result.manager_team_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const handleLocalChatSearch = (input, array) => {
    const get_merged = (item) => {
      return `${item.first_name} ${item.last_name}`;
    };
    let filtered = filter(
      array,
      (item) => get_merged(item).toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    return filtered;
  };
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleClick = (e, item) => {
    onUserClick(e, item);
  };
  React.useEffect(() => {
    if (isOpen) {
      fetchListing();
    }
  }, [isOpen]);

  return (
    <Drawer
      disableEnforceFocus={true}
      anchor='right'
      open={isOpen}
      onClose={toggle}
      PaperProps={{
        sx: { width: { xs: '100%', sm: '390px', md: '390px' }, border: 'none', overflow: 'hidden' },
      }}
    >
      <div style={{ position: 'relative', minHeight: '100%' }}>
        <Stack direction='row' sx={{ px: 1 }}>
          {/* <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 'bold' }}>
            Task Detail
          </Typography> */}
          <IconButton onClick={toggle}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <div className='mt-3 mb-2 col'>
          <Typography fontWeight='bold'>New Message</Typography>
        </div>
        <div className='search-user-list row mt-3 mb-2'>
          <span className='col-12' style={{ paddingLeft: '30px', paddingRight: '30px' }}>
            <StyledChatSearch
              value={searchValue}
              onChange={handleChangeSearchValue}
              placeHolder='Search...'
            />
          </span>
        </div>
        <Stack minHeight='100%' spacing={2} sx={{ p: 2, paddingTop: 0 }}>
          <form style={{ minHeight: '100%' }}>
            <Grid gap={3} container maxWidth='sm' mx='auto' justifyContent='center'>
              <Grid item xs={12}>
                <Scrollbar className='new-msg-sidebar-mobile'>
                  {getFilteredList(projectTeamList).map((item, index) => {
                    return (
                      <ButtonBase
                        key={index}
                        className='team-list-parent my-2 border w-100'
                        disabled={isLoading}
                        onClick={(e) => handleClick(e, item)}
                      >
                        <div className='row'>
                          <div className='col-12'>
                            <div className='d-flex align-items-center'>
                              <div>
                                {item.image !== '' ? (
                                  <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                                    <Avatar
                                      alt='Remy Sharp'
                                      src={baseUri + item.image}
                                      className='chat-avatar'
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip arrow title={`${item.first_name} ${item.last_name}`}>
                                    <Avatar alt='Remy Sharp' className='chat-avatar'>
                                      {item.first_name.substring(0, 1)}
                                    </Avatar>
                                  </Tooltip>
                                )}
                              </div>
                              <div className='ml-2'>
                                <Typography>{item.first_name + ' ' + item.last_name} </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ButtonBase>
                    );
                  })}
                  {getFilteredList(projectTeamList).length === 0 && (
                    <div>
                      <Typography textAlign='center' fontWeight='bold'>
                        No Users Found.
                      </Typography>
                    </div>
                  )}
                  <div className='d-flex align-items-center justify-content-center'>
                    {isLoading && <CircularProgress style={{ width: 22, height: 22 }} />}
                  </div>
                </Scrollbar>
              </Grid>
            </Grid>
            {/* 
            <LoadingButton
              type='submit'
              loading={isLoading}
              // onClick={() => handleUploadFile()}
            >
              Send
            </LoadingButton>
            <Button onClick={handleClose}>Cancel</Button> */}
          </form>
        </Stack>
      </div>
    </Drawer>
  );
}
