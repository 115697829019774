import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Tooltip,
  Typography,
  IconButton,
  Box,
  Grid,
  Collapse,
  Stack,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Iconify, SaleCard } from "..";
import { noTask } from "src/assets";
import { useLocation, useNavigate } from "react-router-dom";

const icon_color = "#66727c";

const SaleSectionUnOptimized = ({
  section,
  query,
  filters,
  onSectionAction,
  onSaleAction,
  sectionSales,
  associates,
}) => {
  const {
    expanded, // this is used to auto expand first sectionSales
    is_default,
    section_id,
    section_name,
    section_order,
    section_sales,
  } = section;
  const [show, setShow] = useState(false); // here to add expand functionality
  const location = useLocation();
  const navigate = useNavigate();
  const sectionWrapperRef = useRef();

  const locationState = location.state;
  console.log(section, "section--");
  // console.log(filters, "filters--");
  /* <<--------------------------------------------------------->> */
  const handleExpand = () => {
    setShow(true);
  };
  const handleToggle = () => {
    setShow((prev) => !prev);
  };
  const handleCollapse = () => {
    setShow(false);
  };
  const handleScroll = () => {
    if (!locationState) {
      return;
    }
    if (locationState.display_section_id) {
      if (
        String(locationState.display_section_id) === String(section.section_id)
      ) {
        const el = sectionWrapperRef.current;
        el.scrollIntoView({
          block: "center",
        });
        el.style.transition = "all .5s linear";
        el.style.outline = "solid 3px rgb(10, 238, 124)";
        setTimeout(() => {
          el.style.outline = "none";
        }, 3000);
        navigate("/all-sales-listing/all", { replace: true, state: null });
      }
    }
  };
  /* <<--------------------------------------------------------->> */
  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 500);
  }, []);
  /* <<--------------------------------------------------------->> */
  return (
    <div ref={sectionWrapperRef} className="section-wrapper">
      <Grid
        alignItems="center"
        container
        className="pointer"
        onClick={handleToggle}
      >
        <Grid item xs={8} sm={9} md={10}>
          <Typography className="theme-text-color sale-section-name">
            {section.section_name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Stack
            spacing={1}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <span className="def-sale-section-count">
              {sectionSales.length}
            </span>
            <div style={{ transform: "translateY(-2px)" }}>
              <Iconify
                style={{ color: icon_color }}
                icon={show ? "ep:arrow-up-bold" : "ep:arrow-down-bold"}
              />
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Collapse in={show}>
        <Stack spacing={2} pt={2}>
          {sectionSales.map((sale) => (
            <SaleCard
              associates={associates}
              onSaleAction={onSaleAction}
              key={sale.id}
              sale={sale}
            />
          ))}
          {sectionSales.length === 0 && (
            <div className="col-12 mt-5">
              <img
                src={noTask}
                alt="no task"
                width="95px"
                style={{ margin: "auto" }}
              />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Sales Found
              </Typography>
            </div>
          )}
        </Stack>
      </Collapse>
    </div>
  );
};
const SaleSection = ({
  section,
  query,
  filters,
  onSectionAction,
  onSaleAction,
  associates,
}) => {
  // console.log(filters, "filters--");
  const sectionSales = useMemo(
    () => handleFilter(),
    [query, filters, associates, section.sectionSales]
  );
  function handleFilter() {
    let filtered = section.section_sales.filter(
      (item) =>
        String(item.sale_title)
          .toLocaleLowerCase()
          .indexOf(String(query).toLocaleLowerCase()) !== -1
    );

    if (String(filters.sorting.value) === "due-date") {
      const getDate = (item) => {
        if (!item.sale_target_date) {
          return "";
        }
        if (!item) {
          return "";
        }
        return item.sale_target_date;
      };
      const sorted = [...filtered].sort((a, b) => {
        if (!a.sale_target_date || b.sale_target_date === "") {
          return 1;
        }
        let targetDateA = getDate(a);
        let targetDateB = getDate(b);
        let result = moment(targetDateA).isAfter(targetDateB);
        if (result === true) {
          return 1;
        }
        if (result === false) {
          return -1;
        }
        return 0;
      });
      filtered = [...sorted];
    }
    if (filters.mySale === true) {
      let mine_only = [...filtered].filter(
        (item) => item.is_sale_assigned === true 
      );
      filtered = [...mine_only];
    }

    if (filters.selectedProducts.length > 0) {
      let matched = [];

    //   filters.selectedProducts.forEach((prod) => {
    //     let match = filtered.find(
    //       (item) => String(item.product_id) === String(prod.product_id)
    //     );
    //     if (match) {
    //       matched.push(match);
    //     }
    //   });

    //   const items_set = new Set([...matched]);
    //   const unique = [...items_set];
    //   filtered = [...unique];
    // }

        filters.selectedProducts.forEach((prod) => {
          let match = filtered.filter(
            (item) => String(item.product_id) === String(prod.product_id)
          );
          if (match.length != 0) {
            matched = [...matched, ...match];
          }
        });
        filtered = [...matched];
      }

    return filtered;
  }
  const optimized = useMemo(
    () => (
      <SaleSectionUnOptimized
        section={section}
        query={query}
        filters={filters}
        onSectionAction={onSectionAction}
        onSaleAction={onSaleAction}
        associates={associates}
        sectionSales={sectionSales}
      />
    ),
    [section, associates, sectionSales]
  );
  /* <<--------------------------------------------------------->> */
  /* <<--------------------------------------------------------->> */
  return optimized;
};

export default SaleSection;
