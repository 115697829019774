import React from "react";
import { useEffect } from "react";
// material
import {
  Box,
  Card,
  Checkbox,
  CardHeader,
  Typography,
  FormControlLabel,
  Stack,
  Divider,
  Skeleton,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Avatar,
  Button,
} from "@mui/material";

import DatePicker from "react-datepicker";
import moment from "moment";
import { noTask } from "../../../../assets";
import { IconButton } from "@mui/material";
import { set_blank_target } from "src/utils/DomUtils";
import { useSnackbar } from "notistack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { useSocket } from "../../Hooks/../../../Hooks/socketContext";
// import PriorityPopover from './PriorityPopover';
import PriorityPopover from "../../../../pages/Tasks/components/Priority";
import { changeStatus, detailProject } from "src/DAL/Project/Project";
import { baseUri } from "../../../../config/config";
import { useNavigate } from "react-router-dom";
import StatusPopover from "../../../../pages/Tasks/components/Status";
import { status_color_and_value } from "src/constants";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { ErrorBoundary, TaskDateTimePicker } from "src/components";
import { format, formatISO, isPast, isValid, parseISO } from "date-fns";
// ----------------------------------------------------------------------
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, targetTask }, ref) => (
    <Tooltip title="Target Date">
      {value !== "" ? (
        <>
          <a
            role="button"
            tabIndex="0"
            className="btn"
            ref={ref}
            style={{
              textDecorationLine: "underline",
              fontSize: 12,
              padding: 0,
              color: getDateColor(value, targetTask.status),
            }}
          >
            {moment(value).format("DD MMM, YYYY")}
          </a>
          {showDangerIcon(value, targetTask.status) && (
            <>
              <PriorityHighIcon htmlColor="red" style={{ fontSize: "1rem" }} />
            </>
          )}
        </>
      ) : (
        <a
          role="button"
          tabIndex="0"
          onClick={onClick}
          onKeyDown={onClick}
          ref={ref}
          style={{ textDecorationLine: "underline", fontSize: 12, padding: 0 }}
          className="theme-text-color"
        >
          Select Date
        </a>
      )}
    </Tooltip>
  )
);
// ----------------------------------------------------------------------
const getActualTime = (date) => {
  //2022-02-23 09:52:33 server date example
  let date_safari = date;
  for (let i = 0; i < date_safari.length; i++) {
    if (date_safari.charAt(i) === "-") {
      date_safari = date_safari.replace("-", "/");
    }
  }
  const serverDate = new Date(date); // todays date
  const difference = serverDate.getTimezoneOffset(); // another date
  const time = moment(moment(serverDate).subtract(difference, "m"));
  const serverDate_safari = new Date(date_safari);
  const difference_safari = serverDate_safari.getTimezoneOffset();
  if (detectSafariBrowser()) {
    const setTime = moment(
      moment(date_safari).subtract(difference_safari, "m")
    );
    return setTime;
  }
  if (!detectSafariBrowser()) {
    const setTime = moment(moment(serverDate).subtract(difference, "m"));
    return setTime;
  }
};
const detectSafariBrowser = () => {
  return true;
};
const getDateColor = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return "rgb(0, 171, 85)";
    }
    return "red";
  }
  return "rgb(0, 171, 85)";
};
const showDangerIcon = (targetDate, status) => {
  //2022-01-24 example date
  const today_date = new Date();
  let today_moment_date = moment(today_date).format("YYYY MM DD");
  if (moment(targetDate).isBefore(today_moment_date)) {
    if (Number(status) === 5 || Number(status) === 3) {
      return false;
    }
    return true;
  }
  return false;
};
export default function TaskListCard({
  data,
  title,
  getTasks,
  customButton,
  customButtonTitle,
  handleCustomButtonClick,
}) {
  const [taskData, setTaskData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { sendSocketNotification, showSocketNotification } = useSocket();

  const getStatusColor = (value) => {
    const chip = status_color_and_value.find(
      (chip) => String(chip.value) === String(value)
    );
    return chip.color;
  };

  const handlePriority = () => {
    //
  };
  const getModifiedData = (data) => {
    let modified = [];
    data.map((task, index) => {
      const userId = localStorage.getItem("user_id");
      let isAllowed = false;
      if (String(userId) === String(task.task_creator_id)) {
        isAllowed = true;
      }
      task = {
        // ...task,
        task_id: task.task_id,
        task: task.task_name,
        status: task.task_status,
        description: task.task_distribution,
        task_creator_id: task.task_creator_id,
        taskOrder: task.task_order,
        targetDate: task.target_date,
        projectId: task.project_id,
        projectManagerId: task.project_manager_id,
        creatorImage: task.task_creator_image,
        creatorName: task.task_creator_name,
        endDate: "",
        priority: task.task_priority,
        detailVisible: true,
        isAssigned: true,
        visibilityStatus: task.vasibility_status,
        isAllowed: isAllowed,
        isCreator: isAllowed,
        createdDate: task.created_at,
        backToDashboard: true,
        projectName: task.project_name,
      };
      modified.push(task);
    });
    return modified;
  };
  const handleTaskStatus = async (value, data) => {
    setTaskData((task) =>
      task.map((x) => {
        if (x.task_id === data.task_id) {
          return {
            ...x,
            status: value,
          };
        }
        return x;
      })
    );
    const formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("status", value);
    formData.append("task_id", data.task_id);

    const result = await changeStatus(formData);
    if (result.code === 200) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: value,
            };
          }
          return x;
        })
      );
      const payload = {
        receiver_id: data.projectManagerId,
        message: "Your Task Status has been Changed",
        project_id: data.projectId,
        task_id: data.task_id,
        project_name: data.projectName,
        notification_type: "task",
      };
      sendSocketNotification(payload);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      getTasks();
    }
    // setTaskStatus(value);
  };
  const handleStatusChecked = async (status, data) => {
    if (status === true) {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 3,
            };
          }
          return x;
        })
      );
      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 3);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        // fetchTaskListing();
        getTasks();
        setTaskData((task) =>
          task.map((x) => {
            if (x.task_id === data.task_id) {
              return {
                ...x,
                status: 3,
              };
            }
            return x;
          })
        );
        const payload = {
          receiver_id: data.projectManagerId,
          message: "Your Task Status has been Changed",
          project_id: data.projectId,
          task_id: data.task_id,
          project_name: data.projectName,
          notification_type: "task",
        };
        sendSocketNotification(payload);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        // fetchTaskListing();
      }
    } else {
      setTaskData((task) =>
        task.map((x) => {
          if (x.task_id === data.task_id) {
            return {
              ...x,
              status: 1,
            };
          }
          return x;
        })
      );

      const formData = new FormData();
      formData.append("token", localStorage.getItem("token"));
      formData.append("status", 1);
      formData.append("task_id", data.task_id);

      const result = await changeStatus(formData);
      if (result.code === 200) {
        // fetchTaskListing();
        getTasks();
        setTaskData((task) =>
          task.map((x) => {
            if (x.task_id === data.task_id) {
              return {
                ...x,
                status: 1,
              };
            }
            return x;
          })
        );
        const payload = {
          receiver_id: data.projectManagerId,
          message: "Your Task Status has been Changed",
          project_id: data.projectId,
          task_id: data.task_id,
          project_name: data.projectName,
          notification_type: "task",
        };
        sendSocketNotification(payload);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        getTasks();
        // fetchTaskListing();
      }
    }
  };
  const handleCopyTaskLink = (task) => {
    let newLink = "";
    let project_id = task.projectId;
    console.log(task, "to-be-copied");
    // console.log(location, 'location-router');
    console.log(window.location, "native-location");
    newLink = `${window.location.origin}/project/${project_id}/${task.task_id}`;
    console.log(newLink);
    navigator.clipboard.writeText(newLink);
    enqueueSnackbar("Task link copied to clipboard", { variant: "success" });
  };
  const handleProjectNavigate = (task) => {
    // navigate(`/project/${task.projectId}`, { state: task });
    ///project-categories-list/project/:id
    navigate(`/projects/project/${task.projectId}`, { state: task });
  };
  const handleNavigate = (task) => {
    console.log(task, "navigate");
    // navigate(`/project/${task.projectId}`, { state: task });
    navigate(`/projects/project/${task.projectId}`, { state: task });
  };
  useEffect(() => {
    const result = getModifiedData(data);
    setTaskData(result);
  }, [data]);
  const onTargetDateTimeChange = (new_date_time, task) => {
    console.log(new_date_time, "new-date");

    // handleTargetDate(new_date_time, task.task_id);
  };
  const handleOpenTooltip = (e) => {
    setTimeout(() => {
      set_blank_target("task-description-html");
    }, 500);
  };
  const getTooltipHtml = (html) => {
    if (!html) {
      return { __html: "<p>No Description added!</p>" };
    }

    return { __html: html };
  };

  const getFormattedDate = (_date) => {
    const date_format = "dd MMM yyyy hh:mm aaa";
    if (String(_date) === "0000-00-00") {
      return "Select date/time";
    }
    let formatted = "";

    if (_date === "" || _date === undefined || _date === null) {
      return "Select date/time";
    }
    try {
      formatted = format(parseISO(_date), date_format);
    } catch (error) {
      if (error.message) {
        formatted = String(_date);
        // console.log(error.message, "date-err");
      }
    }
    return formatted;
  };

  return (
    <Card sx={{ boxShadow: 0, border: "solid 1px #c5c2c2" }}>
      {customButton ? (
        <div className="d-flex align-items-center w-100 justify-content-between">
          <CardHeader title={title}></CardHeader>
          <div style={{ padding: "24px 24px 0px" }}>
            <Button
              onClick={handleCustomButtonClick}
              variant="contained"
              size="small"
            >
              {customButtonTitle}
            </Button>
          </div>
        </div>
      ) : (
        <CardHeader title={title}></CardHeader>
      )}

      <Box
        sx={{ px: { xs: 3, md: 5 }, py: 1, minHeight: "13rem" }}
        // sx={{ px: 5, py: 1, minHeight: '13rem' }}
      >
        <div className="row " style={{ minHeight: "13rem", minHeight: "100%" }}>
          {taskData.length > 0 ? (
            taskData.map((task, index) => (
              <Box
                key={index}
                className="not-drag col-12  pl-1 not-drag-col-outer"
                sx={{ boxShadow: 3 }}
                onClick={() => console.log(task, "task--")}
              >
                <div
                  className="not-drag col-12 p-0 not-drag-col-inner"
                  role="button"
                  tabIndex="0"
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ paddingBottom: "5px" }}
                  >
                    <Checkbox
                      checked={
                        String(task.status) === String(3) ||
                        (String(task.status) === String(5) && true)
                      }
                      onChange={(e) =>
                        handleStatusChecked(e.target.checked, task)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    <Tooltip
                      // open={true}
                      onOpen={(e) => handleOpenTooltip(e)}
                      title={
                        <div id="task-description-tooltip">
                          <div
                            id="task-description-html"
                            dangerouslySetInnerHTML={getTooltipHtml(
                              task.description
                            )}
                          ></div>
                        </div>
                      }
                      placement="top"
                      arrow
                    >
                      <Typography
                        component="div"
                        color={"#5a5a5a"}
                        // className={task.isAssigned === false && 'col-disabled'}
                        // onClick={() => handleOpenEditTask(task)}
                        onClick={() => handleNavigate(task)}
                      >
                        {task.task}&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Tooltip>

                    <div
                      className="d-flex align-items-center"
                      style={{
                        marginLeft: "auto",
                        marginRight: ".5rem",
                        flexWrap: "no-wrap",
                      }}
                    ></div>
                  </div>

                  {task.detailVisible === true ? (
                    <div className="row">
                      {/* ================================================= date picker ===================================== */}

                      <div className="col-md-4 d-flex flex-col align-items-center ">
                        <div
                          style={{ marginLeft: ".7rem" }}
                          className="theme-text-color"
                        >
                          <span>
                            <ErrorBoundary
                              fallback={
                                <>
                                  <span className="task-date">
                                    {getFormattedDate(task.targetDate)}
                                  </span>
                                </>
                              }
                            >
                              <TaskDateTimePicker
                                /* worked on live if you create
                              build on dev branch
                              with build:live */
                                value={task.targetDate}
                                onChange={(new_date) =>
                                  onTargetDateTimeChange(new_date, task)
                                }
                                task={task}
                                disabled={true}
                              />
                            </ErrorBoundary>
                          </span>
                          {/* {task.targetDate !== '0000-00-00' && (
                            <DatePicker
                              // dateFormat="YYYY-MM-DD"

                              value={task.targetDate}
                              selected={new Date(task.targetDate)}
                              // onSelect={(date) => handleTargetDate(date, task.task_id)}
                              minDate={new Date()}
                              targetTask={task}
                              customInput={<ExampleCustomInput targetTask={task} />}
                            />
                          )} */}
                        </div>
                      </div>
                      <div className="col-md-4 py-1 mb-2 mb-md-0">
                        <div className="row align-items-center">
                          <div className="col-12 d-flex">
                            <div
                              className="mr-2"
                              style={{ marginLeft: "0.7rem" }}
                            >
                              <Tooltip
                                title={`Created by ${task.creatorName}`}
                                placement="bottom"
                              >
                                {task.taskCreatorImage !== "" ? (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={baseUri + task.creatorImage}
                                    style={{ height: 30, width: 30 }}
                                  />
                                ) : (
                                  <Avatar
                                    alt="Remy Sharp"
                                    style={{ height: 30, width: 30 }}
                                  >
                                    {task.creatorName.substring(0, 1)}
                                  </Avatar>
                                )}
                              </Tooltip>
                            </div>
                            <div className="mr-2">
                              <div>
                                <PriorityPopover
                                  isCreator={task.isCreator}
                                  isAssigned={task.isAssigned}
                                  priority={task.priority}
                                  id={task.task_id}
                                  task={task}
                                  handleTaskPriority={handlePriority}
                                  shouldOpen={false}
                                />
                              </div>
                            </div>
                            <div>
                              <StatusPopover
                                isAssigned={task.isAssigned}
                                isCreator={task.isCreator}
                                status={task.status}
                                id={task}
                                handleStatus={handleTaskStatus}
                                onStatusChecked={handleStatusChecked}
                                shouldOpen={false}
                              />
                            </div>
                            {String(task.visibilityStatus) === String(1) && (
                              <div className="d-flex align-items-center">
                                <Tooltip
                                  onClick={() => handleCopyTaskLink(task)}
                                  title="Copy task link"
                                >
                                  <ContentCopyIcon
                                    style={{
                                      marginLeft: "0.8rem",
                                      color: "grey",
                                      fontSize: "0.9rem",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 py-1 mb-2 mb-md-0">
                        <Tooltip title="Project name">
                          <Typography
                            onClick={() => handleProjectNavigate(task)}
                            className="project-name-div"
                            component="span"
                            style={{ marginLeft: "0.7rem" }}
                          >
                            {task.projectName}
                          </Typography>
                        </Tooltip>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <Divider /> */}
                </div>
              </Box>
            ))
          ) : (
            <div className="col-12 mt-5" style={{ minHeight: "100%" }}>
              <img
                src={noTask}
                alt="no task"
                width="64rem"
                height="64rem"
                style={{ margin: "auto" }}
              />
              <Typography
                style={{
                  textAlign: "center",
                  paddingLeft: 50,
                  paddingTop: 10,
                }}
              >
                No Task Found
              </Typography>
            </div>
          )}
        </div>
      </Box>
    </Card>
  );
}
