import { Card, Tooltip } from "@mui/material";
import {
  formatISO,
  format,
  parseISO,
  isToday,
  setHours,
  setMinutes,
} from "date-fns";
import { isPast } from "date-fns/esm";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Iconify } from ".";
/* <<--------------------------------------------------------->> */

/*  works on live if you create
    build on dev branch
    with build:live 
*/
const TaskDateTimePicker = ({ task, value, onChange, disabled, hasPortal }) => {
  /* <<--------------------------------------------------------->> */
  // const [value, setValue] = useState("");
  // const [value, setValue] = useState("2022-08-17T19:00:00.000Z");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (_date) => {
    const fns_iso_date = formatISO(_date, { representation: "complete" });
    // setValue(fns_iso_date);
    let expired = isPast(fns_iso_date);
    console.log(expired, "expired?");
    /* expired not working */
    if (expired === true) {
      console.log("expired!");
      return;
    }
    onChange(fns_iso_date);

    handleClose();
  };

  const handleFilterTimes = (time) => {
    if (value === "") {
      return false; /* disable times */
    }
    let iso_time = formatISO(time, { representation: "complete" });
    let past = isPast(parseISO(iso_time));
    if (past === true) {
      return false; /* disable times */
    }
    if (isToday(parseISO(value))) {
      return true;
    }
    let expired = isPast(parseISO(value));
    if (expired === true) {
      return false; /* disable times */
    }
    return true;
  };
  const hasDate = () => {
    if (value === "" || value === null || value === "undefined") {
      return false;
    }
    if (String(value) === "0000-00-00") {
      return false;
    }
    return true;
  };
  return (
    <div id="task-date-pick">
      <DatePicker
        value={hasDate() ? value : ""}
        open={open}
        timeIntervals={5}
        showTimeSelect
        filterTime={handleFilterTimes}
        onClickOutside={handleClose}
        onChange={handleChange}
        selected={hasDate() ? parseISO(value) : new Date()}
        minDate={new Date()}
        disabled={disabled}
        portalId={hasPortal ? "date-picker-root-portal" : ""}
        customInput={
          <CustomPickerInput
            onOpen={handleOpen}
            onClose={handleClose}
            task={task}
            open={open}
            disabled={disabled}
          />
        }
      />
    </div>
  );
};

export default TaskDateTimePicker;

const CustomPickerInput = React.forwardRef((props, ref) => {
  const { task, value, onOpen, open, onClose, disabled } = props;

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    onOpen();
  };
  const getFormattedDate = (_date) => {
    const date_format = "dd MMM yyyy hh:mm aaa";
    if (_date === "" || _date === undefined || _date === null) {
      return "Select date/time";
    }
    const formatted = format(parseISO(_date), date_format);

    return formatted;
  };
  if (open === true) {
    return (
      <span
        ref={ref}
        className="task-date"
        onClick={handleOpen}
        style={{ color: isPast(parseISO(value)) ? "red" : "" }}
      >
        {getFormattedDate(value)}
        {isPast(parseISO(value)) && (
          <PriorityHighIcon
            htmlColor="red"
            style={{
              fontSize: "1rem",
            }}
          />
        )}
      </span>
    );
  }
  return (
    <Tooltip title="Target date/time">
      <span
        ref={ref}
        className="task-date"
        onClick={handleOpen}
        style={{ color: isPast(parseISO(value)) ? "red" : "" }}
      >
        {getFormattedDate(value)}
        {isPast(parseISO(value)) && (
          <PriorityHighIcon
            htmlColor="red"
            style={{
              fontSize: "1rem",
            }}
          />
        )}
      </span>
    </Tooltip>
  );
});
