import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
import { string } from 'yup';
import moment from 'moment';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'none', label: 'none', type: 'default' },
  { value: 'oldest', label: 'Oldest First', type: 'date' },
  { value: 'newest', label: 'Newest First', type: 'date' },
  { value: 'priorityDesc', label: 'Priority: High-Low', type: 'priority' },
  { value: 'priorityAsc', label: 'Priority: Low-High', type: 'priority' },
  {
    value: 'priorityAsc',
    label: 'Pick last project from array instead of first.',
    type: 'priority',
  },
];
export default function SectionSelectV2({
  data,
  onChange,
  selectedIndex,
  setSelectedIndex,
  setSelectedSection,
  taskSectionsListing,
  isDisabled,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const [sectionsList, setSectionsList] = useState([]);
  const [title, setTitle] = useState('');
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = async (event, index) => {
    console.log('isindex', index);
    setSelectedIndex(index);
    let text = getText(index);
    setTitle(text);
    setOpen(null);
    onChange(taskSectionsListing[index]);
    setSelectedSection(taskSectionsListing[index]);
  };
  const isSelected = (index) => {
    let selected = false;
    if (index === selectedIndex) {
      selected = true;
    }
    return selected;
  };
  const truncateText = (string, maxLength) => {
    if (!string) {
      return;
    }
    if (string === '') {
      return '';
    }
    if (maxLength == string.length) {
      return string;
    }
    if (string.length > maxLength) {
      let result = string.slice(0, maxLength).concat(' ' + '...');

      return result;
    }
    return string;
  };
  const getText = (index) => {
    console.log(index, 'at-get');
    if (index === -1) {
      return '';
    }
    const text = taskSectionsListing[index].section_name;
    return truncateText(text, 20);
  };
  const getFilteredSections = (data) => {
    return data;
  };
  return (
    <>
      <Button
        color='inherit'
        disableRipple
        disabled={isDisabled}
        onClick={handleOpen}
        sx={{
          backgroundColor: '#f6f7f8',
          width: '100%',
          overflow: 'hidden',
          height: '52px',
          padding: '2px',
          overflow: 'hidden',
        }}
        endIcon={<Icon color='#00ab55' icon={open ? chevronUpFill : chevronDownFill} />}
      >
        <Typography
          component='span'
          sx={{ color: '#00ab55', fontWeight: '600', textTransform: 'none' }}
        >
          {/* {title === '' && 'Choose Section'} */}
          {taskSectionsListing.length <= 0 && 'Choose Section'}
          {/* &nbsp; */}
        </Typography>
        <Typography
          component='span'
          overflow='hidden'
          variant='subtitle2'
          sx={{ color: 'text.secondary', textTransform: 'none' }}
        >
          {/* {title} */}
          {taskSectionsListing.length > 0 &&
            truncateText(taskSectionsListing[selectedIndex]?.section_name, 25)}
        </Typography>
      </Button>
      <Menu
        disabled={isDisabled}
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            // maxWidth: '18rem',
          },
        }}
      >
        {getFilteredSections(taskSectionsListing).length > 0 ? (
          <span>
            {getFilteredSections(taskSectionsListing).map((option, index) => (
              <MenuItem
                key={option.id}
                // selected={option.value === 'newest'}
                // onClick={handleClose}
                sx={{ typography: 'body2' }}
                selected={isSelected(index)}
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                {truncateText(option.section_name, 30)}
                {/* {option.task} */}
              </MenuItem>
            ))}
          </span>
        ) : (
          <MenuItem disabled sx={{ typography: 'body2' }}>
            No sections yet
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

// export default function SectionSelectV2({
//   data,
//   onChange,
//   selectedIndexPublic,
//   setSelectedIndexPublic,
//   selectedIndexPrivate,
//   setSelectedIndexPrivate,
//   isTaskPersonal,
//   setSelectedSection,
// }) {
//   const [open, setOpen] = useState(null);
//   const [title, setTitle] = useState('');
//   const handleOpen = (event) => {
//     setOpen(event.currentTarget);
//   };
//   const handleClose = () => {
//     setOpen(null);
//   };

//   const handleMenuItemClick = async (event, index) => {
//     console.log('isindex', index);
//     if (isTaskPersonal) {
//       setSelectedIndexPrivate(index);
//       let text = getText(index);
//       setTitle(text);
//       console.log(text, 'at-item  ');
//     } else if (!isTaskPersonal) {
//       setSelectedIndexPublic(index);
//       let text = getText(index);
//       setTitle(text);
//       console.log(text, 'at-item  ');
//     }
//     setOpen(null);
//     // if (index === 0) {
//     //   onChange({ task: 'default' });
//     //   return;
//     // }
//     onChange(data[index]);
//   };
//   const isSelected = (index) => {
//     let selected = false;
//     if (isTaskPersonal) {
//       if (index === selectedIndexPrivate) {
//         selected = true;
//         return selected;
//       }
//     }
//     if (!isTaskPersonal) {
//       if (index === selectedIndexPublic) {
//         selected = true;
//         return selected;
//       }
//     }
//   };
//   const truncateText = (string, maxLength) => {
//     if (maxLength == string.length) {
//       return string;
//     }
//     if (string.length > maxLength) {
//       let result = string.slice(0, maxLength).concat(' ' + '...');
//       console.log(result, 'conc');
//       return result;
//     }
//     return string;
//   };
//   const getText = (index) => {
//     console.log(index, 'at-get');
//     if (index === -1) {
//       return '';
//     }
//     const text = data[index].task;
//     return truncateText(text, 20);
//   };
//   useEffect(() => {
//     console.log(data, 'data--');
//   }, [data]);
//   useEffect(() => {
//     setTitle('');
//     setSelectedIndexPrivate(0);
//     setSelectedIndexPublic(0);
//   }, [isTaskPersonal]);
//   React.useEffect(() => {
//     if (isTaskPersonal) {
//       setSelectedSection(data[selectedIndexPrivate]);
//     }
//     if (!isTaskPersonal) {
//       setSelectedSection(data[selectedIndexPublic]);
//     }
//   }, [selectedIndexPublic, selectedIndexPrivate]);
//   return (
//     <>
//       <Button
//         color='inherit'
//         disableRipple
//         onClick={handleOpen}
//         sx={{
//           backgroundColor: '#f6f7f8',
//           width: '100%',
//           overflow: 'hidden',
//           height: '52px',
//           padding: '2px',
//           overflow: 'hidden',
//         }}
//         endIcon={<Icon color='#00ab55' icon={open ? chevronUpFill : chevronDownFill} />}
//       >
//         <Typography
//           component='span'
//           sx={{ color: '#00ab55', fontWeight: '600', textTransform: 'none' }}
//         >
//           {/* {title === '' && 'Choose Section'} */}
//           {data.length <= 0 && 'Choose Section'}
//           {/* &nbsp; */}
//         </Typography>
//         <Typography
//           component='span'
//           overflow='hidden'
//           variant='subtitle2'
//           sx={{ color: 'text.secondary', textTransform: 'none' }}
//         >
//           {/* {title} */}
//           {isTaskPersonal && <> {data.length > 0 && data[selectedIndexPrivate].section_name} </>}
//           {!isTaskPersonal && <> {data.length > 0 && data[selectedIndexPublic].section_name} </>}
//         </Typography>
//       </Button>
//       <Menu
//         keepMounted
//         anchorEl={open}
//         open={Boolean(open)}
//         onClose={handleClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//         PaperProps={{
//           sx: {
//             // maxWidth: '18rem',
//           },
//         }}
//       >
//         {data.length > 0 ? (
//           <span>
//             {/* <MenuItem
//               sx={{ typography: 'body2' }}
//               onClick={(event) => handleMenuItemClick(event, 0)}
//               selected={isSelected(0)}
//             >
//               Default
//             </MenuItem> */}
//             {data.map((option, index) => (
//               <MenuItem
//                 key={option.id}
//                 // selected={option.value === 'newest'}
//                 // onClick={handleClose}
//                 sx={{ typography: 'body2' }}
//                 selected={isSelected(index)}
//                 onClick={(event) => handleMenuItemClick(event, index)}
//               >
//                 {truncateText(option.section_name, 30)}
//                 {/* {option.task} */}
//               </MenuItem>
//             ))}
//           </span>
//         ) : (
//           <MenuItem
//             disabled
//             sx={{ typography: 'body2' }}
//             // onClick={(event) => handleMenuItemClick(event, index)}
//           >
//             No sections yet
//           </MenuItem>
//         )}
//       </Menu>
//     </>
//   );
// }
