import PropTypes from "prop-types";
// material
import { Grid } from "@mui/material";
import ProjectCard from "./ProjectCard";
import SearchNotFound from "../../SearchNotFound";
import { DndContext } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

// ----------------------------------------------------------------------

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default function ProjectList({
  projects,
  onDeleteProject,
  dragDisabled,
  isDeletingProject,
  afterSort,
  ...other
}) {
  const [dragStart, setDragStart] = useState(false);
  const handleDragEnd = (result) => {
    console.log(result, "drag-end");
    const active = result.active;
    const over = result.over;
    if (!active || !over) {
      return;
    }
    const active_index = active.data.current.sortable.index;
    const over_index = over.data.current.sortable.index;
    if (active_index === over_index) {
      return;
    }
    // else sort the array
    console.log(active_index, "active_index");
    console.log(over_index, "over_index");
    let sorted = sortArrayItems({
      destination_index: over_index,
      source_index: active_index,
      data: projects,
    });
    // console.log(sorted, "sorted");
    afterSort(sorted);
  };
  const sortArrayItems = ({
    destination_index = 0,
    source_index = 0,
    data = [],
  }) => {
    let spliced_items = [...data];

    const [removed_item] = spliced_items.splice(source_index, 1);
    spliced_items.splice(destination_index, 0, removed_item);

    return spliced_items;
  };
  return (
    <div style={{ overflow: "hidden" }} {...other}>
      <Grid container spacing={3}>
        {projects.length > 0 ? (
          projects.map((project) => {
            return (
              <Grid key={project.id} item xs={12} sm={6} md={3}>
                <ProjectCard
                  isDeletingProject={isDeletingProject}
                  project={project}
                  onDeleteProject={onDeleteProject}
                />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12}>
            <SearchNotFound />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
function SortableItem(props) {
  const item = props.item;
  const {
    transform,
    transition,
    setDraggableNodeRef,
    setDroppableNodeRef,
    setNodeRef,
    listeners,
    attributes,
    data,
    isSorting,
    isOver,
    over,
    newIndex,
    overIndex,
    isDragging,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{ zIndex: isDragging ? "2" : "1" }}
      // style={{ padding: "0px" }}
    >
      <div ref={setNodeRef}>
        <div
          {...listeners}
          {...attributes}
          style={style}
          ref={setDraggableNodeRef}
        >
          <ProjectCard project={item} />
        </div>
      </div>
    </Grid>
  );
}
