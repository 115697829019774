import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Typography,
  Stack,
  NativeSelect,
  FormLabel,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { detailProject, editProject, editProjectTeam } from '../../../DAL/Project/Project';
import { getManagerTeam, teamList } from '../../../DAL/Team/Team';
import { baseUri } from '../../../config/config';
import CkEditor from 'src/components/editor/CKEditor';
const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

// ============================================ styling=========================

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
  projectTypeRadio: {
    '& label': {
      margin: '0px',
    },
    // '& span': {
    //   marginTop: '0.1rem',
    // },
  },
  // give this margin to fields below description
  formControlMargin: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  img: {
    margin: 'auto',
  },
}));

function EditProject(props) {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [iswaiting, setIswaiting] = useState(true);
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(true);
  const [description, setDescription] = useState('');
  const [member, setMember] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [Image, setimage] = React.useState('');
  const [file, setProfileImage] = React.useState('');
  const [projectType, setProjectType] = useState('1');
  const handleChange = (data) => {
    setMember(data);
    // const {
    //   target: { value }
    // } = event;
    // setMember(
    //   // On autofill we get a the stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIswaiting(true);
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_name', name);
    formData.append('project_id', id);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('project_description', description);
    formData.append('status', status);
    formData.append('project_visibility_status', projectType);
    if (Image.name) {
      formData.append('project_image', Image);
    }
    if (projectType == '1') {
      const arr = member.map((x, i) => {
        formData.append(`team[${i}]`, x.user_id);
        return x;
      });
    }
    // if(projectType=='0'){
    //   formData.append('team[]','')
    // }
    const result = await editProjectTeam(formData);
    if (result.code === 200) {
      // setData(result.Project_list);
      setIswaiting(false);
      navigate(-1);
    } else {
      setIswaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fileChangedHandler = async (e) => {
    // setiswaiting(true);
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setimage(e.target.files[0]);
    // let formData = new FormData(); //formdata object
    // formData.append("directory", "images/");
    // formData.append("image", e.target.files[0]);
    // const upload_image_resp = await upload_image(formData);
    // console.log(upload_image_resp);
    // setimage(upload_image_resp.path);
    // if(upload_image_resp.code == 200){
    //   setiswaiting(false);
    // }
  };

  const fetchProjectDetail = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    formData.append('project_id', id);

    const result = await detailProject(formData);
    console.log(result, 'detail-project-response');
    if (result.code === 200) {
      // setMembersData(result.Team_member_list);
      setName(result.Team_Data.project_name);
      setStartDate(result.Team_Data.start_date);
      setEndDate(result.Team_Data.end_date);
      setDescription(result.Team_Data.project_description);
      setimage(result.Team_Data.project_image);
      // console.log(result.Team_Data.project_visibility_status, 'visibility')
      setProjectType(result.Team_Data.project_visibility_status);
      if (result.Team_Data.project_image) {
        setProfileImage(baseUri + result.Team_Data.project_image);
      } else {
        setProfileImage(
          'https://www.bastiaanmulder.nl/wp-content/uploads/2013/11/dummy-image-square.jpg'
        );
      }
      setMember(result.Team_Project);
      setIswaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const fetchTeamListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await getManagerTeam(formData);
    console.log(result, 'manager-project-team');
    if (result.code === 200) {
      setMembersData(result.manager_team_list);
      setIswaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
      setIswaiting(false);
    }
  };

  useEffect(async () => {
    fetchTeamListing();
    fetchProjectDetail();
  }, []);

  if (iswaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }

  return (
    <>
      <Container maxWidth='lg'>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant='h4' sx={{ mb: 5 }} style={{ textAlign: 'center' }}>
          Edit Project
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin='dense'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name'
                label='Name'
                type='name'
                fullWidth
                variant='outlined'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                id='name'
                label='Start Date'
                required
                type='date'
                fullWidth
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin='dense'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                id='name'
                label='End Date'
                required
                type='date'
                fullWidth
                variant='outlined'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction='row' alignItems='center'>
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Project type
                  </InputLabel>
                  <NativeSelect
                    variant='outlined'
                    className={classes.formControlMargin}
                    value={projectType}
                    onChange={(e) => setProjectType(e.target.value)}
                    // defaultValue='1'
                    inputProps={{
                      name: 'Project type',
                      id: 'controlled-native',
                    }}
                  >
                    <option value='1'>Team</option>
                    <option value='0'>Personal</option>
                  </NativeSelect>
                </FormControl>
              </Stack>
            </Grid>
            {projectType == '1' && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  id='tags-filled'
                  options={membersData}
                  value={member}
                  // defaultValue={member}
                  getOptionLabel={(option) => option.first_name}
                  // freeSolo
                  // getOptionSelected={(option, value) => option.user_id === value.user_id}
                  onChange={(event, value) => handleChange(value)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option.first_name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' label='Select Team Members' />
                  )}
                />
                {/* <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Team Member</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={member}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Team Member" />}
                  renderValue={(selected) => (
                    <>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.first_name} />
                      ))}
                    </>
                  )}
                >
                  {membersData.map((x, i) => (
                    <MenuItem key={x.id} value={x}>
                      {x.first_name} {(' ', x.last_name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormLabel>Project Description :</FormLabel>
              <div className='project-description'>
                <CkEditor content={description} setContent={(value) => setDescription(value)} />
              </div>
              {/* <TextField
                margin='dense'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                id='name'
                label='Description'
                type='description'
                fullWidth
                variant='outlined'
                multiline
                rows={4}
              /> */}
            </Grid>
            <Grid item xs={12}>
              {file ? (
                <>
                  {' '}
                  <img className={classes.img} src={file} alt='team member' />{' '}
                </>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <input
                accept='image/png,image/jpeg,image/jpg'
                hidden
                id='contained-button-file'
                multiple
                type='file'
                onChange={fileChangedHandler}
              />
              <label htmlFor='contained-button-file' style={{ display: 'flex' }}>
                <Button
                  component='span'
                  fullWidth
                  variant='outlined'
                  color='primary'
                  startIcon={<DriveFolderUploadIcon />}
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs={6}>
              Status :
              <Switch
                checked={status}
                onChange={(e) => setStatus(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant='contained' type='submit'>
                Edit Project
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default EditProject;
