import React, { useContext, useEffect, useState } from 'react';
// import io from 'socket.io-client';
import socketio from 'socket.io-client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { socketUrl } from '../config/config';
import {
  teamNotification,
  teamNotificationStatusChange,
  teamNotificationAllRead,
} from '../DAL/Notification/Notification';
import { userProfile } from '../DAL/Project/Project';
import MessageRing from 'src/assets/mp3/MessageRing.mp3';
import { unreadMessagesCount } from 'src/DAL/chat/chat';
const SocketContext = React.createContext();
const socket_url = socketUrl + '?user_id=' + localStorage.getItem('user_id');
export const useSocket = () => useContext(SocketContext);
let socket;
export function ContextSocket({ children }) {
  /* ------------------------------------------------------
  ------------------| Hooks Functions |--------------------
  ------------------------------------------------------- */
  let user_profile = localStorage.getItem('user-profile');
  let parsed = JSON.parse(user_profile);
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);
  const [user_Profile, setUser_Profile] = useState(parsed);
  const [socketChatInfo, setSocketChatInfo] = useState(undefined);
  const [socketGroupInfo, setSocketGroupInfo] = useState(undefined);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  /* ------------------------------------------------------
  ------------------/ States /--------------------
  ------------------------------------------------------- */
  const _getUnreadMessageCount = () => {
    return unreadMessageCount;
  };
  const _setUnreadMessageCount = (count) => {
    setUnreadMessageCount(count);
  };
  const getUserProfileData = () => {
    return user_Profile;
  };
  const fetchUnreadMessagesCount = async () => {
    const data = new FormData();
    data.append('token', localStorage.getItem('token'));
    const result = await unreadMessagesCount(data);
    console.log(result, 'unread-msg');
    if (result.code === 200) {
      //
      setUnreadMessageCount(Number(result.unread_messages));
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const setUserProfileData = (userData) => {
    let stringified = JSON.stringify(userData);
    setUser_Profile(JSON.parse(stringified));
  };
  const getUserProfile = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await userProfile(formData);
    const { user_detail } = result;
    console.log(user_detail, 'socket-user-profile');
    if (result.code === 200) {
      localStorage.setItem('user-profile', JSON.stringify(user_detail));
      localStorage.setItem('user-status', user_detail.status);
      setUserProfileData(user_detail);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  const fetchNotificationListing = async () => {
    const formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await teamNotification(formData);
    if (result.code === 200) {
      console.log('notification', result);
      setNotifications(result.notify_list);
    } else {
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };

  const showNotification = (_data) => {
    console.log(_data, 'is-data');
    const notification = new Notification('New Notification: Dynamite Digital CheckList!', {
      body: _data.payload,
      icon: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678134-sign-check-512.png',
    });
    notification.onclick = function (event) {
      event.preventDefault();
      if (_data.task_id) {
        navigate(`/projects/project/${_data.project_id}`, {
          state: { task_id: _data.task_id, project_name: _data.project_name },
        });
      } else {
        navigate(`/projects/project/${_data.project_id}`, {
          state: { project_name: _data.project_name },
        });
      }
    };
  };
  const showChatNotification = (_data) => {
    if (!isTabActive()) {
      PlayMessageSound();
    }
    console.log(_data, 'at-show-notif');
    const notification = new Notification('New Message Received!', {
      body: _data.message,
      icon: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678134-sign-check-512.png',
    });

    notification.onclick = function (event) {
      event.preventDefault();
      if (String(location.pathname) === '/chat/chat-messages') {
        navigate('/chat');
        setTimeout(() => {
          navigate('/chat/chat-messages');
        }, 0);
      } else {
        navigate('/chat/chat-messages');
      }
    };
  };
  const showSocketNotification = (_data) => {
    const type = _data.notification_type;
    const action = _data.notification_action;
    if (Notification.permission === 'granted') {
      console.log('granted');
      if (String(type) === 'task') {
        fetchNotificationListing();
        showNotification(_data);
      }
      if (String(type) === 'chat') {
        if (String(action) === 'add_message') {
          showChatNotification(_data);
        }
      }
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          if (String(type) === 'task') {
            fetchNotificationListing();
            showNotification(_data);
          }
          if (String(type) === 'chat') {
            if (String(action) === 'add_message') {
              showChatNotification(_data);
            }
          }
        }
      });
    }
  };

  const sendSocketNotification = (payload) => {
    console.log(payload, 'payload');
    const data = {
      sender_id: localStorage.getItem('user_id'),
      receiver_id: payload.receiver_id,
      project_id: payload.project_id,
      // task_id: payload.task_id,
      project_name: payload.project_name,
      payload: payload.message,
      notification_type: payload.notification_type,
    };
    if (payload.task_id) {
      data.task_id = payload.task_id;
    }
    socket.emit('send_notification_to_all', data);

    console.log(socket, 'send');
  };
  const sendChatNotification = (payload) => {
    console.log(payload, 'payload');
    const data = {
      sender_id: localStorage.getItem('user_id'),
      receiver_id: payload.receiver_id,
      chat_info: payload.chat_info,
      is_del_event: payload.is_del_event,
      notification_type: payload.notification_type,
      notification_action: payload.notification_action,
      message: payload.message,
    };
    socket.emit('send_notification_to_all', data);

    console.log(socket, 'send');
  };
  const disconnect_socket = () => {
    socket.disconnect();
  };
  const isTabActive = () => {
    if (String(document.visibilityState) === 'visible') {
      return true;
    }
    return false;
  };
  const PlayMessageSound = () => {
    const sound = new Audio(MessageRing);
    sound.play();
  };

  useEffect(() => {
    const connectionOptions = {
      query: {},
      'force new connection': true,
      reconnection: true,
      autoConnect: true,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 60000,
      reconnectionAttempts: 'Infinity',
      timeout: 10000,
      transports: ['websocket'],
    };
    socket = socketio(socket_url);
    console.log(socket_url, 'socket-url');
    // socket = io(socketUrl);
    socket.emit('create', localStorage.getItem('user_id'));

    socket.off('receiver_notification').on('receiver_notification', (data) => {
      console.log(data, 'receiver_notification');
      const type = data.notification_type;
      const action = data.notification_action;
      if (String(data.receiver_id) === String(localStorage.getItem('user_id'))) {
        console.log(
          data.receiver_id,
          'receiver_id',
          localStorage.getItem('user_id'),
          'localStorage'
        );
        if (String(type) === 'chat_group') {
          setSocketGroupInfo(data);
        }
        if (String(type) === 'chat') {
          fetchUnreadMessagesCount();
          setSocketChatInfo(data);
          if (String(action) === 'add_message') {
            showSocketNotification(data);
          }
        }
        if (String(type) === 'task') {
          showSocketNotification(data);
          showSocketNotification(data);
        }
        if (String(type) === 'team_edit') {
          getUserProfile();
        }
      }
    });

    console.log(socket, 'socket connection');
  }, [socket_url]);

  useEffect(() => {
    setUserProfileData(JSON.parse(localStorage.getItem('user-profile')));
    getUserProfile();
  }, []);
  useEffect(() => {
    console.log(user_Profile, 'uppd');
  }, [user_Profile]);

  const collection = {
    sendSocketNotification,
    showSocketNotification,
    fetchNotificationListing,
    notifications,
    getUserProfileData,
    setUserProfileData,
    sendChatNotification,
    socketChatInfo,
    socketGroupInfo,
    disconnect_socket,
    _getUnreadMessageCount,
    _setUnreadMessageCount,
    fetchUnreadMessagesCount,
  };
  return <SocketContext.Provider value={collection}>{children}</SocketContext.Provider>;
}
