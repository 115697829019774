import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {
  Container,
  CircularProgress,
  Stack,
  Typography,
  Button,
  IconButton,
  Grid,
} from '@mui/material';
import { filter } from 'lodash';
import Page from 'src/components/Page';
import { makeStyles } from '@mui/styles';
import { activeProjectCategories } from 'src/DAL/Project/Project';
import StyledSearch from '../../pages/Tasks/components/search/StyledSearch';
import CategoryListCard from './CategoryListCard';
import SearchNotFound from '../../components/SearchNotFound';
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: '50%',
    marginTop: '20%',
  },
}));
const ProjectCategoryList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isWaiting, setIsWaiting] = useState(true);
  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleLocalTaskSearch = (input, array) => {
    const getConcat = (item) => {
      let projects = '';
      item.projects.map((proj) => {
        projects = projects + proj.project_name;
      });
      let concat = `${item.catagory_name}${projects}`;
      return concat;
    };

    let filtered = filter(
      array,
      (_category) => getConcat(_category).toLowerCase().indexOf(input.toLowerCase()) !== -1
    );
    // let filtered = filter(
    //   array,
    //   (_category) => _category.catagory_name.toLowerCase().indexOf(input.toLowerCase()) !== -1
    // );
    return filtered;
  };
  const getFilteredList = (e) => {
    const filtered = handleLocalTaskSearch(searchValue, categoriesList);
    return filtered;
  };

  const getActiveList = async () => {
    let formData = new FormData();
    formData.append('token', localStorage.getItem('token'));
    const result = await activeProjectCategories(formData);
    if (result.code === 200) {
      console.log(result.category_list, 'catg-list');
      setCategoriesList(result.category_list);
      setIsWaiting(false);
    } else {
      setIsWaiting(false);
      enqueueSnackbar(result.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    getActiveList();
  }, []);
  if (isWaiting) {
    return <CircularProgress className={classes.loading} color='primary' />;
  }
  /* <<======================= JSX =======================>> */
  return (
    <Page title='Projects | Support Task Portal'>
      <Container maxWidth='xl'>
        <Stack direction='row' alignItems='center' mb={3}>
          <Typography variant='h4'>Project Categories</Typography>
          <div className='ml-auto'>
            <Button
              onClick={() =>
                navigate('/projects/categories/all', {
                  state: { catagory_name: 'All' },
                })
              }
              variant='contained'
            >
              View All Projects
            </Button>
          </div>
        </Stack>
        <Stack mb={3} direction='row' alignItems='center'>
          <div className='d-flex w-100 align-items-center'>
            <span>
              <StyledSearch
                value={searchValue}
                onChange={handleChangeSearchValue}
                placeHolder='Search...'
              />
            </span>
          </div>
        </Stack>
        <Grid container spacing={3}>
          {getFilteredList().length > 0 ? (
            getFilteredList().map((category, index) => (
              <Grid key={index} item xs={12} sm={6} md={3}>
                <CategoryListCard category={category} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <SearchNotFound />
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default ProjectCategoryList;
